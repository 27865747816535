import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';

import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';
import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';

class FacilityResidentRequirements extends PureComponent {
  renderEmptyData() {
    const { facility } = this.props;

    const { chores = null, houseMeetings = null, curfew = null, legalExceptions = '' } = facility;

    return (
      chores === null &&
      houseMeetings === null &&
      curfew === null &&
      (legalExceptions === '' || legalExceptions === null)
    );
  }
  getLegalExceptions() {
    const {
      facility: { legalExceptions = '' },
      intl: { formatMessage },
    } = this.props;
    const exceptions = [];
    const exceptionList = legalExceptions.split(',');
    exceptionList.map(exception => exceptions.push(formatMessage({ id: `facility.res.req.criminal.${exception}` })));
    return exceptions.join(', ');
  }
  render() {
    const { facility } = this.props;
    const { chores = null, houseMeetings = null, curfew = null } = facility;

    return (
      <div className={[styles.container].join(' ')}>
        <div className={grid.row}>
          <div className={grid.col}>
            <h2 className={styles.header}>
              <FormattedMessage id="facility.header.res.requirements" />
            </h2>
          </div>
        </div>

        {!this.renderEmptyData() && (
          <div className={styles.box1}>
            <div className={[grid.row, grid.rowBottom].join(' ')}>
              {chores && (
                <div className={[grid.colSm6, grid.colMd4, styles.check].join(' ')}>
                  <Check />
                  <FormattedMessage id="facility.res.req.chore" />
                </div>
              )}

              {houseMeetings && (
                <div className={[grid.colSm6, grid.colMd4, styles.check].join(' ')}>
                  <Check />
                  <FormattedMessage id="facility.res.req.meeting" />
                </div>
              )}

              {curfew && (
                <div className={[grid.colSm6, grid.colMd4, styles.check].join(' ')}>
                  <Check />
                  <FormattedMessage id="facility.res.req.curfew" />
                </div>
              )}
              {!chores && !houseMeetings && !curfew && (
                <div className={[grid.colSm6, grid.colMd4].join(' ')}>
                  <div className={styles.nonSpecifiedText}>
                    <FormattedMessage id="facility.data.none.specified" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className={[grid.row, styles.box1].join(' ')}>
          {facility.minAbstinence && facility.minAbstinence.length > 0 && (
            <div className={[grid.colSm6, grid.colMd4].join(' ')}>
              <div>
                <b>
                  <FormattedMessage id="facility.res.req.abstinence" />
                </b>
              </div>
              {facility.minAbstinence}
            </div>
          )}
          <div className={[grid.colSm6].join(' ')}>
            <div>
              <b>
                <FormattedMessage id="facility.res.req.convictions" />
              </b>
            </div>
            {facility.claimed && facility.legalExceptions && facility.legalExceptions.length > 0 && (
              <>
                <div className={styles.boxSubHeader}>
                  <FormattedMessage id="facility.res.req.convictions.accept" />
                </div>
                {this.getLegalExceptions()}
              </>
            )}
            <div className={styles.requirementContact}>
              <FormattedMessage id="facility.res.req.convictions.contact" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FacilityResidentRequirements.defaultProps = {};

FacilityResidentRequirements.propTypes = {
  facility: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(injectIntl(FacilityResidentRequirements));
