import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { FormattedMessage } from 'react-intl';


import styles from './facility.module.css';

import { ReactComponent as Facebook } from '../../images/icons/social/facebook.svg';
import { ReactComponent as Twitter } from '../../images/icons/social/twitter.svg';
import { ReactComponent as Linkedin } from '../../images/icons/social/linkedin.svg';
import { ReactComponent as Instagram } from '../../images/icons/social/instagram.svg';

const socialFields = [
  { field: 'facebookUrl', icon: Facebook},
  { field: 'twitterUrl', icon: Twitter},
  { field: 'instagramUrl', icon: Instagram},
  { field: 'linkedinUrl', icon: Linkedin},
];



class FacilitySocialLinks extends PureComponent {

  static hasSocialLinks(facility) {
    let hasSocialLinks = false;

    socialFields.forEach( item => {
      if(facility[item.field]) {
        hasSocialLinks = true;
      }
    });
    return hasSocialLinks;
  }



  render() {
    const { facility } = this.props;
    const hasSocialLinks = this.constructor.hasSocialLinks(facility);

    if (!hasSocialLinks) {
      return '';
    }

    return (
      <div>
        <div className={styles.sectionSubHeader}>
          <FormattedMessage id="facility.social" />
        </div>
        {socialFields.map(item => {
          const url = facility[item.field];
          if(!url) {
            return '';
          }

          const TagName = item.icon;
          return (
            <a key={uuidv4()} href={url} className={styles.socialIcons} target="_blank" rel="noreferrer noopener">
              <TagName />
            </a>
          );
        })}

      </div>
    );
  }
}
FacilitySocialLinks.propTypes = {
  facility: PropTypes.object.isRequired,
};


export default FacilitySocialLinks;
