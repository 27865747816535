import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Supercluster from 'supercluster';

import GoogleMap from '../common/map/GoogleMap';
import styles from './facilities.module.css';
import FacilityMapMarker from './FacilityMapMarker';
import { getFacilityStatusType } from '../../utils/utils';
import { getGoogleMap } from '../../selectors/map';
import { getSystemOptions } from '../../selectors/system';
import { getFacilities } from '../../selectors/facilities';
import { getMapAutocompleteKey } from '../../selectors/facility';

const VIEW_MAP = 'map';
const VIEW_CLUSTER = 'cluster';

const LOS_ANGELES_CENTER = { lat: 34.0522, lng: -118.2437 };
const Marker = ({ children }) => children;

const supercluster = new Supercluster({
  radius: 200,
  maxZoom: 20,
  minPoints: 2,
});

const getMapBounds = (facilities, mapApi) => {
  const bounds = new mapApi.LatLngBounds();
  facilities.forEach(facility => {
    if (facility.latitude && facility.longitude) {
      bounds.extend(new mapApi.LatLng(facility.latitude, facility.longitude));
    }
  });

  return bounds;
};

const fitMapBounds = (facilities, map, mapApi) => {
  const bounds = getMapBounds(facilities, mapApi);

  setTimeout(function() {
    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);
    map.panToBounds(bounds);
  }, 1);
};

const FacilityMap = ({
  facilities,
  googleMap,
  mapKey,
  defaultCenterPoint,
  handleGoogleApiLoaded,
  handleLocationChange,
  view,
  selectedFacility,
  systemOptions,
}) => {
  const [defaultCenter, setDefaultCenter] = useState(defaultCenterPoint || LOS_ANGELES_CENTER);

  const mapRef = useRef();
  const [zoom, setZoom] = useState(14);
  const [showButton, setShowButton] = useState(false);
  const [center, setCenter] = useState(defaultCenterPoint || LOS_ANGELES_CENTER);

  const [hasFitBound, setHasFitBound] = useState(false);

  const [points, setPoints] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [bounds, setBounds] = useState({ northWest: { lng: 0, lat: 0 }, southEast: { lng: 0, lat: 0 } });

  const setMapRef = (map, maps) => {
    handleGoogleApiLoaded(map, maps);
    mapRef.current = map;
  };

  const onBoundsChange = (zoom, bounds, center) => {
    const { nw, se } = bounds;
    setCenter(center);
    setZoom(zoom);
    setBounds({ northWest: nw, southEast: se });
  };

  const handleDragEnd = () => {
    setShowButton(true);
  };

  const handleMapSearchClick = () => {
    const { northWest, southEast } = bounds;

    let newBounds = {
      southEastLatitude: southEast.lat,
      southEastLongitude: southEast.lng,
      northWestLatitude: northWest.lat,
      northWestLongitude: northWest.lng,
      filteredSearch: true,
    };

    //HACK for values out of range
    if (northWest.lng < -180 || northWest.lng > 180) {
      const fitbound = northWest.lng / 100;

      newBounds = { ...newBounds, northWestLongitude: fitbound };
    }
    if (southEast.lng < -180 || southEast.lng > 180) {
      const fitbound = southEast.lng / 100;
      newBounds = { ...newBounds, southEastLongitude: fitbound };
    }

    if (southEast.lat < -90 || southEast.lat > 90) {
      const fitbound = southEast.lat / 90;
      newBounds = { ...newBounds, southEastLatitude: fitbound };
    }
    if (northWest.lat < -90 || northWest.lat > 90) {
      const fitbound = northWest.lat / 90;
      newBounds = { ...newBounds, northWestLatitude: fitbound };
    }

    if (googleMap && googleMap.mapInstance) {
      handleLocationChange(googleMap.mapInstance.center, newBounds);
    }

    setShowButton(false);
  };

  const renderMap = () => {
    if (view === VIEW_CLUSTER) {
      return clusters.map((cluster, index) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } = cluster.properties;

        if (isCluster) {
          return (
            <Marker key={index} lat={latitude} lng={longitude}>
              <div
                key={cluster.properties.cluster_id}
                className={styles.clusterMarker}
                style={{
                  width: `${25 + (pointCount / points.length) * 20}px`,
                  height: `${25 + (pointCount / points.length) * 20}px`,
                }}
                onClick={() => {
                  const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                  mapRef.current.setZoom(expansionZoom);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
              >
                {pointCount}
              </div>
            </Marker>
          );
        }
        return (
          <FacilityMapMarker
            key={cluster.properties.facilityId}
            lat={latitude}
            lng={longitude}
            facility={cluster.facility}
            markerType={getFacilityStatusType(cluster.properties.status)}
          />
        );
      });
    } else {
      return facilities.map(facility => {
        return (
          <FacilityMapMarker
            key={facility.id}
            lat={facility.latitude}
            lng={facility.longitude}
            facility={facility}
            markerType={getFacilityStatusType(facility.status)}
            selectedFacility={selectedFacility}
            systemOptions={systemOptions}
          />
        );
      });
    }
  };

  useEffect(() => {
    if (facilities && facilities.length > 0) {
      setHasFitBound(false);

      if (view === VIEW_CLUSTER) {
        const lat = facilities[0].latitude;
        const lng = facilities[0].longitude;

        if (lat && lng) {
          setCenter({ lat, lng });
        }

        const points = facilities.map(facility => ({
          type: 'Feature',
          properties: { cluster: false, facilityId: facility.id, status: facility.status },
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(facility.longitude), parseFloat(facility.latitude)],
          },
          facility: facility,
        }));
        setPoints(points);
      }
    }
  }, [facilities]);

  useEffect(() => {
    supercluster.load(points);
    setClusters(
      supercluster.getClusters(
        [bounds.northWest.lng, bounds.southEast.lat, bounds.southEast.lng, bounds.northWest.lat],
        zoom,
      ),
    );
  }, [points, zoom, bounds]);

  if (facilities.length && googleMap.mapApiLoaded && !hasFitBound) {
    setHasFitBound(true);
    fitMapBounds(facilities, googleMap.mapInstance, googleMap.mapApi);
  }

  return (
    <Fragment>
      {showButton && (
        <div className={styles.mapButtonContainer}>
          <button className={[styles.mapButton, 'gh-btn-map'].join(' ')} onClick={handleMapSearchClick} type="button">
            <FormattedMessage id="button.map.search" />
          </button>
        </div>
      )}
      {mapKey && (
        <GoogleMap
          defaultZoom={14}
          defaultCenter={defaultCenter}
          bootstrapURLKeys={{
            key: mapKey,
            libraries: ['places', 'geometry'],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => setMapRef(map, maps)}
          onDragEnd={handleDragEnd}
          onChange={({ zoom, bounds, center }) => {
            onBoundsChange(zoom, bounds, center);
          }}
          resetBoundsOnResize={true}
          center={center}
        >
          {facilities.length && renderMap()}
        </GoogleMap>
      )}
    </Fragment>
  );
};

FacilityMap.defaultProps = {
  facilities: [],
  selectedFacility: null,
  systemOptions: {},
  mapKey: '',
  view: VIEW_MAP,
};

FacilityMap.propTypes = {
  facilities: PropTypes.array,
  handleGoogleApiLoaded: PropTypes.func.isRequired,
  selectedFacility: PropTypes.object,
  handleLocationChange: PropTypes.func.isRequired,
  googleMap: PropTypes.object.isRequired,
  systemOptions: PropTypes.object,
  mapKey: PropTypes.string,
  view: PropTypes.string,
};
const mapStateToProps = state => ({
  facilities: getFacilities(state),
  googleMap: getGoogleMap(state),
  systemOptions: getSystemOptions(state),
  mapKey: getMapAutocompleteKey(state),
});

export default connect(mapStateToProps)(FacilityMap);
