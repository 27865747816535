import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import UrlAssembler from 'url-assembler';

import styles from './facilities.module.css';
import './facilities.overrides.css';
// import { pinType } from '../../utils/utils';

import FacilityCard from '../common/FacilityCard/FacilityCard';

const pinStyle = {
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
  CALL: 'Call',
};

class FacilityMapMarker extends Component {
  static getMarkerType(markerType) {
    if (!markerType) {
      return '';
    }

    return pinStyle[markerType];
  }

  static getFacilityUrl(pattern, id) {
    const url = UrlAssembler()
      .template(pattern)
      .param({ id })
      .toString();
    return decodeURIComponent(url);
  }

  constructor(props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.infoWindow = React.createRef();

    this.state = {
      overrides: {
        facilityUrl: '#/facility/:id',
      },
    };
  }

  componentDidMount() {
    const {
      systemOptions: { pageOverrides: { facilityUrl } = {} },
    } = this.props;
    if (facilityUrl) {
      this.setState({ overrides: { facilityUrl } });
    }
  }

  handleMouseEnter() {
    this.infoWindow.current.style.display = 'block';
  }

  handleMouseLeave() {
    this.infoWindow.current.style.display = 'none';
  }

  handleClick() {
    const { facility } = this.props;
    const {
      overrides: { facilityUrl },
    } = this.state;
    // window.location.href = this.constructor.getFacilityUrl(facilityUrl, facility.id);
    window.open(this.constructor.getFacilityUrl(facilityUrl, facility.id));
  }

  render() {
    const { facility, markerType, selectedFacility } = this.props;
    const isSelectedFacility = selectedFacility && selectedFacility.id === facility.id;
    const markerStyle = this.constructor.getMarkerType(markerType);

    return (
      <div
        className={[isSelectedFacility ? styles[`markerSelected${markerStyle}`] : styles[`marker${markerStyle}`]].join(
          ' ',
        )}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      >
        <div className={[styles.infoBox, 'infoBox'].join(' ')} ref={this.infoWindow}>
          <FacilityCard facility={facility} />
        </div>
      </div>
    );
  }
}

FacilityMapMarker.defaultProps = {
  selectedFacility: null,
  markerType: '',
  systemOptions: {},
};

FacilityMapMarker.propTypes = {
  facility: PropTypes.object.isRequired,
  selectedFacility: PropTypes.object,
  markerType: PropTypes.string,
  systemOptions: PropTypes.object,
};

export default withRouter(FacilityMapMarker);
