import React, {PureComponent} from 'react';

import Select from 'react-select';
import PropType from 'prop-types';
import {facilityTypes} from "../../../../constants/facilityType";
import {getFacilityTypeMessage} from "../../../../utils/utils";

import selectStyles from './SelectStyles';

class FacilitySearchType extends PureComponent {

    getFacilityType() {
        let { search: {facilityType} } = this.props;
        const facilityTypeOptions = this.getFacilityTypesOptions();
        return facilityType ? facilityTypeOptions.find(type => facilityType === type.value) : null;
    }

    getFacilityTypesOptions() {
        const facilityTypeOptions = [];
        for (const facilityType of facilityTypes) {
            const message = getFacilityTypeMessage(`facility.type.${facilityType}`, facilityType);
            facilityTypeOptions.push({label: message, value: facilityType});
        }
        return facilityTypeOptions;
    }

    render() {
        const { handleFacilityTypeChange } = this.props;
        return (
            <>
                <Select
                    options={this.getFacilityTypesOptions()}
                    styles={selectStyles()}
                    value={this.getFacilityType()}
                    isSearchable={false}
                    className='basic-single'
                    placeholder={'All Services'}
                    isClearable={true}
                    onChange={handleFacilityTypeChange}/>
            </>
        );
    }
}

FacilitySearchType.defaultProps = {
    handleFacilityTypeChange: PropType.func.isRequired,
    search: PropType.object.isRequired,
};

export default FacilitySearchType;