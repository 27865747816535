import queryString from 'query-string';
import 'whatwg-fetch';
import isEmpty from 'lodash/isEmpty';

const API = process.env.REACT_APP_API_PATH || '';

const handleResponse = response => {
  if (response.status < 200 || response.status >= 300) {
    const error = new Error();
    error.response = response;
    throw error;
  }
  return response;
};

const handleError = error => error.response;

// import/prefer-default-export
export const request = async ({apiPath = API, url, args ={}, apiKey = null, method = 'GET' }) => {
  let apiUrl = `${apiPath}${url}`;
  const options = {
    method,
    headers: {},
  };

  if(apiKey) {
      options.headers.Authorization = `Bearer ${apiKey}`;
  }
  // options.headers['Content-Type'] = 'application/json';

  if (args && method === 'POST') {
    options.body = JSON.stringify(args);
    options.headers['Content-Type'] = 'application/json';
  } else if (!isEmpty(args)  && method === 'GET') {
    apiUrl += `?${queryString.stringify(args)}`;
  }

  return fetch(apiUrl, options)
    .then(handleResponse)
    .catch(handleError);
};
