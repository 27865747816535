import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import Sticky from 'react-stickynode';
import { FormattedMessage } from 'react-intl';
import { animateScroll } from 'react-scroll';

import {setToString, getCurrentPosition, getFacilityTypeMessage} from '../../utils/utils';

import { loadFacilities, loadInitFacilities } from '../../actions/facilities';
import { loadInstitution } from '../../actions/facility';
import { loadServices } from '../../actions/services';

import { loadGoogleMap } from '../../actions/map';

import FacilityList from '../../components/facilities/v2/FacilityList';
import FacilitySearch from '../../components/facilities/v2/FacilitySearch/FacilitySearch';
import FacilitySearchV1 from '../../components/facilities/FacilitySearch';
import FacilityMap from '../../components/facilities/FacilityMap';

import styles from './facilities.module.css';
import grid from '../../styles/bootstrap-grid.module.css';
import { getSystemOptions } from '../../selectors/system';
import { getFeatures } from '../../selectors/facility';
import FacilitySearchBar from '../../components/facilities/v2/FacilitySearchBar/FacilitySearchBar';
import {
  PAYMENT_TYPES_LIST,
  RESIDENT_CHARACTERISTICS_LIST,
  servicesItems,
} from '../../constants/filteredSearchOptions';
import { POPULATION_LIST } from '../../constants/Population';
import { FT_PERSONAL_PROFILE, FT_SCHOOL } from '../../constants/Other';

// Center point of Los Angeles
const DEFAULT_LAT = 34.05271;
const DEFAULT_LNG = -118.24516;
const ONLINE_SERVICES = 687;

const VIEW_LIST = 'list';
const VIEW_MAP = 'map';
const VIEW_SPLIT = 'split';
const VIEW_CLUSTER = 'cluster';

const BUTTON_TYPE = {
  service: 'SERVICE',
  accreditation: 'ACCREDITATION',
};

class Facilities extends Component {
  constructor(props) {
    super(props);

    const { searchOptions } = props;
    const {
      defaultCenterPoint = {},
      defaultDistance,
      defaultState,
      defaultServiceId,
      requiredServiceId,
      requiredAccreditationId,
      defaultView,
      widgetType = '',
      sort,
    } = searchOptions;

    let initialView = defaultView;

    if (defaultView === VIEW_CLUSTER) {
      initialView = VIEW_MAP;
    }

    const { lat, lng } = defaultCenterPoint;
    const search = {
      population: null,
      services: new Set(),
      accreditations: new Set(),
      name: '',
      sort: sort || 'recommended',
      latitude: lat || DEFAULT_LAT,
      longitude: lng || DEFAULT_LNG,
      locationName: '',
      page: 0,
      pageSize: defaultView === VIEW_CLUSTER ? 1000 : 20,
      radius: 25,
      province: null,
      requiredServiceId: requiredServiceId || null,
      requiredAccreditationId: requiredAccreditationId || null,
    };

    if (requiredAccreditationId === 24) {
      search.requiredAccreditationId = [24, 26];
    }
    if (defaultServiceId) {
      search.services.add(defaultServiceId);
    }
    if (defaultDistance === 'state' && defaultState) {
      search.province = defaultState;
    } else {
      search.radius = defaultDistance || 25;
    }

    if (requiredAccreditationId && search.radius === 9999) {
      search.latitude = null;
      search.longitude = null;
    }

    if (widgetType.startsWith(FT_SCHOOL)) {
      search.radius = 3959;
    }

    this.state = {
      search,
      view: initialView || VIEW_LIST,
      initialValues: search,
      showSearchFilters: false,
      showSearchRadius: false,
    };

    this.handleMapChange = this.handleMapChange.bind(this);
    this.handleSearchBarChange = this.handleSearchBarChange.bind(this);
    this.handleGoogleApiLoaded = this.handleGoogleApiLoaded.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSearchFiltersChange = this.handleSearchFiltersChange.bind(this);
    this.handleFiltersOptionsChange = this.handleFiltersOptionsChange.bind(this);
    this.fetchFilteredSearch = this.fetchFilteredSearch.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleSearchBox = this.handleSearchBox.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.handleRadiusChange = this.handleRadiusChange.bind(this);
    this.mapContainer = React.createRef(); // Create a ref object
    this.searchContainer = React.createRef();
    this.openSearchFilters = this.openSearchFilters.bind(this);
    this.openSearchRadius = this.openSearchRadius.bind(this);
    this.handleFacilityTypeChange = this.handleFacilityTypeChange.bind(this);
  }

  static getWindowHeight() {
    let height = 768;
    try {
      if (window.parent && window.parent.frames && window.parent.frames.length > 0) {
        height = window.parent.frames[0].innerHeight;
      } else {
        height = window.parent.innerHeight ? window.parent.innerHeight : window.innerHeight;
      }
    } catch (e) {
      console.log('could not get window height');
    }
    return height;
  }

  async componentDidMount() {
    console.log('componentDidMount');
    const {
      dispatchInitFacilities,
      location: { search: searchQuery },
      systemConfig,
    } = this.props;

    const { search: searchState, search } = this.state;
    const queryStringMap = queryString.parse(searchQuery);

    // We need to wait for auth so that it will populate auth data.
    // dispatchInstitution();
    // dispatchServices();

    let position = {};

    if (!searchState.requiredAccreditationId) {
      try {
        position = await getCurrentPosition();
      } catch (e) {
        // User does something so there is no position
      }

      // Get position from browser

      if (position.coords) {
        const { latitude, longitude } = position.coords;
        searchState.longitude = longitude;
        searchState.latitude = latitude;
      }
    }

    if (this.mapContainer.current) {
      const height = this.constructor.getWindowHeight();
      this.mapContainer.current.style.height = `${height}px`;
    }

    const root = document.getElementById(systemConfig.containerId);
    if ((root && root.offsetWidth < 768) || !this.shouldUseLocation()) {
      this.setState({
        view: VIEW_LIST,
      });
    }

    if (queryStringMap && queryStringMap.services) {
      queryStringMap.services.split(',').forEach(service => searchState.services.add(Number(service)));
    }
    delete queryStringMap.services;

    if (queryStringMap && queryStringMap.accreditations) {
      queryStringMap.accreditations.split(',').forEach(accreditation => {
        searchState.accreditations.add(Number(accreditation));
      });
    }
    delete queryStringMap.accreditations;

    this.setState({ search: { ...searchState, ...queryStringMap } }, () => {
      const { search } = this.state;

      dispatchInitFacilities(search);
    });

    if (search.services.size > 0) {
      const myArr = Array.from(search.services);
      let residentCharacteristics = [];
      let paymentType = null;
      let population = null;
      let receivingMAT = null;

      for (const item of search.services) {
        RESIDENT_CHARACTERISTICS_LIST.find(element => {
          if (element.value === item) {
            residentCharacteristics.push(item);
          }
        });
        PAYMENT_TYPES_LIST.find(element => {
          if (element.value === item) {
            paymentType = element;
          }
        });
        POPULATION_LIST.find(element => {
          if (element.value === item) {
            population = element;
          }
        });
        if (item === 144) {
          receivingMAT = item;
        }
      }

      this.handleFiltersOptionsChange('residentCharacteristics', { value: residentCharacteristics });
      this.handleFiltersOptionsChange('paymentType', paymentType);
      this.handleFiltersOptionsChange('population', population);
      this.handleFiltersOptionsChange('receivingMAT', { value: receivingMAT });
    }

    this.debounceSearch = debounce(this.getSearchedFacilities, 250);
  }

  shouldUseLocation() {
    const {
      searchOptions: { widgetType },
    } = this.props;
    if (widgetType === FT_PERSONAL_PROFILE) {
      return false;
    }
    return true;
  }

  iFrameReRender() {
    this.setState({ iframeBoundRendered: true });
    setTimeout(() => {
      const height = this.constructor.getWindowHeight();
      this.mapContainer.current.style.height = `${height}px`;
      this.setState({ iframeBoundRendered: true });
    }, 100);
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      systemOptions: { searchOptions: { iframeBound } = {} },
    } = this.props;
    const { view, iframeBoundRendered } = this.state;
    if (iframeBound && !iframeBoundRendered) {
      this.iFrameReRender();
    }

    const height = this.constructor.getWindowHeight();

    if (view === VIEW_MAP && this.mapContainer.current && this.searchContainer.current) {
      const cords = this.searchContainer.current.getBoundingClientRect();
      this.mapContainer.current.style.height = `${height - cords.height - this.mapContainer.current.offsetTop}px`;
    } else {
      if (this.mapContainer.current) {
        this.mapContainer.current.style.height = `${height}px`;
      }
    }
  }
  getSearchedFacilities() {
    const { dispatchFacilities, history } = this.props;
    const {
      search,
      search: { filteredSearch = false },
    } = this.state;
    const searchCopy = Object.assign({}, search);
    servicesItems.map(item => delete searchCopy[item]);

    searchCopy.services = setToString(searchCopy.services);
    searchCopy.accreditations = setToString(searchCopy.accreditations);
    if (filteredSearch) {
      delete searchCopy['filteredSearch'];
    }

    if (searchCopy.hasOwnProperty('bounds')) {
      delete searchCopy['bounds'];
    }

    history.push(`?${decodeURIComponent(queryString.stringify(searchCopy))}`);

    dispatchFacilities(search);
  }

  handleSearchBarChange(field, params) {
    const { search } = this.state;

    if (params && params.value) {
      //special case for radius and provience
      if (field === 'radius') {
        search.province = '';

        if (params.value === 99999) {
          search.locationName = '';
          search.latitude = '';
          search.longitude = '';
        }
      }
      search[field] = params.value;
    }

    if (params && params.value == null) {
      delete search[field];
    }

    this.setState({ search: { ...search, page: 0 } }, () => this.fetchFilteredSearch());
  }

  handleSearchFiltersChange(options) {
    const { search } = this.state;

    const { history, dispatchFacilities } = this.props;

    const optionsCopy = Object.assign({}, options);

    servicesItems.map(item => delete optionsCopy[item]);

    if (
      optionsCopy.transportationOptions &&
      typeof optionsCopy.transportationOptions !== 'string' &&
      optionsCopy.transportationOptions.length > 0
    ) {
      let valuesToString = optionsCopy.transportationOptions.join(',');
      optionsCopy['transportationOptions'] = valuesToString;
    }

    if (
      optionsCopy.legalExceptions &&
      typeof optionsCopy.legalExceptions !== 'string' &&
      optionsCopy.legalExceptions.length > 0
    ) {
      let valuesToString = optionsCopy.legalExceptions.join(',');
      optionsCopy['legalExceptions'] = valuesToString;
    }

    const searchCopy = Object.assign({}, optionsCopy);

    let newSearch = { ...search, ...searchCopy, name: searchCopy.name ? searchCopy.name : search.name };

    const filteredSearchOptions = Object.assign({ filteredSearch: true }, newSearch);

    if (newSearch.filteredSearch) {
      delete newSearch['filteredSearch'];
    }

    if (newSearch.hasOwnProperty('bounds')) {
      delete newSearch['bounds'];
    }

    servicesItems.map(item => {
      if (newSearch.hasOwnProperty(item)) {
        delete newSearch[item];
        delete filteredSearchOptions[item];
      }
    });
    newSearch.services = setToString(newSearch.services);
    newSearch.accreditations = setToString(newSearch.accreditations);

    history.push(`?${decodeURIComponent(queryString.stringify(newSearch))}`);
    dispatchFacilities(filteredSearchOptions);
  }

  handleMapChange(value) {
    this.setState({ view: value });
  }

  handleRadiusChange(params) {
    const { search } = this.state;
    const radius = params.value;
    // we are doing a radius search so remove bound search

    delete search['bounds'];
    delete search['southEastLatitude'];
    delete search['southEastLatitude'];
    delete search['southEastLongitude'];
    delete search['northWestLatitude'];
    delete search['northWestLongitude'];

    this.setState(
      {
        search: { ...search, radius, page: 0 },
        clickedFacility: null,
        selectedFacility: null,
      },
      () => {
        this.debounceSearch();
      },
    );
  }

  handleLocationChange(place, bounds) {
    if (!place && (!place.geometry || !(place.lat() && place.lng()))) {
      return;
    }

    const { search } = this.state;
    let searchParams = { ...search, page: 0 };

    if (place.geometry || typeof place.lat === 'function') {
      let location = {};
      searchParams.bounds = false;
      if (place.geometry) {
        location = place.geometry.location;
        searchParams.locationName = place.formatted_address.replace(/\s+/g, '');
      } else {
        location = place;
      }

      searchParams.latitude = location.lat();
      searchParams.longitude = location.lng();
    } else if (place.label && place.value) {
      searchParams.province = place.value;
      searchParams.locationName = '';
    }

    if (
      bounds &&
      (bounds.southEastLatitude !== 0 &&
        bounds.southEastLongitude !== 0 &&
        bounds.northWestLatitude !== 0 &&
        bounds.northWestLongitude !== 0)
    ) {
      searchParams.locationName = '';
      searchParams.province = '';

      searchParams = { ...searchParams, ...bounds, bounds: true };
    }

    this.setState(
      { search: searchParams },
      // { search: { ...search, locationName, ...cords, page: 0 } },
      () => {
        this.getSearchedFacilities();
      },
    );
  }

  handlePagination(data) {
    const { search } = this.state;
    const { systemOptions = {} } = this.props;

    this.setState({ search: { ...search, page: data.selected } }, () => {
      this.getSearchedFacilities();
      if (systemOptions.scrollTop) {
        const elem = document.querySelector(`.${systemOptions.scrollTop}`);
        if (elem) {
          elem.scrollIntoView();
        }
      } else {
        animateScroll.scrollToTop({ duration: 250 });
      }
    });
  }

  handleGoogleApiLoaded(map, maps) {
    const { dispatchGoogleMaps } = this.props;
    console.log('google api loaded');
    dispatchGoogleMaps({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
  }

  handleFiltersOptionsChange(field, params) {
    const { initialValues, search } = this.state;

    if (params && params.value) {
      //special case for multiples checkboxes
      if (Array.isArray(params.value)) {
        search[field] = params.value.map(item => item.value || item);
      } else {
        search[field] = params.value;
      }
    }

    if (params && params.value == null) {
      delete search[field];
    }

    this.setState({ search: { ...search } });
    if (field === 'clear') {
      this.setState({ search: { ...initialValues, amenities: [], servicesPrograms: [], name: search.name } });
    }
  }

  fetchFilteredSearch() {
    const { search } = this.state;

    let servicesValues = servicesItems
      .filter(function(item) {
        return search[item];
      })
      .map(item => search[item]);

    if (servicesValues.length > 0) {
      let valuesToString = servicesValues.join(',');
      let arr = valuesToString.split(',');
      const mySet = new Set();
      arr.map(item => item !== '' && mySet.add(Number(item)));
      search.services = mySet;
    } else {
      search['services'] = new Set();
    }

    this.setState({ search: { ...search } });

    this.handleSearchFiltersChange(search);
  }

  handleSearchBox(term) {
    const {
      search,
      search: { services, name },
    } = this.state;

    let facilityName = name;
    if (term.value) {
      services.add(Number(term.value));
      facilityName = term.name || '';
    } else if (typeof term.name !== 'undefined') {
      facilityName = term.name;
    }

    this.setState(
      {
        search: { ...search, services, name: facilityName, page: 0 },
        clickedFacility: null,
        selectedFacility: null,
      },
      () => {
        this.debounceSearch();
      },
    );
  }

  handleSearchButton({ service = {}, selected = false }) {
    const {
      search,
      search: { services, accreditations },
    } = this.state;

    if (service.type === BUTTON_TYPE.accreditation) {
      if (selected) {
        accreditations.add(Number(service.id));
      } else {
        accreditations.delete(Number(service.id));
      }
    }
    if (service.type === BUTTON_TYPE.service) {
      if (selected) {
        services.add(Number(service.id));
      } else {
        services.delete(Number(service.id));
      }
    }
    this.setState(
      {
        search: { ...search, services, accreditations, page: 0 },
        clickedFacility: null,
        selectedFacility: null,
      },
      () => {
        this.debounceSearch();
      },
    );
  }

  openSearchFilters() {
    const { showSearchFilters } = this.state;
    // this.handleFiltersOptionsChange('clear');
    this.setState({
      showSearchFilters: !showSearchFilters,
    });
  }

  openSearchRadius() {
    const { showSearchRadius, search } = this.state;

    search['radius'] = 150;
    this.setState({
      showSearchRadius: !showSearchRadius,
    });
  }

  getMapStyle() {
    const { view, search } = this.state;
    const { searchOptions } = this.props;
    let mapStyle = styles.mapHidden;

    if (searchOptions.noMap || (search.services && search.services.has(ONLINE_SERVICES))) {
      mapStyle = styles.mapHidden;
    } else if (view === VIEW_MAP) {
      mapStyle = styles.mapInnerContainer;
    } else if (view === VIEW_SPLIT) {
      mapStyle = styles.splitMap;
    }
    return mapStyle;
  }

  handleFacilityTypeChange(facilityType) {
    const { search } = this.state;
    const value = facilityType === null ? null : facilityType.value;
    this.setState(
        { search: {...search, facilityType: value} },
        () => {
          this.getSearchedFacilities();
        },
    );
  }

  render() {
    const {
      view,
      search,
      search: { page },
      search: { locationName },
      showSearchFilters,
      showSearchRadius,
    } = this.state;
    const {
      searchOptions,
      searchOptions: { defaultCenterPoint, hideFilters = false, widgetType, defaultView = '' },
      systemConfig: { version },
      systemConfig,
      systemOptions,
    } = this.props;
    return (
      <div
        ref={this.searchContainer}
        className={[view === VIEW_SPLIT ? grid.containerFluid : grid.container, 'gh-facilities-section'].join(' ')}
      >
        <div>
          {(version === 2 || version === 2.1) ? (
            <>
              <FacilitySearch
                search={search}
                locationName={locationName}
                searchOptions={searchOptions}
                handleSearchBarChange={this.handleSearchBarChange}
                handleLocationChange={this.handleLocationChange}
                handleFiltersOptionsChange={this.handleFiltersOptionsChange}
                fetchFilteredSearch={this.fetchFilteredSearch}
                openSearchRadius={this.openSearchRadius}
                showSearchRadius={showSearchRadius}
                handleFacilityTypeChange={this.handleFacilityTypeChange}
                systemConfig={systemConfig}
              />
                  {widgetType !== FT_PERSONAL_PROFILE &&
                    <FacilitySearchBar
                          handleMapChange={this.handleMapChange}
                          view={view}
                          search={search}
                          locationName={locationName}
                          searchOptions={searchOptions}
                          handleSearchBarChange={this.handleSearchBarChange}
                          handleLocationChange={this.handleLocationChange}
                          handleSearchFiltersChange={this.handleSearchFiltersChange}
                          handleFiltersOptionsChange={this.handleFiltersOptionsChange}
                          fetchFilteredSearch={this.fetchFilteredSearch}
                          systemConfig={systemConfig}
                          hideFilters={hideFilters}
                          openSearchFilters={this.openSearchFilters}
                          showSearchFilters={showSearchFilters}
                      />
                  }
            </>
          ) : (
            <FacilitySearchV1
              search={search}
              locationName={locationName}
              searchOptions={searchOptions}
              handleSearchBoxChange={this.handleSearchBox}
              handleSearchButtonChange={this.handleSearchButton}
              handleRadiusChange={this.handleRadiusChange}
              handleLocationChange={this.handleLocationChange}
            />
          )}
        </div>

        <div className={[grid.containerFluid, styles.container].join(' ')}>
          <div
            style={{ display: view === VIEW_LIST || view === VIEW_SPLIT ? 'block' : 'none' }}
            className={['gh-facilities-list', view === VIEW_LIST ? styles.listContainer : ''].join(' ')}
          >
            <div className={[grid.row].join(' ')}>
              {!searchOptions.hideFacilityAdd && (
                <div className={[grid.col, styles.upsellLeft].join(' ')}>
                  <b>
                    {`${getFacilityTypeMessage('facility.claim.upsell', widgetType)} `}
                  </b>
                  <a
                    href={`${
                      systemOptions.appUrl ? systemOptions.appUrl : process.env.REACT_APP_PROVIDER_PATH
                    }/facilityClaim/createFull`}
                    target="_blank"
                    className={[styles.claimButton, 'gh-claim-button'].join(' ')}
                  >
                    <FormattedMessage id="facility.claim.upsell.button" />
                  </a>
                </div>
              )}
              {!searchOptions.hideLogin && (
                <div className={[grid.col, styles.upsellRight].join(' ')}>
                  <a
                    href={`${
                      systemOptions.appUrl ? systemOptions.appUrl : process.env.REACT_APP_PROVIDER_PATH
                    }/login/auth`}
                    target="_blank"
                    className={[styles.claimButton, 'gh-claim-button'].join(' ')}
                  >
                    <FormattedMessage id="header.login" />
                  </a>
                </div>
              )}
            </div>

            <FacilityList
              currentPage={page}
              handlePagination={this.handlePagination}
              view={view}
              openSearchFilters={this.openSearchFilters}
              openSearchRadius={this.openSearchRadius}
              handleFiltersOptionsChange={this.handleFiltersOptionsChange}
              version={version}
            />
          </div>

          <div className={[this.getMapStyle()].join(' ')}>
            <div className={styles.upsellRight}></div>
            <Sticky bottomBoundary="#bottom-boundary">
              <div id="map-container" ref={this.mapContainer} className={styles.mapContainer}>
                <FacilityMap
                  handleGoogleApiLoaded={this.handleGoogleApiLoaded}
                  handleLocationChange={this.handleLocationChange}
                  defaultCenterPoint={defaultCenterPoint}
                  view={defaultView}
                />
              </div>
            </Sticky>
          </div>
          <div id="bottom-boundary"></div>
        </div>
      </div>
    );
  }
}

Facilities.defaultProps = {
  searchOptions: {
    defaultCenterPoint: {
      lat: null,
      lng: null,
    },
    defaultRadius: null,
  },
  systemOptions: {},
};

Facilities.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatchFacilities: PropTypes.func.isRequired,
  dispatchServices: PropTypes.func.isRequired,
  dispatchInstitution: PropTypes.func.isRequired,
  dispatchGoogleMaps: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  features: PropTypes.object.isRequired,
  systemOptions: PropTypes.object,
  searchOptions: PropTypes.object,
};

const mapDispatchToProps = {
  dispatchFacilities: loadFacilities,
  dispatchServices: loadServices,
  dispatchInstitution: loadInstitution,
  dispatchGoogleMaps: loadGoogleMap,
  dispatchInitFacilities: loadInitFacilities,
};

// reducer is called facilities
const mapStateToProps = state => ({
  systemOptions: getSystemOptions(state),
  features: getFeatures(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Facilities);
