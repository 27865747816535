import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import DemographicFilter from '../SearchFilters/DemographicFilter';
import FinancesFilter from '../SearchFilters/FinancesFilter';
import HousingFilter from '../SearchFilters/HousingFilter';
import AmenitiesFilter from '../SearchFilters/AmenitiesFilter';
import ServicesFilter from '../SearchFilters/ServicesFilter';
import { getServices } from '../../../../selectors/services';
import TransportationFilter from '../SearchFilters/TransportationFilter';

import { ReactComponent as X } from '../../../../images/icons/v2/x.svg';
import { ReactComponent as Filter } from '../../../../images/icons/v2/filter.svg';

import { getGoogleMap } from '../../../../selectors/map';

import styles from './filterModal.module.css';
import {getFacilityFeatures} from "../../../../constants/facilityType";

const customStyles = {
  overlay: { zIndex: 100000 },
};

class FilterModal extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleViewRecoveryHouses = this.handleViewRecoveryHouses.bind(this);
  }

  handleClearAll() {
    const { handleFiltersOptionsChange } = this.props;

    handleFiltersOptionsChange('clear');
  }

  handleClose() {
    const { handleCloseModal } = this.props;
    handleCloseModal();
  }

  handleViewRecoveryHouses() {
    const { fetchFilteredSearch } = this.props;
    fetchFilteredSearch();
    this.handleClose();
  }

  render() {
    const { show, initialServices = [], handleFiltersOptionsChange, search } = this.props;
    const facilityFeatures = getFacilityFeatures(search.facilityType);
    return (
      <Modal
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
        }}
        onAfterClose={() => {
          document.body.removeAttribute('style');
        }}
        isOpen={show}
        className={styles.modal}
        ariaHideApp={false}
        style={customStyles}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              <Filter />
              <h2 className="gh-modal-filter-title">
                <FormattedMessage id="more.filters.title" />
              </h2>
            </div>
            <div className={styles.headerContainerRight}>
              <div onClick={this.handleClose} data-dismiss="modal" className={styles.btnClose}>
                <X />
              </div>
            </div>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.container}>
              <DemographicFilter
                handleFiltersOptionsChange={handleFiltersOptionsChange}
                initialServices={initialServices}
                search={search}
              />

              {(facilityFeatures === undefined || facilityFeatures.includes('financeSection')) &&
                <FinancesFilter handleFiltersOptionsChange={handleFiltersOptionsChange} search={search}/>
              }
              {(facilityFeatures === undefined || facilityFeatures.includes('otherDetails')) &&
                <HousingFilter handleFiltersOptionsChange={handleFiltersOptionsChange} search={search}/>
              }
              <AmenitiesFilter
                initialServices={initialServices}
                handleFiltersOptionsChange={handleFiltersOptionsChange}
                search={search}
              />
              {/*<TransportationFilter handleFiltersOptionsChange={handleFiltersOptionsChange} search={search} />*/}
              <ServicesFilter
                handleFiltersOptionsChange={handleFiltersOptionsChange}
                initialServices={initialServices}
                search={search}
              />
            </div>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.needHelpButton}>
              <button className={styles.clearButton} onClick={this.handleClearAll}>
                <FormattedMessage id="button.clear.all" />
              </button>
            </div>
            <button className={styles.viewButton} onClick={this.handleViewRecoveryHouses}>
              <FormattedMessage id="button.filter" />
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
FilterModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  initialServices: PropTypes.array.isRequired,
  handleFiltersOptionsChange: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  initialServices: getServices(state),
  googleMap: getGoogleMap(state),
});

export default connect(mapStateToProps)(FilterModal);
