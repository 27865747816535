import mediaQuerySelector from '../../utils/mediaQuerySelector';

const desktop = {
  container: styles => ({
    ...styles,
    display: 'inline-block',
    width: '270px',
    border: 0,
    paddingLeft: '5px',
  }),
  control: styles => ({
    ...styles,
    border: 0,
    paddingRight: '5px',
    borderRadius: '3px 0 0 3px',
    paddingLeft: 0,
    minHeight: '30px',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: 0,
    border: 0,
    height: '36px',
  }),
  menuList: styles => ({
    ...styles,
    border: 0,
  }),

  placeholder: styles => ({
    ...styles,
    top: '18px',
    fontWeight: 'normal',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    marginTop: '8px!important',
  }),
};

const mobile = {
  container: styles => ({
    ...styles,
    display: 'inline-block',
    width: '88%',
    paddingLeft: '5px',
  }),
  control: styles => ({
    ...styles,
    border: 0,
    paddingRight: '5px',
    borderRadius: '5px 0 0 5px',
    paddingLeft: 0,
    minHeight: '30px',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: 0,
    border: 0,
    height: '36px',
  }),
  menuList: styles => ({
    ...styles,
    border: 0,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
    marginTop: '8px!important',
  }),

  placeholder: styles => ({
    ...styles,
    top: '18px',
    fontWeight: 'normal',
  }),
};

const iPad = {
  container: styles => ({
    ...styles,
    display: 'inline-block',
    width: '200px',
    border: 0,
    paddingLeft: '5px',
  }),
  control: styles => ({
    ...styles,
    border: 0,
    paddingRight: '5px',
    borderRadius: '3px 0 0 3px',
    paddingLeft: 0,
    minHeight: '30px',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: 0,
    border: 0,
    height: '36px',
  }),
  menuList: styles => ({
    ...styles,
    border: 0,
  }),

  placeholder: styles => ({
    ...styles,
    top: '18px',
    fontWeight: 'normal',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    marginTop: '8px!important',
  }),
};

const selectBox = () => {
  const type = mediaQuerySelector();
    switch (type) {
    case 'sm':
        return mobile;
    case 'md':
        return iPad;
    default:
      return desktop;
    }
};

export default selectBox;
