import { createSelector } from 'reselect';

export const getServicesSelector = state => state.services.services;

export const getSuggestedServicesSelector = state => state.services.suggested;

export const getServices = createSelector(
  [getServicesSelector],
  (services) => {
    const serviceClone = services.map( item => {
      item.type = 'SERVICE';
      return item;
    });
    return serviceClone;
  }
);

export const getSuggestedServices = createSelector(
  [getSuggestedServicesSelector],
  (suggested) => {
    const serviceClone = suggested.map( item => {
      item.id = item.ids[0];
      return item;
    });
    return serviceClone;
  }
);

