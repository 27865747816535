import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { FormattedMessage } from 'react-intl';

import { getFacilities, getFacilitiesPageCount, isFetchingFacilities } from '../../../selectors/facilities';
import { getSystemOptions, getSystemStyleOverrides } from '../../../selectors/system';
import FacilityItem from './FacilityItem';
import FacilitySearchNoResults from '../FacilitySearchNoResults';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facilities.module.css';
import paginationStyles from './pagination.module.css';

class FacilityList extends PureComponent {
  renderItem(item) {
    const {
      styleOverrides: { facilityItem },
      systemOptions,
      view,
      version
    } = this.props;

    return (
      <FacilityItem
        facility={item}
        key={item.id}
        styleOverrides={facilityItem}
        systemOptions={systemOptions}
        view={view}
        version={version}
      />
    );
  }

  render() {
    const {
      facilities,
      currentPage,
      handlePagination,
      isFetching,
      facilitiesPageCount,
      systemOptions,
      systemOptions: { searchOptions = {} },
      openSearchFilters,
      openSearchRadius,
      handleFiltersOptionsChange,
    } = this.props;
    return (
      <div className={[grid.row, styles.searchListContainer].join(' ')}>
        <div className={[grid.col].join(' ')}>
          {facilities.length > 0 && facilities.map(facility => this.renderItem(facility))}

          {facilitiesPageCount > 0 && (
            <div className={[paginationStyles.container, 'gh-pagination'].join(' ')}>
              <ReactPaginate
                forcePage={parseInt(currentPage, 10) !== 0 ? parseInt(currentPage, 10) : 0}
                onPageChange={handlePagination}
                pageCount={facilitiesPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                previousLabel="<"
                nextLabel=">"
                containerClassName={paginationStyles.pagination}
                activeClassName={paginationStyles.active}
                disabledClassName={paginationStyles.disabled}
              />
            </div>
          )}
          {!isFetching && facilities.length === 0 && (
            <FacilitySearchNoResults
              openSearchFilters={openSearchFilters}
              openSearchRadius={openSearchRadius}
              handleFiltersOptionsChange={handleFiltersOptionsChange}
            />
          )}
          {!isFetching && !searchOptions.hideLogin && (
            <div className={grid.row}>
              <div className={[grid.col].join(' ')}>
                <FormattedMessage id="search.login.1" />
                <a
                  href={`${systemOptions.appUrl ?
                    systemOptions.appUrl : process.env.REACT_APP_PROVIDER_PATH}/login/auth`}
                  target="_blank"
                  className={styles.loginLink}
                >
                  <b>
                    <FormattedMessage id="search.login.2" />
                  </b>
                </a>
              </div>
              <div className={[grid.col, styles.empower].join(' ')}><FormattedMessage id="search.empower" /></div>
            </div>
          )}
          {!isFetching && searchOptions.hideLogin && (
            <div className={grid.row}>
              <div className={[grid.col, styles.empower].join(' ')}><FormattedMessage id="search.empower" /></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

FacilityList.defaultProps = {
  currentPage: 0,
  facilities: [],
  facilitiesPageCount: 0,
  systemOptions: {},
  styleOverrides: {},
};

FacilityList.propTypes = {
  handlePagination: PropTypes.func.isRequired,
  currentPage: PropTypes.any,
  facilities: PropTypes.array,
  facilitiesPageCount: PropTypes.number,
  isFetching: PropTypes.bool.isRequired,
  systemOptions: PropTypes.object,
  styleOverrides: PropTypes.object,
  view: PropTypes.string.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facilities: getFacilities(state),
  facilitiesPageCount: getFacilitiesPageCount(state),
  isFetching: isFetchingFacilities(state),
  systemOptions: getSystemOptions(state),
  styleOverrides: getSystemStyleOverrides(state),
});

export default connect(mapStateToProps)(FacilityList);
