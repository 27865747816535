import { combineReducers } from 'redux';

import facilities from './facilities';
import facility from './facility';
import services from './services';
import map from './map';
import system from './system';

const rootReducer = combineReducers({
  facilities,
  facility,
  services,
  map,
  system,
});

export default rootReducer;
