import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Menu, { MenuItem, SubMenu } from 'rc-menu';
import 'rc-slider/assets/index.css';

import { ReactComponent as Arrow } from '../../../../images/icons/v2/down-arrow.svg';
import { ReactComponent as X } from '../../../../images/icons/v2/x.svg';

import '../../../../styles/general.css';
import styles from './facilitySearchBar.module.css';
import { SORT_LIST } from '../../../../constants/Sort';

class BarSort extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      valueSelected: {},
    };
    this.handleExpanded = this.handleExpanded.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      search,
      search: { sort = '' },
    } = this.props;

    if (prevProps.search !== search) {
      let item = SORT_LIST.find(element => element.value === sort);

      if (item) {
        this.setState({
          valueSelected: item,
        });
      }
    }
  }

  handleExpanded() {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  handleSelect(info) {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;

    let item = SORT_LIST.find(element => element.label === info.key);
    this.setState({
      valueSelected: item ? item : {},
    });

    if (item && item.value !== 'recommended') {
      handleFiltersOptionsChange('sort', item);
    } else {
      handleFiltersOptionsChange('sort', { value: null });
    }
    fetchFilteredSearch();
  }

  handleClear() {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;
    this.setState({
      valueSelected: {},
    });
    handleFiltersOptionsChange('sort', { value: null });
    fetchFilteredSearch();
  }

  render() {
    const { expanded, valueSelected } = this.state;

    return (
      <>
        <Menu
          mode="horizontal"
          overflowedIndicator=""
          triggerSubMenuAction="click"
          onClick={this.handleSelect}
          onOpenChange={this.handleExpanded}
        >
          <SubMenu
            title={
              <div>
                <span className="submenu-title-wrapper">
                  {valueSelected.label ? <span>{valueSelected.label}</span> : <FormattedMessage id="search.bar.sort" />}
                </span>
                {valueSelected.label && <X onClick={this.handleClear} className={styles.removeIcon} />}
                <Arrow className={expanded ? styles.upArrow : styles.downArrow} />
              </div>
            }
            key="sort"
          >
            {SORT_LIST.map(item => (
              <MenuItem key={item.label} className={styles.filterOption}>
                <span>{item.label}</span>
              </MenuItem>
            ))}
          </SubMenu>
        </Menu>
      </>
    );
  }
}

BarSort.propTypes = {
  handleFiltersOptionsChange: PropTypes.func.isRequired,
  fetchFilteredSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default injectIntl(BarSort);
