import { createSelector } from 'reselect';
import isEmpty from "lodash/isEmpty";

export const getFacilitiesSelector = state => state.facilities.facilities;

export const getFacilitiesTotalCount = state => state.facilities.facilitiesTotalCount;
export const getFacilitiesPageSize = state => state.facilities.facilitiesPageSize;

export const isFetchingFacilities = state => state.facilities.isFetching;

export const getFacilities = createSelector(
  [getFacilitiesSelector],
  (facilities) => {
    const facilitiesClone = [...facilities];
    facilitiesClone.forEach( item => {
      if(item.website && !item.website.includes('http')) {
        item.website = `http://${item.website}`;
      }
    });

    return facilitiesClone;
  }
);




export const getFacilitiesPageCount = createSelector(
  [getFacilities, getFacilitiesTotalCount, getFacilitiesPageSize],
  (facilities, totalCount, pageSize) => {
    let pages = 0;
    if(!facilities.length) {
      return pages;
    }

    pages = totalCount > facilities.length ? Math.ceil(totalCount / pageSize) : 0;

    return pages;
  }
);
