import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { animateScroll as scroll } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import UrlAssembler from 'url-assembler';
/** @jsx jsx */
import { jsx } from '@emotion/core';

import FacilityCard from '../common/FacilityCard/FacilityCard';

import styles from './facilities.module.css';
import FacilityStatusBar from "../common/FacilityStatusBar/FacilityStatusBar";

class FacilityItem extends Component {

  static getStyleOverrides(overrides) {
    const styleOverrides = {};

    if(overrides.minWidth) {
      styleOverrides.minWidth = overrides.minWidth;
    }
    if(overrides.listItemColor) {
      styleOverrides.color = `${overrides.listItemColor}!important`;
    }

    return styleOverrides;
  }

  static getFacilityUrl(pattern, id) {
    const url =  UrlAssembler().template(pattern).param({id}).toString();
    return decodeURIComponent(url);
  }

  constructor(props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.myRef = React.createRef(); // Create a ref object

    this.state = {
      overrides: {
        facilityUrl: '#/facility/:id'
      }
    };
  }

  componentDidMount() {

    const { systemOptions : { pageOverrides : { facilityUrl } = {} }} = this.props;
    if(facilityUrl) {
      // const { overrides: { facilityUrl } } = this.state;
      this.setState({overrides: { facilityUrl } } );
    }
  }

  componentDidUpdate(prevProps) {
    const { clickedFacility } = this.props;

    if (prevProps.facility && clickedFacility && prevProps.facility.id === clickedFacility.id) {
      scroll.scrollTo(this.myRef.current.offsetTop + this.myRef.current.offsetParent.offsetTop - 10);
    }
  }

  handleMouseEnter() {
    const { facility, handleListItemMouseEnter } = this.props;

    handleListItemMouseEnter(facility);
  }

  handleMouseLeave() {
    const { handleListItemMouseLeave } = this.props;

    handleListItemMouseLeave();
  }



  render() {

    const {
      selectedFacility,
      facility,
      styleOverrides,
      systemOptions,
    } = this.props;

    const { overrides: { facilityUrl } } = this.state;

    const isSelectedFacility = selectedFacility && selectedFacility.id === facility.id;

    return (
      <a
        href={this.constructor.getFacilityUrl(facilityUrl, facility.id)}
        id={facility.id}
        className={[isSelectedFacility ? styles.facilityItemSelected : styles.facilityItem,
          'gh-facilities-item'].join(' ')}
        css={this.constructor.getStyleOverrides(styleOverrides)}

        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={this.myRef}
        // onClick={this.handleClick}
      >
        {facility.clientManagement && (<FacilityStatusBar status={facility.status} />)}
        <FacilityCard facility={facility} systemOptions={systemOptions} />
      </a>
    );
  }
}

FacilityItem.defaultProps = {
  selectedFacility: null,
  clickedFacility: null,
  styleOverrides: {},
  systemOptions: {},
};

FacilityItem.propTypes = {
  facility: PropTypes.object.isRequired,
  selectedFacility: PropTypes.object,
  clickedFacility: PropTypes.object,
  handleListItemMouseEnter: PropTypes.func.isRequired,
  handleListItemMouseLeave: PropTypes.func.isRequired,
  styleOverrides: PropTypes.object,
  systemOptions: PropTypes.object,
};

export default withRouter(FacilityItem);
