import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './facilities.module.css';

const FacilitySearchNoResults = ({ openSearchFilters, openSearchRadius, handleFiltersOptionsChange }) => {
  const handleOpenModal = () => {
    handleFiltersOptionsChange('clear');
    openSearchFilters();
  };

  const handleOpenRadius = () => {
    openSearchRadius();
  };
  return (
    <div className={styles.noResultsContainer}>
      <div className={styles.noResults}>
        <FormattedMessage id="search.no_results" />
      </div>
      <div>
        <FormattedMessage id="search.no_results.description" />
      </div>
      <div className={styles.noResultsSuggestionContainer}>
        <div className={styles.noResultsHeader}>
          <FormattedMessage id="search.no_results.suggestion.header" />
        </div>
        <ul className={styles.noResultsSuggestions}>
          <li>
            <FormattedMessage id="search.no_results.suggestion.location" />
          </li>
          <li className={styles.removeFilters}>
            <a onClick={handleOpenRadius}>
              <span>
                <FormattedMessage id="search.no_results.link.radius" />
              </span>
            </a>
            <FormattedMessage id="search.no_results.suggestion.radius" />
          </li>
          <li className={styles.removeFilters}>
            <div>
              <a onClick={handleOpenModal}>
                <span>
                  <FormattedMessage id="search.no_results.link.filters" />
                </span>
              </a>
              <FormattedMessage id="search.no_results.suggestion.filters" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FacilitySearchNoResults;
