import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

/** @jsx jsx */
import { jsx } from '@emotion/core';

import { getServices, getSuggestedServices} from '../../selectors/services';

import ServiceButton from '../common/ServiceButton/ServiceButton';

import styles from './facilities.module.css';
import { getSystemStyleOverrides} from "../../selectors/system";

const MAX_SUGGESTED_ITEMS = 14;

class FacilitySuggested extends Component {

  // TODO Move this to a selector
  static getSelectedObject(haystack = [], selectedItems = new Set(), search = {}) {
    const selectedItem = [];

    selectedItems.forEach( item => {
        if((search.requiredServiceId && search.requiredServiceId === item) ||
          (search.requiredAccreditationId && search.requiredAccreditationId === item) ) {
          return;
        }

        const needle = haystack.find(obj =>  obj.id === item );
        if(needle) {
          selectedItem.push(needle);
        }
      });
    return selectedItem;
  }

  static getSuggestedMappedServices(suggestedServices = [],
                              selectedServices = [], maxLength = selectedServices.length) {

    const suggestedServicesSubset = suggestedServices.slice(0, maxLength);
    const suggestedServiceButtons = suggestedServicesSubset.reduce((accumulator, service) => {

      accumulator.push({
        service,
        selected: !!selectedServices.find( item => item.id === service.id )
      });

      return accumulator;
    }, []);

    return suggestedServiceButtons;
  }

  static getStyleOverrides(overrides) {
    const styleOverrides = {};

    if(overrides.background) {
      styleOverrides.background = overrides.background;
    }

    return styleOverrides;
  }

  render() {
    const {
      searchServices,
      suggestedServices,
      selectedServices,
      selectedAccreditations,
      handleSearchButtonChange,
      styleOverrides : { suggestedSection = {}},
      styleOverrides,
      search,
    } = this.props;

    const selectedServicesMapped = this.constructor.getSelectedObject(searchServices, selectedServices, search);
    const selectedAccMapped = this.constructor.getSelectedObject(suggestedServices,selectedAccreditations);
    const selectedButtons = [...selectedServicesMapped, ...selectedAccMapped];

    const suggestedServiceButtons = this.constructor.getSuggestedMappedServices(suggestedServices,
      selectedButtons, MAX_SUGGESTED_ITEMS);

    return (
      <Fragment>
        {(selectedButtons.length > 0 || suggestedServiceButtons.length > 0) && (
          <div
            className={[styles.row, styles.suggested, 'gh-service-section' ].join(' ')}
            css={this.constructor.getStyleOverrides(suggestedSection)}
          >
            {selectedButtons.length > 0 && (
              <div className={[styles.selectedServicesContainer].join(' ')}>
                <div className={[styles.selectedServices].join(' ')}>
                  {selectedButtons.map(item => {
                    return (
                      <ServiceButton
                        key={uuidv4()}
                        service={item}
                        handleClick={handleSearchButtonChange}
                        selected
                        showCloseIcon
                        styleOverrides={styleOverrides}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <div className={[styles.selectedServices, 'gh-service-selected-section'].join(' ')}>
              <div className={styles.suggestedHeader}>Suggested Filters</div>
              {suggestedServiceButtons.map(suggestedService => {
                return (
                  <ServiceButton
                    key={uuidv4()}
                    service={suggestedService.service}
                    selected={suggestedService.selected}
                    handleClick={handleSearchButtonChange}
                    styleOverrides={styleOverrides}
                  />
                );
              })}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

FacilitySuggested.propTypes = {
  searchServices: PropTypes.array.isRequired,
  suggestedServices: PropTypes.array.isRequired,
  selectedServices: PropTypes.instanceOf(Set).isRequired,
  selectedAccreditations: PropTypes.instanceOf(Set).isRequired,
  handleSearchButtonChange: PropTypes.func.isRequired,
  styleOverrides: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  suggestedServices: getSuggestedServices(state),
  searchServices: getServices(state),
  styleOverrides: getSystemStyleOverrides(state),


});

export default connect(mapStateToProps)(FacilitySuggested);
