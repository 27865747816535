import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';

class FacilityDeposit extends PureComponent {
  render() {
    const { facility } = this.props;

    return (
      facility.depositRequired !== null && (
        <div className={[styles.container].join(' ')}>
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}><FormattedMessage id="facility.header.deposit"/></h2>
            </div>
          </div>
          <div className={[grid.row, styles.box1].join(' ')}>
            <div className={[grid.col12, grid.colSm6, grid.colMd4].join(' ')}>
              <b><FormattedMessage id="facility.deposit.label.amountdue"/></b>
              <FormattedMessage id="facility.deposit.amountdue" values={{amount: facility.depositAmount}}/>
            </div>
              {facility.depositDue && (
                  <div className={[grid.col12, grid.colSm6, grid.colMd4].join(' ')}>
                      <b><FormattedMessage id="facility.deposit.label.duedate"/></b>
                      <FormattedMessage id={`facility.deposit.duedate.${facility.depositDue}`}/>
                  </div>
              )}
          </div>
        </div>
      )
    );
  }
}

FacilityDeposit.defaultProps = {};

FacilityDeposit.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityDeposit);
