import { request } from './BaseRequest';

export const fetchSuggestServices = ({ apiKey, facilityId }) =>
  request({
    url: '/v1/search-buttons',
    apiKey,
    args: { facilityId },
  }).then(async (response = {}) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    const data = await response.json();
    throw new Error(data.details);
  });

export const fetchServices = ({ widgetType = '' }) => {
  const MAX_SERVICES = 1000;
  const args = {
    pageSize: MAX_SERVICES,
  };
  if (widgetType) {
    args.facilityType = widgetType;
  }
  return request({
    url: '/v1/services',
    args: args,
  }).then(async (response = {}) => {
    if (response.status === 200) {
      const data = await response.json();
      return data.content;
    }
    const data = await response.json();
    throw new Error(data.details);
  });
};
