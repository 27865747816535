import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import uuidv4 from 'uuid/v4';

import { getFacilitySelector } from '../../../selectors/facility';
import { ReactComponent as WheelChair } from '../../../images/icons/v2/wheelchair.svg';
import ImageResizedForHeight from '../../common/v2/ImageResizedForHeight/ImageResizedForHeight';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';

const NO_IMAGE = require('../../../images/no_photo.png');

export const Status = ({ room, allowWaitList }) => {
  const dateToString = room.bedUpdatedAt
    ? new Date(room.bedUpdatedAt).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
    : null;

  return (
    <div>
      <div className={styles.accAvail}>
        <span
          className={[styles.circle, room.status === 1 ? styles.statusAvailable : styles.statusUnavailable].join(' ')}
        ></span>
        <FormattedMessage id={`facility.accom.status.${allowWaitList && room.status === 2 ? 3 : room.status}`} />
      </div>
      {dateToString && <div className={styles.accDate}>{`(as of ${dateToString})`}</div>}
    </div>
  );
};

class FacilityAccommodations extends PureComponent {
  renderEmptyData() {
    const {
      facility: { beds = 0, bath = 0, recommendedStay = null, accommodations = [] },
    } = this.props;

    return beds === 0 && bath === 0 && recommendedStay === null && accommodations.length === 0;
  }
  renderAccommodationCard(room) {
    const { facility } = this.props;
    const { allowWaitList = false } = facility;

    return (
      <div key={uuidv4()} className={[grid.col12, grid.colMd4].join(' ')}>
        <div className={[styles.accCard].join(' ')}>
          <div className={styles.accCardHeader}>
            <span>{room.name}</span>
            {room.adaRoomsAvailable && (
              <span className={styles.accWheelChairIcon}>
                <WheelChair />
              </span>
            )}
          </div>
          <div className={styles.accCardBody}>
            <Status room={room} allowWaitList={allowWaitList} />
            <div className={[styles.bedContainer].join(' ')}>
              <ImageResizedForHeight src={room.image ? room.image : NO_IMAGE} />
            </div>
            {room.avgCost && (
              <p className={[styles.accContent, styles.accContentPrice].join(' ')}>
                <FormattedMessage id="facility.accom.from" values={{ from: room.avgCost }} />
              </p>
            )}
            {room.description && (
              <p className={styles.accDescription} title={room.description}>
                {room.description}
              </p>
            )}
            {room.avgSize && (
              <p className={[styles.accContent, styles.accContentSize].join(' ')}>
                <FormattedMessage id="facility.accom.size" values={{ size: room.avgSize }} />
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderTotals(count, message) {
    return (
      <div className={[grid.col12, grid.colMd4].join(' ')}>
        <div className={[styles.accCard].join(' ')}>
          <h3 className={styles.accTypeHeader}>
            <FormattedMessage id={message} />
          </h3>
          <div className={styles.accTypeBody}>{count}</div>
        </div>
      </div>
    );
  }
  renderAccommodationsDetails() {
    const { facility } = this.props;

    return (
      <div className={[grid.row, styles.box1].join(' ')}>
        {facility.beds > 0 && this.renderTotals(facility.beds, 'facility.accom.beds')}
        {facility.baths > 0 && this.renderTotals(facility.baths, 'facility.accom.bath')}
        {facility.recommendedStay &&
          facility.recommendedStay !== null &&
          this.renderTotals(facility.recommendedStay, 'facility.accom.rec.stay')}
      </div>
    );
  }

  render() {
    const { facility } = this.props;

    return (
      !this.renderEmptyData() && (
        <>
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}>
                <FormattedMessage id="facility.header.accommodation" />
              </h2>
            </div>
          </div>
          <div>{this.renderAccommodationsDetails()}</div>
          {facility.accommodations && facility.accommodations.length > 0 && (
            <div key={uuidv4()} className={grid.row}>
              {facility.accommodations.map(room => this.renderAccommodationCard(room))}
            </div>
          )}
        </>
      )
    );
  }
}

FacilityAccommodations.defaultProps = {};

FacilityAccommodations.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityAccommodations);
