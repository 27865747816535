import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

export const getSnippetAddress = (city = '', state = '', zip = '', county = '') => {
  return (
    <Fragment>
      <div>
        {city && `${city} `}
        {state && `${state}`}
        {zip && <span>{`, ${zip}`}</span>}
      </div>
      {county}
      {county && county.toLowerCase().indexOf('county') === -1 && (
        <>
          {' '}
          <FormattedMessage id="common.county" />
        </>
      )}
    </Fragment>
  );
};

export const getFullAddress = (address1 = '', address2 = '', city = '', state = '', zip = '', county = '') => {
  return (
    <Fragment>
      {address1 && <div>{address1}</div>}
      {address2 && <div>{address2}</div>}
      {getSnippetAddress(city, state, zip, county)}
    </Fragment>
  );
};

export const getSnippetAddressSingleLine = (city = '', state = '', zip = '', county = '') => {
  return (
    <Fragment>
      {city && `${city} `}
      {state && `${state}`}
      {zip && <span>{`, ${zip}`}</span>}
      {county && (
        <span>
          {' '}
          &bull; {county}
          {county.toLowerCase().indexOf('county') === -1 && (
            <>
              {' '}
              <FormattedMessage id="common.county" />
            </>
          )}
        </span>
      )}
    </Fragment>
  );
};

export const getFullAddressSingleLine = (
  address1 = '',
  address2 = '',
  city = '',
  state = '',
  zip = '',
  county = '',
) => {
  return (
    <Fragment>
      {address1 && <span>{address1}</span>}
      {address2 && <span> &bull; {address2}</span>}
      {(city || state) && <> &bull; {getSnippetAddressSingleLine(city, state, zip, county)}</>}
    </Fragment>
  );
};
