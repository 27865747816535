import React, { Component, Fragment } from 'react';


import FacilityHeader from '../../components/facility/FacilityHeader';
import FacilityContent from '../../components/facility/FacilityContent';
import FacilityService from '../../components/facility/FacilityService';

import grid from '../../styles/bootstrap-grid.module.css';
import styles from './facilities.module.css';
import PropTypes from "prop-types";
import {isFetchingFacilities} from "../../selectors/facilities";
import {getSystemOptions} from "../../selectors/system";
import {getFeatures} from "../../selectors/facility";
import {connect} from "react-redux";

class Facility extends Component {

  componentDidMount() {
    const { systemOptions = {} } = this.props;
    if(systemOptions.scrollTop) {
      document.querySelector(`.${systemOptions.scrollTop}`).scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Fragment>

            <div className={[grid.containerFluid, styles.facilityContainer].join(' ')}>
              <div className={grid.row}>
            <div className={[grid.col, grid.colXSm12].join(' ')}>
              <div className={[grid.row, styles.facilityHeader].join(' ')}>
                <FacilityHeader />
              </div>
              <div className={grid.row}>
                <FacilityContent />
              </div>
            </div>
            <div className={[grid.col4, grid.colXSm12, grid.noGuttersBoth].join(' ')}>
              {/* <div className={[grid.row, grid.rowBottom,].join(' ')}> */}
              {/*  <div className={grid.col} style={{height: '180px', border: '1px solid red'}}>image</div> */}
              {/* </div> */}
              <div className={grid.row}>
                <div className={grid.col}>
                  <FacilityService />
                </div>
              </div>
            </div>
          </div>
            </div>

      </Fragment>
    );
  }
}
Facility.defaultProps = {
  systemOptions: {},

};

Facility.propTypes = {
  systemOptions: PropTypes.object,

};
// reducer is called facilities
const mapStateToProps = (state) => ({
  isFetching: isFetchingFacilities(state),
  systemOptions: getSystemOptions(state),
  features: getFeatures(state),

});

export default connect(
  mapStateToProps,
)(Facility);
