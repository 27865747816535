import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getSnippetAddress } from '../LocationFormat';
// import FacilityBed from '../FacilityBed/FacilityBed';
// import FacilityAvailability from '../FacilityAvailability/FacilityAvailability';
import FacilityStatus from '../FacilityStatus/FacilityStatus';
import FacilityBadges from '../FacilityBadges/FacilityBadges';
import FacilityFlag from '../FacilityFlag/FacilityFlag';
import FacilityAccreditations from '../FacilityAccreditations/FacilityAccreditations';

import styles from './facilityCard.module.css';

const getDefaultImg = facility => {
  if (facility.thumbnailUrl) {
    return '';
  }

  let backgroundUrl = styles.imgOverride1;

  const lastDigit = facility.id % 10;
  if (lastDigit % 2 === 0) {
    backgroundUrl = styles.imgOverride2;
  } else if (lastDigit % 3 === 0) {
    backgroundUrl = styles.imgOverride1;
  } else if (lastDigit % 5 === 0) {
    backgroundUrl = styles.imgOverride3;
  }
  return backgroundUrl;
};

const FacilityCard = ({ facility, systemOptions }) => {
  return (
    <div className={[styles.facilityCard, 'gh-card'].join(' ')}>
      <div
        className={[styles.imgContainer, 'facility-card-img', 'gh-card-img', getDefaultImg(facility)].join(' ')}
        style={facility.thumbnailUrl ? { backgroundImage: `url(${facility.thumbnailUrl})` } : {}}
      />
      <div className={styles.facilityItemContent}>
        <div className={['facility-card-header', styles.facilityHeader, styles.lineClamp].join(' ')}>
          {facility.name}
        </div>
        <div className={styles.address}>{getSnippetAddress(facility.city, facility.state, facility.zipCode)}</div>
        <div className={styles.badges}>
          <div className={styles.badgeRow}>
            <div className={styles.badgeCol}>
              <FacilityBadges facility={facility} />
              {facility.accreditationIds && <FacilityAccreditations accreditations={facility.accreditationIds} />}
            </div>
            <FacilityFlag rating={facility.rating} />
          </div>
          <div className={styles.badgeRow}>
            <div className={[styles.badgeCol, styles.facilityType].join(' ')}>
              <span><FormattedMessage id={`facility.type.${facility.facilityType}`}/></span>
            </div>
            <div className={[styles.badgeCol, 'gh-distance'].join(' ')}>
              {facility.distance > 0 && (
                <>
                  <span className={styles.distance} />
                  {`${Number.parseFloat(facility.distance).toFixed(2)} `}
                  <FormattedMessage id="distance.miles" />
                </>
              )}
            </div>
            <FacilityStatus status={facility.status} />
            {/* <div className={styles.badgeCol}> */}

            {/*  {systemOptions.hasBedAvailability  && ( */}
            {/*    <FacilityAvailability beds={facility.freeBeds} availability={facility.bedsAvailability} /> */}
            {/*  )} */}
            {/* </div> */}
            {/* <FacilityBed beds={facility.beds} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

FacilityCard.defaultProps = {
  systemOptions: {},
};

FacilityCard.propTypes = {
  facility: PropTypes.object.isRequired,
  systemOptions: PropTypes.object,
};

export default FacilityCard;
