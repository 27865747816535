import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import AutoComplete from '../../../common/map/AutoComplete';
import Select from 'react-select';
import selectStyles from './SelectStyles';

class FacilitySearchLocation extends PureComponent {
  getState() {
    let {
      search: { province },
    } = this.props;
    const states = this.getStateOptions();

    return province ? states.find(item => item.value === province.toUpperCase()) : null;
  }

  getStateOptions() {
    const {
      systemOptions: { searchOptions = {} },
      intl: { messages },
    } = this.props;
    const states = [];
    const { requiredAccreditationId = null } = searchOptions;

    for (const [key, label] of Object.entries(messages)) {
      if (key.startsWith('state.')) {
        const keyArray = key.split('.');
        if (requiredAccreditationId && Array.isArray(requiredAccreditationId)) {
          //if the state is in the array of acccrediations
          if (requiredAccreditationId.includes(parseInt(keyArray[1], 10))) {
            states.push({ label, value: keyArray[2] });
          }
          // we have an array of required id's
        } else if (requiredAccreditationId && Number.isInteger(parseInt(requiredAccreditationId, 10))) {
          if (parseInt(requiredAccreditationId, 10) === parseInt(keyArray[1], 10)) {
            states.push({ label, value: keyArray[2] });
          }
        } else {
          states.push({ label, value: keyArray[2] });
        }
      }
    }
    return states;
  }

  render() {
    const {
      googleMap,
      handleLocationChange,
      locationName,
      className,
      search: { radius, province },
      placeholder,
    } = this.props;

    return (
      <>
        {(radius === 99999 || province) && (
          <Select
            ref={ref => {
              this.select = ref;
            }}
            options={this.getStateOptions()}
            styles={selectStyles()}
            name="province"
            value={this.getState()}
            isSearchable={false}
            className="basic-single"
            onChange={place => handleLocationChange(place)}
          />
        )}
        {radius !== 99999 && !province && googleMap.mapApiLoaded && (
          <AutoComplete
            map={googleMap.mapInstance}
            mapApi={googleMap.mapApi}
            addplace={handleLocationChange}
            value={locationName}
            className={className}
            placeholder={placeholder}
          />
        )}
      </>
    );
  }
}
FacilitySearchLocation.defaultProps = {
  className: '',
  systemOptions: {},
};

FacilitySearchLocation.propTypes = {
  handleLocationChange: PropTypes.func.isRequired,
  googleMap: PropTypes.object.isRequired,
  locationName: PropTypes.string,
  className: PropTypes.string,
  search: PropTypes.object.isRequired,
  systemOptions: PropTypes.object,
};

export default injectIntl(FacilitySearchLocation);
