import { combineReducers } from 'redux';

import {
  STORE_SYSTEM_CONFIG,

  STORE_AUTH_FAILED,
  STORE_AUTH_REQUESTED,
  STORE_AUTH_SUCCEEDED
} from '../actions/system';

const config = (state = {}, { config: systemConfig, type }) => {

  switch (type) {
    case STORE_SYSTEM_CONFIG:
      return systemConfig;
    default:
      return state;
  }
};
const auth = (state = {}, { data, type }) => {

  switch (type) {
    case STORE_AUTH_SUCCEEDED:
        return data;
    case STORE_AUTH_FAILED:
    case STORE_AUTH_REQUESTED:
        default:
      return state;
  }
};
export default combineReducers({
  config,
  auth,
});
