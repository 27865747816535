import { request } from './BaseRequest';

export const fetchFacility = ({ id = 0, version} ) => {
  const apiVersion = version > 1 ? 'v2' : 'v1';
  return  request({
    url: `/${apiVersion}/facilities/${id}`,
  }).then(async (response ={}) => {

    if (response.status === 200) {
      const data = await response.json();

      return data;
    }
    const data = await response.json();

    throw new Error(data.details);
  });
};


export const fetchInstitution = ({ id = 0} ) =>
  request({
    url: `/v1/institutions/${id}`,
  }).then(async (response ={}) => {

    if (response.status === 200) {
      const data = await response.json();

      return data;
    }
    const data = await response.json();

    throw new Error(data.details);
  });
