import { all } from 'redux-saga/effects';

import facilitiesSaga from './facilitiesSaga';
import facilitySaga from './facilitySaga';
import servicesSaga from './servicesSaga';
import authSaga from './authSaga';

export default function* rootSaga() {
  yield all([].concat(facilitySaga, facilitiesSaga, servicesSaga, authSaga));
}
