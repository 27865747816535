import React, { Component } from 'react';
import { connect } from 'react-redux';
// V2 components
import FacilityHeader from '../../components/facility/v2/FacilityHeader';
import FacilityLanguage from '../../components/facility/v2/FacilityLanguage';
import FacilityPopulation from '../../components/facility/v2/FacilityPopulation';
import FacilityDescription from '../../components/facility/v2/FacilityDescription';
import FacilityPaymentOption from '../../components/facility/v2/FacilityPaymentOption';
import FacilityMedia from '../../components/facility/v2/FacilityMedia';
import FacilityLicense from '../../components/facility/v2/FacilityLicense';
import FacilityAccommodations from '../../components/facility/v2/FacilityAccommodations';
import FacilityDeposit from '../../components/facility/v2/FacilityDeposit';
import FacilityAccessibility from '../../components/facility/v2/FacilityAccessibility';
import FacilityAmenities from '../../components/facility/v2/FacilityAmenities';
import FacilityResidentRequirements from '../../components/facility/v2/FacilityResidentRequirements';
import FacilityHousePolicy from '../../components/facility/v2/FacilityHousePolicy';
import FacilityTransportation from '../../components/facility/v2/FacilityTransportation';
import FacilityService from '../../components/facility/v2/FacilityService';
import FacilityApplication from '../../components/facility/v2/FacilityApplication';
import FacilityRent from '../../components/facility/v2/FacilityRent';
import { getFacilitySelector } from '../../selectors/facility';

import grid from '../../styles/bootstrap-grid.module.css';
import { getSystemOptions } from '../../selectors/system';
import PropTypes from 'prop-types';
import { FT_PERSONAL_PROFILE } from '../../constants/Other';

class Facility extends Component {
  componentDidMount() {
    const { systemOptions = {} } = this.props;

    if (systemOptions.scrollTop) {
      const elem = document.querySelector(`.${systemOptions.scrollTop}`);
      if (elem) {
        elem.scrollIntoView();
      }
    } else {
      window.onbeforeunload = function() {
        window.scrollTo(0, 0);
      };
    }
  }

  render() {
    const {
      systemOptions: { searchOptions: { widgetType = '' } = {} },
    } = this.props;

    return (
      <div className={[grid.container].join(' ')}>
        <div className={grid.row}>
          <div className={[grid.col, grid.colXSm12].join(' ')}>
            <FacilityHeader widgetType={widgetType} />
            <FacilityLicense />
            <FacilityMedia widgetType={widgetType} />
            <FacilityDescription />
            <FacilityPopulation widgetType={widgetType} />
            <FacilityLanguage />
            <FacilityRent widgetType={widgetType} />
            <FacilityAmenities />
            <FacilityPaymentOption />
            <FacilityDeposit />
            <FacilityHousePolicy />
            <FacilityAccommodations />
            <FacilityAccessibility />
            {widgetType !== FT_PERSONAL_PROFILE && <FacilityResidentRequirements />}
            <FacilityTransportation />
            <FacilityService  />
            <FacilityApplication />
          </div>
        </div>
      </div>
    );
  }
}
Facility.defaultProps = {
  systemOptions: {},
};

Facility.propTypes = {
  systemOptions: PropTypes.object,
};

// reducer is called facilities
const mapStateToProps = state => ({
  systemOptions: getSystemOptions(state),
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(Facility);
