export const FACILITIES_FETCH_REQUESTED = 'FACILITIES_FETCH_REQUESTED';
export const FACILITIES_FETCH_SUCCEEDED = 'FACILITIES_FETCH_SUCCEEDED';
export const FACILITIES_FETCH_FAILED = 'FACILITIES_FETCH_FAILED';

export const FACILITIES_INIT_REQUESTED = 'FACILITIES_INIT_REQUESTED';
export const FACILITIES_INIT_SUCCEEDED = 'FACILITIES_INIT_SUCCEEDED';
export const FACILITIES_INIT_FAILED = 'FACILITIES_INIT_FAILED';

export const loadFacilities = args => ({
  type: FACILITIES_FETCH_REQUESTED,
  args,
});

export const facilitiesFetchSucceeded = data => ({
  type: FACILITIES_FETCH_SUCCEEDED,
  data,
});

export const facilitiesFetchFailed = message => ({
  type: FACILITIES_FETCH_FAILED,
  message,
});

export const loadInitFacilities = args => ({
  type: FACILITIES_INIT_REQUESTED,
  args,
});
