/* eslint-disable max-len */

const localeData = {
  // *********************** SAMPLES ***********************
  'common.descriptor': 'common.descriptor',
  'component.descriptor1.descriptor2': 'Container.component.descriptor',

  // *********************** Header Main ***********************
  'header.brand': 'Get Help',
  'header.visit': 'Visit Website',
  'header.login': 'Login',
  'header.signup': 'Sign Up',

  // *********************** COMMON ***********************
  'button.cancel': 'Cancel',
  'button.change': 'Change',
  'button.close': 'Close',
  'button.continue': 'Continue',
  'button.message': 'Message',
  'button.ok': 'Ok',
  'button.refresh': 'Refresh',
  'button.save': 'Save',
  'button.skip': 'Skip',
  'button.map.search': 'Redo Search In Map',
  'button.clear': 'Clear',
  'button.clear.all': 'Clear All',
  'button.filter': 'View Results',

  'common.find': 'Find',
  'common.and': 'and',
  'common.county': 'County',
  'common.status': 'Status:',
  'common.expiryDate': 'Expiry:',
  'common.issueDate': 'Issue date:',

  'no.info': 'No information has been entered yet.',

  // *********************** Facility ***********************
  'facility.accredited': 'Accredited',
  'facility.available': 'Available',
  'facility.unavailable': 'Unavailable',
  'facility.beds': 'Beds',
  'distance.miles': 'mi.',
  'distance.miles.location': 'miles from location',

  'facility.accreditation': 'Accreditation & Affiliations',
  'facility.accreditation.IN_PROCESS': 'accreditation in process',
  'facility.overview': 'About Us',
  'facility.service': 'Services',
  'facility.levels_of_care': 'Levels of Care',
  'facility.license': 'License',
  'facility.licenses': 'Licenses',
  'facility.insurance': 'Insurance',
  'facility.in_network': 'In Network',
  'facility.out_of_network': 'Out of Network',
  'facility.social': 'Find us On',
  'facility.claimed': 'Claimed',
  'facility.unclaimed': 'Unclaimed',
  'facility.hours': 'Hours',
  'facility.unpublished': 'This profile is not publicly visible in search results.',

  'facility.additional_contact': 'Additional Contact',
  'facility.additional.intake_number': 'Intake Number',
  'facility.additional.toll_free_number': 'Toll Free Number',
  'facility.additional.email': 'Admission Email',
  'facility.additional.website': 'Additional Website',
  'facility.view.website': 'View website',

  'facility.status.Available': 'Beds Available',
  'facility.status.Unavailable': 'No Beds Available',
  'facility.status.Call': 'Call for bed availability',

  'facility.status.Available.PERSONAL_PROFILE': 'Accepting New Clients',
  'facility.status.Unavailable.PERSONAL_PROFILE': 'Not Accepting New Clients',
  'facility.status.Call.PERSONAL_PROFILE': 'Call for Availability',

  'facility.status.availabilityDate': ' (as of ',

  'facility.claimed.desc': 'This business has been claimed by the owner or a representative.',
  'facility.unclaimed.desc': 'This business has not yet been claimed by the owner or a representative. ',
  'facility.unclaimed.desc.click': 'Click to claim it!',

  'facility.claim.upsell': "Don't see your facility?",
  'facility.claim.upsell.PERSONAL_PROFILE': "Don't see your profile?",
  'facility.claim.upsell.button': 'Click here to get listed',

  'facility.deposit.label.amountdue': 'Amount due:',
  'facility.deposit.amountdue': ' ${amount}',

  'facility.deposit.label.duedate': 'Due date:',
  'facility.deposit.duedate.FLEXIBLE_PAYMENT': ' Flexible payment option',
  'facility.deposit.duedate.MOVE_IN_DAY_PAYMENT': ' Move-in day',

  'facility.modal.show.all': 'Show All {value}  >',

  // *********************** Search ***********************
  'search.select': 'Select or search by facility name',
  'search.no_results': 'No Results were found',
  'search.no_results.description':
    'This may be due to limited search criteria, or a lack of data for resources near you.',
  'search.no_results.suggestion.header': 'Suggestions for improving the results:',
  'search.no_results.suggestion.location':
    'Try a different location (such as state, city, or ZIP). Make sure to click on the appropriate recommended location in the dropdown as you type.',
  'search.no_results.link.filters': 'Remove search filters ',
  'search.no_results.suggestion.filters': 'to expand your search criteria.',
  'search.no_results.link.radius': 'Expand search radius ',
  'search.no_results.suggestion.radius': 'to include ALL results nearest to you.',
  'search.rating': 'rating',
  'search.login.1': 'Already have an account with us? ',
  'search.login.2': 'Login here!',
  'search.empower': 'Empowered by GET HELP®',
  // *********************** ERROR MODAL ***********************
  'errorModal.header': 'Oh no.',
  'errorModal.body': 'Something went wrong, please try again.',

  // *********************** Badge tool tips ***********************
  'facility.badges.tooltip.header.verified': 'Verified Information',
  'facility.badges.tooltip.body.verified':
    'This program or service organization has verified the accuracy of its data in the GET HELP system.',
  'facility.badges.tooltip.header.claimed': 'Claimed Facility',
  'facility.badges.tooltip.body.claimed':
    'This program or service organization has claimed ownership of its listing to edit and update information in the GET HELP system. ',
  'facility.badges.tooltip.header.premium': 'Premium Facility',
  'facility.badges.tooltip.body.premium':
    'This program or service organization has advanced ownership and editing capabilities in the GET HELP system and are actively engaged in updating their listing.',

  // *********************** Rating tool tips ***********************
  'facility.rating.tooltip.header': 'Rating',
  'facility.rating.tooltip.body':
    "This program or service organization's Rating is based on the proprietary \"27 Standards\" developed by GET HELP's Standard's Council, Advisory Board, and in cooperation with Industry Partners.",

  // *********************** ERROR BOUNDARY ***********************
  'errorBoundary.body': "We've been notified of this issue. Please hit 'Refresh' below to try again.",
  'errorBoundary.header': 'Something went wrong.',

  // *********************** Accreditations (will move to db later) ***********************
  'accreditation.1': 'AHRQ',
  'accreditation.2': 'FACT',
  'accreditation.3': 'AAAHC',
  'accreditation.4': 'URAC',
  'accreditation.5': 'NCQA',
  'accreditation.6': 'CARF',
  'accreditation.7': 'JCAHO',
  'accreditation.9': 'Charity Navigator Four Star Charity',
  'accreditation.10': 'Council on Accreditation',
  'accreditation.11': 'SAMHSA',
  'accreditation.12': 'CCAPP Members',
  'accreditation.13': 'HFAP',
  'accreditation.14': 'CCAPP Educational Institute (Continuing Education)',
  'accreditation.15': 'CCAPP Educational Institute (Schools)',
  'accreditation.16': 'BHAP',
  'accreditation.17': 'ARCC Certified',
  'accreditation.19': 'GARR Certified',
  'accreditation.20': 'CCAPP Certified',
  'accreditation.21': 'CCAPP Recovery Community Organization',
  'accreditation.22': '',
  'accreditation.23': 'Oxford House Kentucky',
  'accreditation.24': 'MCRSP Members',
  'accreditation.25': 'WAQRR Accredited',
  'accreditation.26': 'MCRSP/NARR Accredited',
  'accreditation.27': 'AzRHA Certified',
  'accreditation.29': 'CTARR Certified',
  'accreditation.34': 'KRHN Certified',
  'accreditation.35': 'MARR Certified',
  'accreditation.38': 'MARR Certified',
  'accreditation.39': 'MASH Certified',
  'accreditation.40': 'NHCORR Certified',
  'accreditation.45': 'PARR Certified',
  'accreditation.47': 'TN-ARR Certified',
  'accreditation.52': 'VARR Certified',
  'accreditation.54': 'W.A.S.H. Certified',
  'accreditation.56': 'PARR Recommended',
  'accreditation.57': 'OKARR Certified',
  'accreditation.59': 'NYSARR Certified',
  'accreditation.66': 'FSARR Certified',
  'accreditation.status.UNDEFINED': '',
  'accreditation.status.VERIFIED': 'Verified',
  'accreditation.status.IN_PROCESS': 'In Process',
  'accreditation.status.NOT_VERIFIED': 'Not Verified',
  'accreditation.status.ARCHIVED': 'Archived',

  // *********************** V2 Facility ***********************
  'facility.header.contact': 'Contact Info',
  'facility.header.pop': 'People Served',
  'facility.header.pop.PERSONAL_PROFILE': 'Languages',
  'facility.header.priority.pop': 'Priority Populations',
  'facility.subheader.priority.pop':
    'This house accepts all populations listed above, but has programs or services that specifically cater to the following:',
  'facility.header.languages': 'Languages Spoken',
  'facility.header.languages.PERSONAL_PROFILE': 'Languages Spoken/Interpreted ',
  'facility.header.accommodation': 'Bed Types',
  'facility.header.payment': 'Accepted Payment Option',
  'facility.header.deposit': 'Deposit Information',
  'facility.header.accessibility': 'Accessibility',
  'facility.header.amenities': 'Amenities',
  'facility.header.res.requirements': 'Requirements',
  'facility.header.rules': 'Rules and Policies',
  'facility.header.transportation': 'Transportation',
  'facility.header.services': 'Services & Programs',
  'facility.header.application.process': 'Application Process',
  'facility.header.rent': 'Fees',

  'facility.view.app.button': 'View Application Process',

  'facility.search.insurance': 'Accepts insurance',
  'facility.search.payments': 'Weekly payments accepted',
  'facility.search.upfront': 'upfront',
  'facility.label.abstinencedefinition': 'Our Definition of Abstinence:',
  'facility.label.structureType': 'Type of Structure',
  'facility.rent.more': 'See specific bed prices below under Bed Types',
  'facility.license.disclaimer.true': '*This house complies with state and/or local permit and licensing requirements',
  'facility.license.disclaimer.false': '*No information yet about local permit and licensing requirements',
  'facility.license.LEVEL1': '(Level 1)',
  'facility.license.LEVEL2': '(Level 2)',
  'facility.license.LEVEL3': '(Level 3)',
  'facility.license.LEVEL4': '(Level 4)',
  'facility.contactinfo.button': 'View contact info',
  'facility.data.none.specified': 'None Specified',
  'facility.allowWaitlist': 'Allow Waitlist',
  'facility.allowWaitlist.description':
    'This house uses a waitlist system. If there is no availability and you are still interested in this house, please use the contact information below to get on the waitlist.',
  'facility.allowWaitlist.description.PERSONAL_PROFILE':
    'This provider uses a waitlist system. If there is no availability and you are still interested in their services, please use the contact information below to get on the waitlist.',

  // *********************** V2 WhatsThis Popup ***********************
  'facility.help.whatsthis': 'What is this?',
  'facility.help.popup.header.1': 'Facility certification and level',
  'facility.help.popup.content.1': 'The facility certification is something...',
  'facility.help.popup.header.2': 'Policies and Procedures',
  'facility.help.popup.content.2':
    "Good recovery housing often involves some type of rules and policies to make sure that each individual's recovery is safe and effective. Having documented and easy to understand rules and policies is a good sign that the house is professional and well-organized.",
  'facility.help.popup.header.3': 'Oxford House',
  'facility.help.popup.content.3':
    'Democratically and self-run drug-free sober living homes where residents pay living expenses. Community-based recovery approach provides an independent and supportive environment.',

  // *********************** V2 Accessibililty ***********************
  'facility.accessibility.AMEN_ADA_ROOM': 'This building has at least one wheelchair-accessible room with beds',
  'facility.accessibility.AMEN_ADA_COMMON': 'Building entrance and common areas are wheelchair accessible',

  // *********************** V2 Transportation ***********************
  'facility.trans.OWN_VEHICLE': 'Free parking for own vehicle',
  'facility.trans.PUBLIC': 'Near public transportation',
  'facility.trans.IN_HOUSE': 'In-house transportation service',
  'facility.trans.RIDESHARE': 'Taxi or rideshare (Uber, Lyft, etc.)',
  'facility.trans.VEHICLE_ALLOWED': 'Personal vehicles allowed',
  'facility.trans.VEHICLE_STAY': 'Personal vehicles not allowed during stay',
  'facility.trans.VEHICLE_ALLOWED_RESTRICT': 'Personal vehicles allowed with some restrictions',

  // *********************** V2 Services & Programs ***********************
  'facility.services.header.inhouse': 'In-house',
  'facility.services.header.inhouse.PERSONAL_PROFILE': 'Referrals and Services Available',
  'facility.services.header.offsite': 'Off-Site',
  'facility.services.header.offsite.PERSONAL_PROFILE': 'Services you can connect Participants to ',
  'facility.services.header.collaborative': 'Collaborative',
  'facility.services.header.walking': 'Places in walking distance',

  'facility.services.CAT_12_STEP': '12-step recovery program',

  ////***** 12 step services
  'facility.services.12_STEP_AA': 'Alcoholics Anonymous',
  'facility.services.12_STEP_NA': 'Narcotics Anonymous',
  'facility.services.12_STEP_MA': 'Marijuana Anonymous',
  'facility.services.12_STEP_CA': 'Cocaine Anonymous',
  'facility.services.12_STEP_AL': 'Al-Anon',
  'facility.services.12_STEP_CELEBRATE': 'Celebrate Recovery',

  // ***** Programs
  'facility.services.PRGM_SEX_OFFENDER': 'Probation Approved Sex Offender Program',
  'facility.services.PRGM_CLOTHING': 'Clothing Program',
  'facility.services.PRGM_SMART': 'SMART recovery',
  'facility.services.PRGM_RATIONAL': 'Rational Recovery',
  'facility.services.PRGM_WOMEN_SOBRIETY': 'Women for Sobriety',
  'facility.services.PRGM_MODERATION': 'Moderation Management',
  'facility.services.PRGM_LIFERING': 'LifeRing Secular Recovery',
  'facility.services.PRGM_MAT': 'Medication-Assisted Treatment/Opioid Treatment (MAT)',

  // ***** Treatment
  'facility.services.TA_IPT': 'Individual Psychotherapy / Counseling',
  'facility.services.TA_COUPLES_FAM': 'Couples / Family Therapy',
  'facility.services.TA_GRP_THERAPY': 'Group Therapy',
  'facility.services.TA_CBT': 'Cognitive Behavioral Therapy (CBT)',
  'facility.services.TA_DBT': 'Dialectical Behavior Therapy (DBT)',
  'facility.services.TA_CLINICAL_THERAPY': 'Clinical group therapy',
  'facility.services.TA_COMPLEMENTARY_THERAPIES': 'Complementary therapies (e.g., yoga or meditation)',
  'facility.services.TA_INTERPERSONAL_THERAPY': 'Interpersonal therapy',
  'facility.services.TA_MEDICAL_SUPERVISION': 'Medical supervision',
  'facility.services.TA_RECREATIONAL_THERAPIES': 'Recreational therapies',
  'facility.services.TA_SUPPORT_GROUPS': 'Support groups',
  'facility.services.TA_THERAPEUTIC_COMMUNITY': 'Therapeutic community',
  'facility.services.TA_OTHER_MENTAL': 'Other Mental health services',

  'facility.services.SA_LIFE_SKILLS': 'Life and social skills',
  'facility.services.SA_CHILDCARE': 'Childcare',
  'facility.services.clinical.therapy': 'Clinical group therapy',
  'facility.services.RES_DETOX': 'Detoxification',
  'facility.services.SA_EDUCATION_SUPPORT': 'Education support',
  'facility.services.SA_EMPLOYMENT_SUPPORT': 'Employment support',
  'facility.services.SA_CLERGY': 'Faith-based program',
  'facility.services.group.therapy': 'Group therapy',
  'facility.services.counseling': 'Individual counseling',
  'facility.services.SA_LEGAL': 'Legal services',
  'facility.services.CAT_TREATMENT': 'Mental health services',
  'facility.services.skills': 'New social skills and social recovery',
  'facility.services.SA_PEER_SUPPORT': 'Peer support',
  'facility.services.community.group': 'Therapeutic community group',

  'facility.services.near.CONVENIENCE_STORE': 'Convenience store',
  'facility.services.near.DENTAL_SERVICES': 'Dental services',
  'facility.services.near.GROCERY_STORE': 'Grocery store',
  'facility.services.near.GAS_STATION': 'Gas station',
  'facility.services.near.HEALTHCARE': 'Healthcare',
  'facility.services.near.PHARMACY': 'Pharmacy',
  'facility.services.near.RESTAURANTS': 'Restaurants',
  'facility.services.near.OTHER': 'Other',

  // *********************** V2 Application Process ***********************
  'facility.application.process.applicationRequired': 'Application Required',
  'facility.application.process.screeningRequired': 'Screening Required',
  'facility.application.process.urineTestRequired': 'Resident must complete a urine analysis and/or breathalyzer test',
  'facility.application.process.backgroundCheckRequired': 'Background Check Required',
  'facility.application.process.applicationFeeRequired': 'Application Fee Required',

  // *********************** V2 Accommodations ***********************

  'facility.accom.cost': 'Total move-in cost:',
  'facility.accom.cost.depositAmount': 'Deposit: ',
  'facility.accom.cost.additionalFeeAmount': 'Additional move-in fee: ',
  'facility.accom.cost.applicationFeeAmount': 'Application fee: ',
  'facility.accom.cost.totalAmount': 'Total: ',
  'facility.accom.deposit': 'Deposit required',
  'facility.accom.deposit.flex': 'Flexible deposit options available',
  'facility.accom.application.fee': 'Application fee required',
  'facility.accom.financial': 'Financial assistance available',
  'facility.accom.weekly': 'Weekly payments accepted',

  'facility.accom.beds': 'Total beds',
  'facility.accom.bath': 'Total bathrooms',
  'facility.accom.min.stay': 'Minimum stay',
  'facility.accom.rec.stay': 'Recommended stay',
  'facility.accom.from': 'From',
  'facility.accom.from.suffix': ' ${from}/month',
  'facility.accom.size': '{size} Sqr/ft',

  'facility.accom.status.0': 'Call to verify',
  'facility.accom.status.1': 'Bed available',
  'facility.accom.status.2': 'No bed available',
  'facility.accom.status.3': 'Waitlist',
  'facility.fee.servicesDetails': 'Services Details: ',
  'facility.fee.servicesDetails.PERSONAL_PROFILE': 'Services Fee: ',

  // *********************** V2 Accommodations Amennities ***********************
  'facility.amen.show.all': 'Show all amenities >',
  'facility.amen.show.less': 'Show less >',

  // *********************** V2 Resident Requirements ***********************
  'facility.res.req.abstinence': 'Minimum abstinence requirement',
  'facility.res.req.meeting': 'Requires house meetings',
  'facility.res.req.chore': 'Requires Chores',
  'facility.res.req.curfew': 'Has curfew',
  'facility.res.req.convictions': 'Correctional History',
  'facility.res.req.convictions.accept':
    'We do not accept individuals convicted of the following or associated with the following legal circumstances:',
  'facility.res.req.convictions.contact': 'Contact the house for more information about legal exceptions',

  'facility.res.req.criminal.ARSON': 'Arson',
  'facility.res.req.criminal.ASSAULT': 'Assault',
  'facility.res.req.criminal.BATTERY': 'Battery',
  'facility.res.req.criminal.FELONY_STATUS': 'Felony status',
  'facility.res.req.criminal.SEX_OFFENDER': 'Sex offender',
  'facility.res.req.criminal.VIOLENT_OFFENDER': 'Violent offender',
  'facility.res.req.criminal.HOME_INCARCERATION': 'Home incarceration',
  'facility.res.req.criminal.PAROLE': 'Parole',
  'facility.res.req.criminal.PROBATION': 'Probation',

  // *********************** V2 House Rules ***********************
  'facility.rules.policy.house': 'General house guidelines',
  'facility.rules.policy.medication': 'General medication policy',
  'facility.rules.policy.mat': 'Medication-Assisted treatment policy',
  'facility.rules.policy.mat.moudPolicy':
    'Medication-Assisted Treatment (MAT) and Medication for Opioid Use Disorder (MOUD) friendly',
  'facility.rules.policy.mat.moudMeds': 'Accepted Forms of Medication for Opioid Use Disorder',
  'facility.rules.policy.return': 'Return to use policy',
  'facility.rules.policy.financial': 'Financial policy',
  'facility.rules.policy.work': 'Resident work policy',
  'facility.rules.policy.education': 'Education policy',
  'facility.rules.policy.program': 'Program/Services participation policy',
  'facility.rules.policy.refund': 'Refund policy',
  'facility.rules.policy.discharge': 'Discharge policy',
  //'facility.rules.smoking.yes': 'Smoking allowed',
  //'facility.rules.smoking.no': 'Smoking not allowed',
  'facility.rules.policy.procedures': 'Policies and procedures',
  'facility.rules.policy.linklabel': 'View our {linklabel} policy document',
  'facility.rules.policy.tobacco': 'Tobacco use',
  'facility.rules.smokingPolicy.PT_TOBACCO_ALLOWED': 'Tobacco allowed',
  'facility.rules.smokingPolicy.PT_TOBACCO_NOT_ALLOWED': 'Tobacco not allowed',
  'facility.rules.smokingPolicy.PT_TOBACCO_ALLOWED_DESIGNATED': 'Tobacco use allowed in designated areas',
  'facility.rules.policy.marijuana': 'Marijuana use',
  'facility.rules.smokingPolicy.PT_MARIJUANA_ALLOWED': 'Marijuana allowed',
  'facility.rules.smokingPolicy.PT_MARIJUANA_NOT_ALLOWED': 'Marijuana not allowed',
  'facility.rules.smokingPolicy.PT_MARIJUANA_PRESCRIPTION': 'Marijuana prescription only',

  'facility.policy.selector.DISCHARGE_PROCEDURES': 'Discharge residents',
  'facility.policy.selector.EDUCATION': 'Education document',
  'facility.policy.selector.FINANCIAL': 'Financial',
  'facility.policy.selector.REFUND_BELONGINGS': 'Refund belongings',
  'facility.policy.selector.RELAPSE_OR_RETURN': 'Relapse or return',
  'facility.policy.selector.RESIDENT_BEHAVIOUR': 'Resident Behaviour',
  'facility.policy.selector.WORK_REQUIREMENTS': 'Work requirements',
  'facility.policy.selector.EMPLOYMENT': 'Employment opportunities',

  'facility.policy.moud.BUPRENORPHINE': 'Buprenorphine',
  'facility.policy.moud.SUBUTEX': 'Subutex',
  'facility.policy.moud.SUBOXONE': 'Suboxone',
  'facility.policy.moud.NALTREXONE': 'Naltrexone',
  'facility.policy.moud.VIVITROL': 'Vivitrol',
  'facility.policy.moud.METHADONE': 'Methadone',

  // *********************** V2 Population ***********************
  'facility.population.POP_FEMALE_ONLY': 'Women only',
  'facility.population.POP_UNDER_18_FEMALE': 'Women under 18',
  'facility.population.POP_FEMALE_ADULT': 'Women',
  'facility.population.POP_PREGNANCY': 'Pregnant & expecting',
  'facility.population.POP_CHILDREN_WOMEN': 'Women w/ children',

  'facility.population.POP_MALE_ONLY': 'Men Only',
  'facility.population.POP_UNDER_18_MALE': 'Men under 18',
  'facility.population.POP_MALE_ADULT': 'Men',
  'facility.population.POP_CHILDREN_MEN': 'Men w/ children',

  'facility.population.POP_COED': 'Coed',
  'facility.population.POP_COUPLE': 'Couples',
  'facility.population.POP_CHILDREN_COUPLE': 'Couples w/ children',

  'facility.population.POP_LGBTQ': 'LBGTQIA+ friendly',
  'facility.population.POP_INTERSEX': 'Intersex',
  'facility.population.POP_NON_BINARY': 'Non binary',
  'facility.population.POP_TRANS_MAN': 'Trans men',
  'facility.population.POP_TRANS_WOMEN': 'Trans women',

  'facility.population.POP_VETERANS': 'Veterans',
  'facility.population.POP_DEAF': 'Deaf & hard of hearing',
  'facility.population.POP_BLIND': 'Blind & visually impaired',
  'facility.population.POP_HOMELESS': 'Homeless & housing insecure',
  'facility.population.POP_INDIGENOUS': 'Indigenous',
  'facility.population.POP_RENTRY': 'Re-entry population',
  'facility.population.POP_JUSTICE': 'Justice involved',
  'facility.population.POP_COURT_INVOLVEMENT': 'Any court involvement',
  'facility.population.POP_OCCURRING_MENTAL_HEALTH_DISORDER': 'Co-occurring Mental Health Disorder',

  'facility.population.NEUTRAL': 'Neutral',
  'facility.population.WOMEN_ONLY': 'Women only',
  'facility.population.MEN_ONLY': 'Men only',

  // *********************** V2 Structure Type ***********************

  'facility.structureType.SINGLE_FAMILY_HOUSE': 'Single Family detached house',
  'facility.structureType.APARTMENT_UNITS': 'One or more apartment units',
  'facility.structureType.DUPLEX_OR_TRIPLEX': 'Duplex or triplex',
  'facility.structureType.APARTMENT_BUILDING': 'Apartment building',
  'facility.structureType.CONDOMINIUM_UNITS': 'Condominium unit(s)',
  'facility.structureType.OTHER': 'Other',

  // *********************** V2 Facilities ***********************
  // *********************** V2 Search ***********************
  'search.label.i.am': 'I am a...',
  'search.label.distance': 'Looking for help...',
  'SCHOOL.search.label.distance': 'Looking for Educator...',
  'search.label.location': 'Near this location…',
  'search.label.for': 'For ...',
  'search.label.name': 'Find',
  'search.label.type': 'Type of service...',
  'search.name': 'Search by facility Name',
  'search.name.PERSONAL_PROFILE': 'Search by Professional Name',
  'search.population.select': 'Select gender',
  'SCHOOL.search.population.select': 'Select Educator Type',

  // *********************** V2 Search Modal ***********************
  'search.modal.less': 'Show less',
  'search.modal.transportation.more': 'Show all Transportation',
  'search.modal.location.name': 'House name',
  'search.modal.location': 'Location',
  'search.modal.provider.name': 'Provider Name',
  'search.modal.gender': 'Gender',
  'search.modal.residentCharacteristics': 'Special Populations',
  'search.modal.languages': 'Languages Spoken',
  'search.modal.correctionalBackground': 'Justice Involvement',
  'search.modal.receivingMAT': 'MAT/MOUD Patient',
  'search.modal.payment.type': 'Payment Methods',
  'search.modal.avgCostMin': 'Min fee per month',
  'search.modal.avgCostMax': 'Max fee per month',
  'search.modal.smokingAllowed': 'Allows tobacco use',
  'search.modal.urineTestRequired': 'Mandatory drug testing',
  'search.modal.houseMeetings': 'Mandatory Meetings',
  'search.modal.NARRLevel': 'NARR Level',
  'search.modal.associations': 'Certifications',
  'search.modal.associations.NARR': 'NARR',
  'search.modal.associations.oxfordHouses': 'Oxford Houses',
  'search.modal.financialAssistance': 'Financial Assistance Available',

  // *********************** V2 Search Bar ***********************
  'search.bar.modifiers': 'Special Populations',
  'search.bar.payment.type': 'Payment Methods',
  'search.bar.avg.rent': 'Average fee',
  'search.bar.availability': 'Show "Available" Only',
  'search.bar.sort': 'Sort',
  'search.bar.more': 'Filters',
  'search.bar.map': 'Map view',
  'search.bar.list': 'List view',
  'search.bar.split': 'Split view',
  'search.bar.modifiers.preg': 'Pregnant',
  'search.bar.modifiers.children': 'With children',
  'search.bar.modifiers.lgbtq': 'LGBTQ+',
  'search.bar.modifiers.blind': 'Blind',
  'search.bar.modifiers.deaf': 'Def & hard of hearing',
  'search.bar.modifiers.homeless': 'Homeless',
  'search.bar.modifiers.native': 'Indigenous peoples',
  'search.bar.modifiers.reentry': 'Re-entry population',
  'search.bar.modifiers.unemployed': 'Unemployed',
  'search.bar.modifiers.veteran': 'Veteran',
  'search.bar.payment.medicaid': 'Medicaid',
  'search.bar.payment.aetna': 'Aetna',
  'search.bar.payment.anthem': 'Anthem',
  'search.bar.payment.humana': 'Humana',
  'search.bar.payment.passport': 'Passport',
  'search.bar.payment.wellcare': 'WellCare',
  'search.bar.payment.medicare': 'Medicare',
  'search.bar.payment.private': 'Private Insurance',
  'search.bar.payment.self': 'Self-Pay',
  'search.bar.payment.payment.plan': 'Payment Plan',
  'search.bar.payment.military': 'Military Insurance / Tri-Care',
  'search.bar.payment.voucher': 'Treatment Voucher',
  'search.bar.payment.scholarship': 'Treatment Scholarship',
  'search.bar.payment.no.fee': 'No Fee',

  'more.filters.title': 'Search Filters',

  'travel.type.WALK_BIKE': 'Walk/Bike',
  'travel.type.PUBLIC': 'Near public transportation',
  'travel.type.RIDESHARE': 'Taxi or rideshare (Uber,Lyft,etc.)',
  'travel.type.AMBULANCE': 'Ambulance',
  'travel.type.OWN_VEHICLE': 'Free parking for own vehicle',
  'travel.type.IN_HOUSE': 'In house transportation service',
  'travel.type.VEHICLE_ALLOWED': 'Personal vehicles allowed',
  'travel.type.VEHICLE_STAY': 'Personal vehicles not allowed during stay',
  'travel.type.VEHICLE_ALLOWED_RESTRICT': 'Personal vehicles allowed with some restrictions',

  'facility.type.INSTITUTION_FACILITY': 'Institution',
  'facility.type.SERVICE_FACILITY': 'Service Facility',
  'facility.type.REFERRING_AGENCY': 'Referring Agency',
  'facility.type.OUTREACH': 'Outreach',
  'facility.type.ASSOCIATION': 'Accreditation Association',
  'facility.type.RECOVERY_RESIDENCE': 'Recovery Residence',
  'facility.type.RCO': 'RCO Facility',
  'facility.type.HEALTH_MENTAL': 'Mental Health',
  'facility.type.HEALTH_BEHAVIORAL': 'Behavioral Health',
  'facility.type.TREATMENT_CLINICAL': 'Clinical Treatment',
  'facility.type.TREATMENT_OUTPATIENT': 'Outpatient Treatment',
  'facility.type.SCHOOL_ONLINE': 'Online School or Provider',
  'facility.type.SCHOOL_LIVE': 'Live School or Provider',
  'facility.type.HOMELESS_SHELTER': 'Homeless Shelter',
  'facility.type.PERSONAL_PROFILE': 'Personal Profile',
  'facility.type.SCHOOL': 'School',
  'facility.type.TRANSITIONAL_HOUSING': 'Transitional Housing',
  'facility.type.PERMANENT_SUPPORTIVE_HOUSING': 'Permanent Supportive Housing',
  'facility.type.EMERGENCY_SHELTER': 'Emergency Shelter',
  'facility.type.RAPID_RE_HOUSING': 'Rapid Re-housing',

  'facility.type.PUBLIC_ASSISTANCE': 'Public Assistance',
  'facility.type.FOOD_NUTRITION': 'Food & Nutrition',
  'facility.type.SUPPORT_GROUPS': 'Support Group',
  'facility.type.HYGIENE': 'Hygiene Services',
  'facility.type.INSURANCE': 'Insurance Provider',
  'facility.type.HARM_REDUCTION': 'Harm Reduction',

  'state.0.AL': 'Alabama',
  'state.AK': 'Alaska',
  'state.27.AZ': 'Arizona',
  'state.0.AR': 'Arkansas',
  'state.20.CA': 'California',
  'state.28.CO': 'Colorado',
  'state.29.CT': 'Connecticut',
  'state.0.DE': 'Delaware',
  'state.30.FL': 'Florida',
  'state.19.GA': 'Georgia',
  'state.0.HI': 'Hawaii',
  'state.0.ID': 'Idaho',
  'state.32.IL': 'Illinois',
  'state.33.IN': 'Indiana',
  'state.0.IA': 'Iowa',
  'state.0.KS': 'Kansas',
  'state.34.KY': 'Kentucky',
  'state.0.LA': 'Louisiana',
  'state.35.ME': 'Maine',
  'state.36.MD': 'Maryland',
  'state.37.MA': 'Massachusetts',
  'state.38.MI': 'Michigan',
  'state.39.MN': 'Minnesota',
  'state.0.MS': 'Mississippi',
  'state.26.MO': 'Missouri',
  'state.0.MT': 'Montana',
  'state.0.NE': 'Nebraska',
  'state.0.NV': 'Nevada',
  'state.40.NH': 'New Hampshire',
  'state.41.NJ': 'New Jersey',
  'state.0.NM': 'New Mexico',
  'state.0.NY': 'New York',
  'state.42.NC': 'North Carolina',
  'state.0.ND': 'North Dakota',
  'state.44.OH': 'Ohio',
  'state.57.OK': 'Oklahoma',
  'state.0.OR': 'Oregon',
  'state.45.PA': 'Pennsylvania',
  'state.43.RI': 'Rhode Island',
  'state.46.SC': 'South Carolina',
  'state.0.SD': 'South Dakota',
  'state.47.TN': 'Tennessee',
  'state.48.TX': 'Texas',
  'state.50.UT': 'Utah',
  'state.51.VT': 'Vermont',
  'state.52.VA': 'Virginia',
  'state.25.WA': 'Washington',
  'state.53.WV': 'West Virginia',
  'state.54.WI': 'Wisconsin',
  'state.0.WY': 'Wyoming',

};

export default localeData;
