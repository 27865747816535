import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';

import {getFacilityTypeMessage} from "../../../../utils/utils";

class FacilitySearchName extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.debounceSearch = debounce(this.debounceSearch, 250);

    this.state = {
      name: '',
    };
  }

  componentDidMount() {
    const { search } = this.props;
    this.setState({
      name: search.name,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        name: search.name,
      });
    }
  }

  debounceSearch(value) {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;

    handleFiltersOptionsChange('name', { value: value });
    if (value === '') {
      handleFiltersOptionsChange('name', { value: null });
    }
    fetchFilteredSearch();
  }

  handleChangeName(e) {
    let value = e.target.value;
    this.setState(
      {
        name: value,
      },
      () => {
        this.debounceSearch(value);
      },
    );
  }

  render() {
    const {
      className,
      intl: { formatMessage },
      widgetType,
    } = this.props;
    const { name } = this.state;

    return (
      <input
        ref={ref => {
          this.searchInput = ref;
        }}
        type="text"
        className={className}
        placeholder={getFacilityTypeMessage('search.name', widgetType)}
        onChange={this.handleChangeName}
        value={name || ''}
      />
    );
  }
}

FacilitySearchName.propTypes = {
  handleFiltersOptionsChange: PropTypes.func.isRequired,
  fetchFilteredSearch: PropTypes.func.isRequired,
};

export default injectIntl(FacilitySearchName);
