/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { injectIntl, FormattedMessage } from 'react-intl';

import { getFacilities } from '../../selectors/facilities';
import { getServices } from '../../selectors/services';
import { getGoogleMap } from '../../selectors/map';

import AutoComplete from '../common/map/AutoComplete';

import styles from './facilities.module.css';
import grid from '../../styles/bootstrap-grid.module.css';

import selectBox from './SelectBox';
import selectRadius from './SelectRadius';
import FacilitySuggested from './FacilitySuggested';

const RADIUS_LIST = [
  { label: '5 miles', value: 5 },
  { label: '10 miles', value: 10 },
  { label: '15 miles', value: 15 },
  { label: '25 miles', value: 25 },
  { label: '50 miles', value: 50 },
  { label: '100 miles', value: 100 },
  { label: '500 miles', value: 500 },
  { label: '1000 miles', value: 1000 },
  { label: '3000 miles', value: 3000 },
];

const generateOptions = searchServices => {
  const options = searchServices.map(({ id, name }) => ({
    value: id,
    label: name.trim(),
  }));

  return options.sort(function(a, b) {
    return a.label.localeCompare(b.label);
  });
};

class FacilitySearch extends Component {
  static getRadius(radius) {
    let selectedRadius = RADIUS_LIST[2];
    if (radius) {
      selectedRadius = RADIUS_LIST.find(item => item.value === parseInt(radius, 10));
    }

    return selectedRadius;
  }

  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
    };

    this.handleServiceChange = this.handleServiceChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleServiceChange(item) {
    const { handleSearchBoxChange } = this.props;

    handleSearchBoxChange({ value: item.value, name: '' });
    this.setState({ inputValue: ' ' });
  }

  handleInputChange(inputValue, action) {
    const { handleSearchBoxChange } = this.props;

    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      this.setState({ inputValue });
      handleSearchBoxChange({ name: inputValue });
    }
  }

  handleKeyDown(e) {
    if (e.keyCode === 13) {
      this.select.select.blur();
      e.preventDefault();
    }
  }

  render() {
    const {
      searchServices,
      handleLocationChange,
      handleSearchButtonChange,
      handleRadiusChange,
      search,
      search: { services, accreditations, name },
      googleMap,
      locationName,
      searchOptions,
      intl: { formatMessage },
    } = this.props;
    let { inputValue } = this.state;

    inputValue = inputValue || name;
    return (
      <Fragment>
        <div className={[styles.row, styles.searchContainer].join(' ')}>
          <label className={[styles.search, 'gh-search-box'].join(' ')}>
            <span>
              <FormattedMessage id="common.find" />
            </span>
            <Select
              inputValue={inputValue}
              cacheOptions
              tabSelectsValue={false}
              ref={ref => {
                this.select = ref;
              }}
              noOptionsMessage={() => null}
              isSearchable
              placeholder={formatMessage({ id: 'search.select' })}
              // hideSelectedOptions
              options={generateOptions(searchServices)}
              styles={selectBox()}
              name="search"
              onChange={this.handleServiceChange}
              onInputChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              components={{
                DropdownIndicator: () => null,
              }}
            />
          </label>
          <label className={[styles.location, 'gh-search-location'].join(' ')}>
            <span>Near</span>
            <div className={styles.locationAutocomplete}>
              {googleMap.mapApiLoaded && (
                <AutoComplete
                  map={googleMap.mapInstance}
                  mapApi={googleMap.mapApi}
                  addplace={handleLocationChange}
                  value={locationName}
                />
              )}
            </div>
          </label>
          {!search.province && (
            <div className={[styles.searchRadius, grid.hiddenSmDown, 'gh-search-radius'].join(' ')}>
              Radius:
              <Select
                ref={ref => {
                  this.select = ref;
                }}
                options={RADIUS_LIST}
                styles={selectRadius()}
                name="radius"
                value={this.constructor.getRadius(search.radius)}
                isSearchable={false}
                className="basic-single"
                onChange={handleRadiusChange}

                // onChange={this.handleServiceChange}
                // components={{
                //   DropdownIndicator: () => null,
                // }}

              />
            </div>
          )}
        </div>
        { (!searchOptions.filter || (searchOptions.filter && searchOptions.filter !== 'hidden')) && (
          <FacilitySuggested
            search={search}
            selectedServices={services}
            selectedAccreditations={accreditations}
            handleSearchButtonChange={handleSearchButtonChange}
          />
        )}

        <div className={[styles.row, styles.claimed, 'gh-claimed-section'].join(' ')}>
          <div className={[styles.claimedContainer, 'gh-claim-container'].join(' ')}>
            <b>
              <FormattedMessage id="facility.claim.upsell" />
            </b>
            <a
              href={`${process.env.REACT_APP_PROVIDER_PATH}/facilityClaim/createFull`}
              target="_blank"
              className={[styles.claimButton, 'gh-claim-button'].join(' ')}
            >
              <FormattedMessage id="facility.claim.upsell.button" />
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

FacilitySearch.defaultProps = {
  locationName: '',
};

FacilitySearch.propTypes = {
  searchServices: PropTypes.array.isRequired,
  handleSearchBoxChange: PropTypes.func.isRequired,
  handleSearchButtonChange: PropTypes.func.isRequired,
  handleRadiusChange: PropTypes.func.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  googleMap: PropTypes.object.isRequired,
  locationName: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facilities: getFacilities(state),
  searchServices: getServices(state),
  googleMap: getGoogleMap(state),
});

export default connect(mapStateToProps)(injectIntl(FacilitySearch));
