import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Menu, { MenuItem, SubMenu } from 'rc-menu';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { ReactComponent as Arrow } from '../../../../images/icons/v2/down-arrow.svg';

import '../../../../styles/general.css';
import styles from './facilitySearchBar.module.css';

const Range = createSliderWithTooltip(Slider.Range);

class BarRent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      minRentValue: '',
      maxRentValue: '',
    };
    this.handleExpanded = this.handleExpanded.bind(this);
    this.handleMinRent = this.handleMinRent.bind(this);
    this.handleMaxRent = this.handleMaxRent.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        minRentValue: search.avgCostMin ? search.avgCostMin : '',
        maxRentValue: search.avgCostMax ? search.avgCostMax : '',
      });
    }
  }

  handleExpanded() {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  handleMinRent(e) {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;
    let value = e.target.value;
    handleFiltersOptionsChange('avgCostMin', { value: value });
    this.setState({
      minRentValue: Number(value),
    });
    if (value === '') {
      handleFiltersOptionsChange('avgCostMin', { value: null });
      this.setState({
        minRentValue: '',
      });
    }
    fetchFilteredSearch();
  }

  handleMaxRent(e) {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;

    let value = e.target.value;
    handleFiltersOptionsChange('avgCostMax', { value: value });
    this.setState({
      maxRentValue: Number(value),
    });
    if (value === '') {
      handleFiltersOptionsChange('avgCostMax', { value: null });
      this.setState({
        maxRentValue: '',
      });
    }
    fetchFilteredSearch();
  }

  render() {
    const { expanded, maxRentValue, minRentValue } = this.state;

    return (
      <>
        <Menu
          mode="horizontal"
          overflowedIndicator=""
          triggerSubMenuAction="click"
          multiple={true}
          onOpenChange={this.handleExpanded}
        >
          <SubMenu
            title={
              <>
                <span className="submenu-title-wrapper">
                  <FormattedMessage id="search.bar.avg.rent" />
                </span>
                <Arrow className={expanded ? styles.upArrow : styles.downArrow} />
              </>
            }
            key="avgCost"
          >
            <MenuItem key="avgCostMin">
              <div className={styles.barRentItem}>
                <span className={styles.barRentItemTitle}>
                  <FormattedMessage id="search.modal.avgCostMin" />
                </span>
                <div className={styles.inputGroup}>
                  <span>$</span>
                  <input
                    className={styles.priceInput}
                    type="number"
                    step={0.01}
                    min={1}
                    onChange={this.handleMinRent}
                    value={minRentValue}
                  />
                </div>
              </div>
            </MenuItem>
            <MenuItem key="avgCostMax">
              <div className={styles.barRentItem}>
                <span className={styles.barRentItemTitle}>
                  <FormattedMessage id="search.modal.avgCostMax" />
                </span>
                <div className={styles.inputGroup}>
                  <span>$</span>
                  <input
                    className={styles.priceInput}
                    type="number"
                    min={1}
                    step={0.01}
                    onChange={this.handleMaxRent}
                    value={maxRentValue}
                  />
                </div>
              </div>
            </MenuItem>
          </SubMenu>
        </Menu>
      </>
    );
  }
}

BarRent.propTypes = {
  handleFiltersOptionsChange: PropTypes.func.isRequired,
  fetchFilteredSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default injectIntl(BarRent);
