import { combineReducers } from 'redux';

import {
  FACILITY_FETCH_REQUESTED,
  FACILITY_FETCH_SUCCEEDED,
  FACILITY_FETCH_FAILED,

  INSTITUTION_FETCH_FAILED,
  INSTITUTION_FETCH_REQUESTED,
  INSTITUTION_FETCH_SUCCEEDED,
} from '../actions/facility';

const facility = (state = {}, { data, type }) => {
  switch (type) {
    case FACILITY_FETCH_SUCCEEDED:
      return data;
    case FACILITY_FETCH_REQUESTED:
    case FACILITY_FETCH_FAILED:
    default:
      return state;
  }
};

const institution = (state = {}, { data, type }) => {
  switch (type) {
    case INSTITUTION_FETCH_SUCCEEDED:
      return data;
    case INSTITUTION_FETCH_REQUESTED:
    case INSTITUTION_FETCH_FAILED:
    default:
      return state;
  }
};
const isFetching = (state = false, {type}) => {
  switch (type) {
    case FACILITY_FETCH_SUCCEEDED:
    case FACILITY_FETCH_FAILED:
      return false;
    case FACILITY_FETCH_REQUESTED:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  facility,
  institution,
  isFetching,
});
