import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import uuidv4 from 'uuid/v4';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { getFacilitySelector } from '../../../selectors/facility';
import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import '../../../styles/slick-carousel-1.6.css';
import { USE_BADGE_SYSTEM } from '../../../constants/badges';
import { getFormattedDate } from '../../../utils/utils';
import { FT_PERSONAL_PROFILE } from '../../../constants/Other';
import { FormattedMessage } from 'react-intl';

const ASSET_URL = `${process.env.REACT_APP_STATIC}/accreditation-logos`;
const BADGE_PATH = process.env.REACT_APP_BADGES_PATH;

const NavigationPrev = ({ innerProps, isModal }) =>
  isModal && (
    <div {...innerProps}>
      <button className={[styles.modalArrow, styles.modalPrevArrow].join(' ')}>{'<'}</button>
    </div>
  );

const NavigationNext = ({ innerProps, isModal }) =>
  isModal && (
    <div {...innerProps}>
      <button className={[styles.modalArrow, styles.modalNextArrow].join(' ')}>{'>'}</button>
    </div>
  );

const isAccreditation = item => {
  return item.status !== 'NOT_VERIFIED' || item.status !== 'ARCHIVED';
};

class FacilityMedia extends PureComponent {
  state = { modalIsOpen: false, currentIndex: 0 };
  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  };

  // HACK
  mouseDownCoords = e => {
    window.checkForDrag = e.clientX;
  };

  //HACK
  handleShowModal = (e, index) => {
    const mouseUp = e.clientX;
    if (mouseUp < window.checkForDrag + 6 && mouseUp > window.checkForDrag - 6) {
      this.setState(state => ({ modalIsOpen: !state.modalIsOpen, currentIndex: index }));
    }
  };

  setNumberImages(images, widgetType) {
    let number = images.length;
    const DEFAULT_IMAGE_COUNT = 4;
    const PROFILE_IMAGE_COUNT = 2;

    if (images.length > 4) {
      number = widgetType === FT_PERSONAL_PROFILE ? PROFILE_IMAGE_COUNT : DEFAULT_IMAGE_COUNT;
    }

    return number;
  }

  render() {
    const { modalIsOpen, currentIndex } = this.state;
    const {
      facility,
      facility: { images = [], accreditations = [] },
      widgetType,
    } = this.props;

    const imagesPath = images.map(image => {
      return { src: image };
    });

    const settings = {
      dots: true,
      lazyLoad: true,
      slidesToShow: this.setNumberImages(images, widgetType),
      // slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      // infinite: images.length > 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: true,
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            dots: true,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className={widgetType === FT_PERSONAL_PROFILE ? styles.imagesContainer : ''}>
        {images.length > 0 && (
          <div className={[styles.box1, widgetType === FT_PERSONAL_PROFILE && styles.splitImages].join(' ')}>
            <div className={grid.row}>
              <div className={grid.col}>
                <Slider {...settings}>
                  {facility.images.map((url, index) => (
                    <div key={uuidv4()}>
                      <div className={styles.sliderImg}>
                        <img
                          src={url}
                          onMouseUp={e => this.handleShowModal(e, index)}
                          onMouseDown={e => this.mouseDownCoords(e)}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <ModalGateway>
              {modalIsOpen && (
                <Modal onClose={this.toggleModal}>
                  <Carousel
                    views={imagesPath}
                    currentIndex={currentIndex}
                    components={{ NavigationPrev, NavigationNext }}
                  />
                </Modal>
              )}
            </ModalGateway>
          </div>
        )}

        {widgetType === FT_PERSONAL_PROFILE && accreditations.length > 0 && (
          <div className={styles.accreditationsContainer}>
            {accreditations.length > 0 &&
              accreditations.map((item, index) => (
                <div key={index}>
                  {isAccreditation(item) && USE_BADGE_SYSTEM.includes(item.id) && (
                    <div className={styles.accreditation} key={index}>
                      <a href={`${BADGE_PATH}/badge/verify/${item.facilityAccreditationId}`} target="_blank">
                        <img src={`${ASSET_URL}/${item.id}.png`} className={styles.acreditationImage} />
                      </a>
                      <div>
                        <div className={styles.acreditationItem}>
                          <div>
                            <b>{item.name}</b>
                          </div>

                          {item.status && item.status !== 'UNDEFINED' && (
                            <div>
                              <b>
                                <FormattedMessage id="common.status" />{' '}
                              </b>
                              <span>
                                <FormattedMessage id={`accreditation.status.${item.status}`} />
                              </span>
                            </div>
                          )}
                          {item.issueDate && (
                            <div>
                              <b>
                                <FormattedMessage id="common.issueDate" />{' '}
                              </b>
                              <span>{getFormattedDate(item.issueDate)}</span>
                            </div>
                          )}
                          {item.expiryDate && (
                            <div>
                              <b>
                                <FormattedMessage id="common.expiryDate" />{' '}
                              </b>
                              <span>{getFormattedDate(item.expiryDate)}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

FacilityMedia.defaultProps = {
  widgetType: '',
};

FacilityMedia.propTypes = {
  facility: PropTypes.object.isRequired,
  widgetType: PropTypes.string,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityMedia);
