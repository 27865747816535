import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  SERVICES_FETCH_REQUESTED,
  servicesFetchFailed,
  servicesFetchSucceeded,
  SUGGESTED_AND_SERVICE_FETCH_REQUESTED,
  suggestedFetchFailed,
  suggestedFetchSucceeded,
} from '../actions/services';

import { fetchServices, fetchSuggestServices } from '../services/ServicesService';
import { getSystemAuth, getSystemConfig } from '../selectors/system';

export function* loadSuggestedAndServices() {
  try {
    yield all([call(loadSuggested), call(loadServices)]);
  } catch (e) {
    //no op because it would be causght in the other
  }
}

export function* loadSuggested() {
  try {
    const systemConfig = yield select(getSystemConfig);
    const { properties: { facilityId = 0 } = {} } = yield select(getSystemAuth);
    const services = yield call(fetchSuggestServices, { facilityId, apiKey: systemConfig.apiKey });

    const successAction = yield call(suggestedFetchSucceeded, services);

    yield put(successAction);
  } catch (e) {
    const failAction = yield call(suggestedFetchFailed, e.message);
    yield put(failAction);
  }
}

export function* loadServices() {
  try {
    const systemConfig = yield select(getSystemConfig);
    let { options: { searchOptions: { widgetType } = ' ' } = {} } = systemConfig;

    widgetType = widgetType || 'RECOVERY_RESIDENCE';
    // let widgetType = 'RECOVERY_RESIDENCE';

    const services = yield call(fetchServices, { widgetType });

    const successAction = yield call(servicesFetchSucceeded, services);

    yield put(successAction);
  } catch (e) {
    const failAction = yield call(servicesFetchFailed, e.message);
    yield put(failAction);
  }
}

const servicesSaga = [
  takeLatest(SERVICES_FETCH_REQUESTED, loadServices),
  takeLatest(SUGGESTED_AND_SERVICE_FETCH_REQUESTED, loadSuggestedAndServices),
];

export default servicesSaga;
