import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  FACILITIES_FETCH_REQUESTED,
  facilitiesFetchFailed,
  facilitiesFetchSucceeded,
  FACILITIES_INIT_REQUESTED,
} from '../actions/facilities';

import { fetchFacilities } from '../services/FacilitiesService';
import { getSystemConfig } from '../selectors/system';
import { loadInstitution } from './facilitySaga';
import { loadServices, loadSuggestedAndServices } from './servicesSaga';
import { loadAuth } from './authSaga';
import { FT_PERSONAL_PROFILE } from '../constants/Other';

const removeCords = searchParams => {
  delete searchParams['latitude'];
  delete searchParams['longitude'];

  return searchParams;
};

const removeBounds = searchParams => {
  delete searchParams['southEastLatitude'];
  delete searchParams['southEastLongitude'];
  delete searchParams['northWestLatitude'];
  delete searchParams['northWestLongitude'];

  return searchParams;
};

const facilitiesParams = (args, widgetType) => {
  const ONLINE_SERVICES = 687;
  let queryArgs = {};
  if (args.province && args.province !== '') {
    args = removeCords(args);
    args = removeBounds(args);
  }

  if (args.bounds) {
    args = removeCords(args);
  } else {
    args = removeBounds(args);
  }

  //Options from filters
  if (args.filteredSearch) {
    Object.entries(args).forEach(([key, value]) => {
      if (value !== '' || Number.isFinite(value)) {
        queryArgs[key] = value;
      }
    });
    delete queryArgs['filteredSearch'];

    if (args.hasOwnProperty('bounds')) {
      delete queryArgs['bounds'];
    }
  } else {
    let clonedService = args.services instanceof Set ? new Set(args.services) : args.services;

    queryArgs = {
      services: clonedService,
      page: parseInt(args.page, 10),
      pageSize: parseInt(args.pageSize, 10),
    };
    if (args.facilityAvailable) {
      queryArgs.facilityAvailable = args.facilityAvailable;
    }
    if (args.name) {
      queryArgs.name = args.name;
    }
  }
  //add required services passed in
  if (args.requiredServiceId && queryArgs.services) {
    queryArgs.services.add(args.requiredServiceId);
  }

  queryArgs.accreditations = args.accreditations instanceof Set ? new Set(args.accreditations) : args.accreditations;
  if ((!args.accreditations || args.accreditations.size === 0) && args.requiredAccreditationId) {
    queryArgs.accreditations.add(args.requiredAccreditationId);
  }
  //we need to ignore location and province if online service
  if (!args.services.has(ONLINE_SERVICES) && widgetType !== FT_PERSONAL_PROFILE) {
    queryArgs.radius = args.radius;
    queryArgs.latitude = args.latitude;
    queryArgs.longitude = args.longitude;
    if (args.province) {
      queryArgs.province = args.province;
    }
  } else {
    delete queryArgs.radius;
    delete queryArgs.latitude;
    delete queryArgs.longitude;
    delete queryArgs.province;
  }

  if (args.sort && args.sort !== 'recommended') {
    queryArgs.sort = args.sort;
  }
  if (args.facilityType) {
    queryArgs.facilityType = args.facilityType;
  }
  return queryArgs;
};

export function* loadFacilities({ args = { page: 1, pageSize: 20, filteredSearch: false } }) {
  try {
    const systemConfig = yield select(getSystemConfig);

    let { options: { searchOptions: { widgetType } = '' } = {} } = systemConfig;
    const queryArgs = facilitiesParams(args, widgetType);

    let facilities = yield call(fetchFacilities, {
      args: queryArgs,
      apiKey: systemConfig.apiKey,
      version: systemConfig.version,
      widgetType: widgetType,
    });

    const successAction = yield call(facilitiesFetchSucceeded, facilities);
    yield put(successAction);
  } catch (e) {
    const failAction = yield call(facilitiesFetchFailed, e.message);
    yield put(failAction);
  }
}

export function* loadInitFacilities({ args }) {
  try {
    const systemConfig = yield select(getSystemConfig);
    const { options: { searchOptions = {} } = {} } = systemConfig;

    // Need to call loadAuth first.  Once this is loaded, we can then use the auth data for the other
    // services
    yield call(loadAuth);

    if (systemConfig.version === 2 || systemConfig.version === 2.1) {
      yield all([call(loadFacilities, { args }), call(loadServices), call(loadInstitution)]);
    } else {
      const services = [];
      services.push(call(loadFacilities, { args }));
      services.push(call(loadInstitution));
      if (!(searchOptions.noService === true)) {
        services.push(call(loadSuggestedAndServices));
      }
      yield all(services);
    }
  } catch (e) {
    const failAction = yield call(facilitiesFetchFailed, e.message);
    yield put(failAction);
  }
}

const facilitiesSaga = [
  takeLatest(FACILITIES_FETCH_REQUESTED, loadFacilities),
  takeLatest(FACILITIES_INIT_REQUESTED, loadInitFacilities),
];

export default facilitiesSaga;
