import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


import styles from './facilityStatusBar.module.css';

const STATUS_MAPPING= {
  1: 'Available',
  2: 'Unavailable',
  3: 'Call',
};

const FacilityStatusBar = ({status}) => (
  <>
    {status > 0 && (
      <div className={[styles[`status${STATUS_MAPPING[status]}`],
        `gh-facility-status-${STATUS_MAPPING[status].toLowerCase()}`].join(' ')}
      >
        <FormattedMessage id={`facility.status.${STATUS_MAPPING[status]}`} />
      </div>
    )}
  </>
);

FacilityStatusBar.propTypes = {
  status: PropTypes.number.isRequired
};

export default FacilityStatusBar;
