import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

import selectStyles from '../FacilitySearch/SelectStyles';
import ModalSection from '../../../common/v2/ModalSection/ModalSection';

import styles from '../FilterModal//filterModal.module.css';
import grid from '../../../../styles/bootstrap-grid.module.css';
import { PAYMENT_TYPES_LIST } from '../../../../constants/filteredSearchOptions';

class FinancesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      minRentValue: '',
      maxRentValue: '',
      financialAssistanceSelected: false,
    };

    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.handleMinRent = this.handleMinRent.bind(this);
    this.handleMaxRent = this.handleMaxRent.bind(this);
    this.handleFinancialAssistance = this.handleFinancialAssistance.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;
    this.setState({
      minRentValue: search.avgCostMin,
      maxRentValue: search.avgCostMax,
      financialAssistanceSelected: search.hasOwnProperty('financialAssistance')
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        minRentValue: search.avgCostMin ? search.avgCostMin : '',
        maxRentValue: search.avgCostMax ? search.avgCostMax : '',
        financialAssistanceSelected: search.hasOwnProperty('financialAssistance')
      });
    }
  }

  handleShowFilter() {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter,
    }));
  }

  handleMinRent(e) {
    const { handleFiltersOptionsChange } = this.props;
    let value = e.target.value;
    handleFiltersOptionsChange('avgCostMin', { value: value });
    this.setState({
      minRentValue: Number(value),
    });
    if (value === '') {
      handleFiltersOptionsChange('avgCostMin', { value: null });
      this.setState({ minRentValue: '' });
    }
  }

  handleMaxRent(e) {
    const { handleFiltersOptionsChange } = this.props;
    let value = e.target.value;
    handleFiltersOptionsChange('avgCostMax', { value: value });
    this.setState({
      maxRentValue: Number(value),
    });
    if (value === '') {
      handleFiltersOptionsChange('avgCostMax', { value: null });
      this.setState({ maxRentValue: '' });
    }
  }

  handleFinancialAssistance(e) {
    const { handleFiltersOptionsChange } = this.props;
    let isFinancialAssistanceChecked = e.target.checked;
    if (isFinancialAssistanceChecked) {
      handleFiltersOptionsChange('financialAssistance', { value: isFinancialAssistanceChecked });
    } else {
      handleFiltersOptionsChange('financialAssistance', { value: null });
    }

    this.setState({
      financialAssistanceSelected: isFinancialAssistanceChecked,
    });
  }

  render() {
    const { showFilter, maxRentValue, minRentValue, financialAssistanceSelected } = this.state;
    const { handleFiltersOptionsChange, filterOptions, search } = this.props;

    return (
      <div className={styles.filterContainer}>
        <ModalSection headingLabel="Fees & Payment" handleClick={this.handleShowFilter} expanded={showFilter}>
          <div className={grid.container}>
            <div className={styles.filterItemContainer}>
              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <div className={styles.filterItemLabel}>
                    <span className="gh-filter-label">
                      <FormattedMessage id="search.modal.avgCostMin" />
                    </span>
                  </div>
                  <div className={styles.filterItemInput}>
                    <input
                      className={styles.priceInput}
                      type="number"
                      placeholder="$"
                      min={1}
                      onChange={this.handleMinRent}
                      value={minRentValue}
                    />
                  </div>
                </div>
              </div>
              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <div className={styles.filterItemLabel}>
                    <span className="gh-filter-label">
                      <FormattedMessage id="search.modal.payment.type" />
                    </span>
                  </div>
                  <div className={styles.filterItemInput}>
                    <PaymentTypes
                      handleFiltersOptionsChange={handleFiltersOptionsChange}
                      filterOptions={filterOptions}
                      search={search}
                    />
                  </div>
                </div>
              </div>
              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <div className={styles.filterItemLabel}>
                    <span className="gh-filter-label">
                      <FormattedMessage id="search.modal.avgCostMax" />
                    </span>
                  </div>
                  <div className={styles.filterItemInput}>
                    <div>
                      <input
                        className={styles.priceInput}
                        type="number"
                        placeholder="$"
                        min={1}
                        onChange={this.handleMaxRent}
                        value={maxRentValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="financialAssistance"
                      onChange={this.handleFinancialAssistance}
                      checked={financialAssistanceSelected}
                    />
                    <span className={[styles.checkboxLabel, 'gh-filter-label'].join(' ')}>
                      <FormattedMessage id="search.modal.financialAssistance"/>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ModalSection>
      </div>
    );
  }
}

FinancesFilter.propTypes = {
  handleFiltersOptionsChange: PropTypes.func.isRequired,
};

export default FinancesFilter;

class PaymentTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;
    this.setState({
      values: PAYMENT_TYPES_LIST.find(item => item.value === search.paymentType),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        values: search.paymentType ? PAYMENT_TYPES_LIST.find(item => item.value === search.paymentType) : [],
      });
    }
  }

  handleChange(value) {
    const { handleFiltersOptionsChange } = this.props;
    handleFiltersOptionsChange('paymentType', value);

    if (value === null) {
      handleFiltersOptionsChange('paymentType', { value: null });
    }
  }

  render() {
    const { values } = this.state;

    return (
      <Select
        ref={ref => {
          this.select = ref;
        }}
        options={PAYMENT_TYPES_LIST}
        name="paymentType"
        styles={selectStyles()}
        isSearchable={false}
        className="basic-single"
        onChange={this.handleChange}
        value={values}
        isClearable={true}
      />
    );
  }
}
