import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getFacilitySelector } from '../../../selectors/facility';
import { getFacilityTypeMessage } from '../../../utils/utils';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';


class FacilityLanguage extends PureComponent {
  render() {
    const { facility } = this.props;
    const services = facility.serviceCategoryMap.get('CAT_LANG');
    return (
      <>
        {services && services.length && (
          <div className={grid.row}>
            <div className={[grid.col].join(' ')}>
              <div className={styles.container}>
                <div className={grid.row}>
                  <div className={grid.col}>
                    <h2 className={styles.header}>
                      {getFacilityTypeMessage('facility.header.languages',facility.facilityType)}
                    </h2>
                  </div>
                </div>
                <div className={[grid.row, styles.box1].join(' ')}>
                  <div className={grid.col}>
                    <ol className={styles.simpleList}>
                      {services.map(service => (
                        <li className={styles.simpleListItem} key={service.id}>
                          {service.name}
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className={[grid.colMd4].join(' ')}></div>
          </div>
        )}
      </>
    );
  }
}


FacilityLanguage.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityLanguage);
