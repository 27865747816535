import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';

import { getSnippetAddressSingleLine, getFullAddressSingleLine } from '../../common/LocationFormat';

import ToolTip from '../../common/ToolTip/ToolTip';
import FacilityStatus from '../../common/v2/FacilityStatus/FacilityStatus';

import { ReactComponent as QuestionMark } from '../../../images/icons/question-mark.svg';
import { ReactComponent as Claimed } from '../../../images/icons/claimed.svg';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import { getSystemOptions } from '../../../selectors/system';
import { FT_SCHOOL } from '../../../constants/Other';

const setURL = (appUrl, facilityID, facilityType) => {
  return `${appUrl}/facilityClaim/createFull?facilityId=${facilityID}&facilityType=${facilityType}`;
};

class FacilityHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      facilityType: props.widgetType || 'RECOVERY_RESIDENCE',
    };
  }

  componentDidMount() {
    const { widgetType } = this.props;
    if (widgetType.startsWith(FT_SCHOOL)) {
      this.setState({
        facilityType: FT_SCHOOL,
      });
    }
  }

  static renderClaimedToolTip(isClaimed, id, appUrl, facilityType) {
    return (
      <Fragment>
        {isClaimed ? (
          <FormattedMessage id="facility.claimed.desc" />
        ) : (
          <>
            <FormattedMessage id="facility.unclaimed.desc" />
            <a href={setURL(appUrl, id, facilityType)} target="_blank">
              <strong>
                <FormattedMessage id="facility.unclaimed.desc.click" />
              </strong>
            </a>
          </>
        )}
      </Fragment>
    );
  }

  renderAddress() {
    const { facility } = this.props;
    if (facility.privateAddress) {
      return getSnippetAddressSingleLine(facility.city, facility.state, facility.zipCode, facility.county);
    }
    return getFullAddressSingleLine(
      facility.address1,
      facility.address2,
      facility.city,
      facility.state,
      facility.zipCode,
      facility.county,
    );
  }

  render() {
    const {
      facility,
      systemOptions,
      facility: { published = true },
      widgetType,
    } = this.props;
    const { facilityType } = this.state;

    const appUrl = systemOptions.appUrl ? systemOptions.appUrl : process.env.REACT_APP_PROVIDER_PATH;
    return (
      <div className={styles.box1}>
        {!published && (
          <div className={[grid.row, grid.rowBottom].join(' ')}>
            <div className={[grid.col, styles.infoBox].join(' ')}>
              <FormattedMessage id="facility.unpublished" />
            </div>
          </div>
        )}
        <div className={[grid.row].join(' ')}>
          <div className={[grid.col12, grid.colSm8, 'gh-facility-header'].join(' ')}>
            <div>
              <h1 className={styles.facilityHeader}>{facility.name}</h1>
              <ToolTip
                placement="bottom"
                trigger="hover"
                tooltip={this.constructor.renderClaimedToolTip(facility.claimed, facility.id, appUrl, facilityType)}
                delayHide={200}
              >
                <span className={facility.claimed ? styles.claimed : styles.unclaimed}>
                  {facility.claimed ? (
                    <Fragment>
                      <Claimed />
                      <FormattedMessage id="facility.claimed" />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <a target="_blank" href={setURL(appUrl, facility.id, facilityType)}>
                        <QuestionMark />
                        <FormattedMessage id="facility.unclaimed" />
                      </a>
                    </Fragment>
                  )}
                </span>
              </ToolTip>
            </div>
          </div>
          <div className={[grid.col12, grid.colSm4, styles.status].join(' ')}>
            {typeof facility.status !== 'undefined' && facility.premium && (
              <FacilityStatus status={facility.status} bedUpdatedAt={facility.bedUpdatedAt} widgetType={widgetType} />
            )}
          </div>
        </div>
        <div className={[grid.row].join(' ')}>
          <div className={[grid.col12, grid.colSm8, 'gh-facility-header'].join(' ')}>
            <div className={[styles.address].join(' ')}>{this.renderAddress()}</div>
          </div>
          <div className={[grid.col12, grid.colSm4, styles.status].join(' ')}></div>
        </div>
      </div>
    );
  }
}

FacilityHeader.defaultProps = {};

FacilityHeader.propTypes = {
  facility: PropTypes.object.isRequired,
  systemOptions: PropTypes.object,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
  systemOptions: getSystemOptions(state),
});

export default connect(mapStateToProps)(FacilityHeader);
