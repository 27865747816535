import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';

import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';
import { ReactComponent as X } from '../../../images/icons/v2/x.svg';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import { AMEN_ADA_COMMON, AMEN_ADA_ROOM } from '../../../constants/Accessibility';

class FacilityAccessibility extends PureComponent {
  hasAccessibility(option) {
    const {
      facility: { accessibility = '' },
    } = this.props;
    return accessibility.includes(option);
  }

  renderAccessibilityCol(value) {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <div className={grid.row}>
        <div className={[grid.col, styles.check].join(' ')}>
          <Check /> <FormattedMessage id={`facility.accessibility.${value}`} />
        </div>
      </div>
    );
  }

  render() {
    const {
      facility: { accessibility = '' },
    } = this.props;

    return (
      accessibility &&
      accessibility !== '' && (
        <div className={[styles.container].join(' ')}>
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}>
                <FormattedMessage id="facility.header.accessibility" />
              </h2>
            </div>
          </div>
          <div className={[styles.box1].join(' ')}>
            {this.hasAccessibility(AMEN_ADA_COMMON) && this.renderAccessibilityCol(AMEN_ADA_COMMON)}
            {this.hasAccessibility(AMEN_ADA_ROOM) && this.renderAccessibilityCol(AMEN_ADA_ROOM)}

            {!this.hasAccessibility(AMEN_ADA_COMMON) && !this.hasAccessibility(AMEN_ADA_ROOM) &&
              <div className={styles.nonSpecifiedText}>
                <FormattedMessage id="facility.data.none.specified" />
              </div>
            }
          </div>
        </div>
      )
    );
  }
}

FacilityAccessibility.defaultProps = {};

FacilityAccessibility.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(injectIntl(FacilityAccessibility));
