import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { FormattedMessage} from 'react-intl';

import {getFacilities, getFacilitiesPageCount, isFetchingFacilities} from '../../selectors/facilities';
import {getSystemOptions, getSystemStyleOverrides} from '../../selectors/system';
import FacilityItem from './FacilityItem';
import FacilitySearchNoResults from './FacilitySearchNoResults';

import paginationStyles from '../../styles/pagination.module.css';

class FacilityList extends PureComponent {

  renderItem(item) {
    const { selectedFacility,
      clickedFacility,
      handleListItemMouseEnter,
      handleListItemMouseLeave,
      styleOverrides : {facilityItem},
      systemOptions } = this.props;

    return (
      <FacilityItem
        facility={item}
        selectedFacility={selectedFacility}
        clickedFacility={clickedFacility}
        handleListItemMouseEnter={handleListItemMouseEnter}
        handleListItemMouseLeave={handleListItemMouseLeave}
        key={item.id}
        styleOverrides={facilityItem}
        systemOptions={systemOptions}
      />
    );
  }

  render() {
    const { facilities, currentPage, handlePagination, isFetching, facilitiesPageCount,
      systemOptions : {searchOptions = {}} } = this.props;

    return (
      <Fragment>
        {facilities.length > 0 && facilities.map(facility => this.renderItem(facility))}
        {facilitiesPageCount > 0 && (
          <div className={[paginationStyles.container, 'gh-pagination'].join(' ')}>
            <ReactPaginate
              forcePage={parseInt(currentPage, 10)}
              onPageChange={handlePagination}
              pageCount={facilitiesPageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              previousLabel="previous"
              nextLabel="next"
              containerClassName={paginationStyles.pagination}
              activeClassName={paginationStyles.active}
              disabledClassName={paginationStyles.disabled}
            />
          </div>
        )}
        {!isFetching && facilities.length === 0 && (<FacilitySearchNoResults />)}
        {!isFetching && searchOptions.showLoginLink && (
          <div>
            <FormattedMessage id="search.login.1" />
            <a href={`${process.env.REACT_APP_PROVIDER_PATH}/login/auth`} target="_blank">
              <b><FormattedMessage id="search.login.2" /></b>
            </a>
          </div>
        )}
      </Fragment>
    );
  }
}

FacilityList.defaultProps = {
  facilities: [],
  selectedFacility: null,
  clickedFacility: null,
  currentPage: 0,
  facilitiesPageCount: 0,
  systemOptions: {},
  styleOverrides: {},
};

FacilityList.propTypes = {
  facilities: PropTypes.array,
  facilitiesPageCount: PropTypes.number,
  selectedFacility: PropTypes.object,
  clickedFacility: PropTypes.object,
  currentPage: PropTypes.any,
  handleListItemMouseEnter: PropTypes.func.isRequired,
  handleListItemMouseLeave: PropTypes.func.isRequired,
  handlePagination: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  systemOptions:  PropTypes.object,
  styleOverrides: PropTypes.object,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facilities: getFacilities(state),
  facilitiesPageCount: getFacilitiesPageCount(state),
  isFetching: isFetchingFacilities(state),
  systemOptions: getSystemOptions(state),
  styleOverrides: getSystemStyleOverrides(state),


});

export default connect(mapStateToProps)(FacilityList);
