import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import Sticky from 'react-stickynode';

import { animateScroll } from 'react-scroll';

import { setToString, getCurrentPosition } from '../../utils/utils';

import { loadFacilities, loadInitFacilities } from '../../actions/facilities';
import { loadInstitution } from '../../actions/facility';
import {  loadSuggestedAndServices } from '../../actions/services';
import { isFetchingFacilities } from '../../selectors/facilities';

import { loadGoogleMap } from '../../actions/map';

import FacilityList from '../../components/facilities/FacilityList';
import FacilitySearch from '../../components/facilities/FacilitySearch';
import FacilityMap from '../../components/facilities/FacilityMap';
import Loader from '../../components/common/Loader/Loader';

import styles from './facilities.module.css';
import grid from '../../styles/bootstrap-grid.module.css';
import { getSystemOptions } from '../../selectors/system';
import { getFeatures } from '../../selectors/facility';
import { featureSet } from '../../featureSet';

// Center point of Los Angeles
const DEFAULT_LAT = 34.05271;
const DEFAULT_LNG = -118.24516;
const ONLINE_SERVICES = 687;

const BUTTON_TYPE = {
  service: 'SERVICE',
  accreditation: 'ACCREDITATION',
};

class Facilities extends Component {
  constructor(props) {
    super(props);

    const { searchOptions } = props;
    const {
      defaultCenterPoint = {},
      defaultRadius,
      defaultState,
      requiredServiceId,
      requiredAccreditationId,
      accreditation,
      defaultServices,
    } = searchOptions;
    const { lat, lng } = defaultCenterPoint;

    const search = {
      services: new Set(),
      accreditations: new Set(),
      name: '',
      // sort: 'distance',
      latitude: lat || DEFAULT_LAT,
      longitude: lng || DEFAULT_LNG,
      locationName: '',
      page: 0,
      pageSize: 20,
      radius: defaultRadius || 15,
      province: null,
      requiredServiceId: requiredServiceId || null,
      requiredAccreditationId: requiredAccreditationId || null,
    };

    if (requiredAccreditationId === 24) {
      search.requiredAccreditationId = [24, 26];
    }
    if (accreditation) {
      search.accreditations.add(Number(accreditation));
    }
    if (defaultServices) {
      defaultServices.forEach(service => search.services.add(Number(service)));
    }
    if (defaultState) {
      search.province = defaultState;
      search.latitude = null;
      search.longitude = null;
    }

    this.state = {
      search,
      selectedFacility: null,
    };
    this.handleSearchBox = this.handleSearchBox.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.handleRadiusChange = this.handleRadiusChange.bind(this);
    this.handleGoogleApiLoaded = this.handleGoogleApiLoaded.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.handleListItemMouseEnter = this.handleListItemMouseEnter.bind(this);
    this.handleListItemMouseLeave = this.handleListItemMouseLeave.bind(this);
    this.handlePagination = this.handlePagination.bind(this);

    this.mapContainer = React.createRef(); // Create a ref object
  }

  async componentDidMount() {
    console.log('componentDidMount');
    const {
      dispatchFacilities,
      dispatchServices,
      dispatchInstitution,
      dispatchInitFacilities,
      location: { search: searchQuery },
      searchOptions: { defaultRadius },
    } = this.props;

    const { search: searchState } = this.state;
    const queryStringMap = queryString.parse(searchQuery);

    // We need to wait for auth so that it will populate auth data.
    // dispatchInstitution();
    // dispatchServices();

    let position = {};
    try {
      position = await getCurrentPosition();
    } catch (e) {
      // User does something so there is no position
    }

    // Get position from browser
    if (position.coords) {
      const { latitude, longitude } = position.coords;
      searchState.longitude = longitude;
      searchState.latitude = latitude;
    }

    if (this.mapContainer.current) {
      this.mapContainer.current.style.height = `${window.innerHeight}px`;
    }

    if (queryStringMap && queryStringMap.services) {
      queryStringMap.services.split(',').forEach(service => {
        searchState.services.add(Number(service));
      });
    }
    delete queryStringMap.services;

    if (queryStringMap && queryStringMap.accreditations) {
      queryStringMap.accreditations.split(',').forEach(accreditation => {
        searchState.accreditations.add(Number(accreditation));
      });
    }
    delete queryStringMap.accreditations;
    this.setState({ search: { ...searchState, ...queryStringMap } }, () => {
      const { search } = this.state;
      dispatchInitFacilities(search);
    });

    this.debounceSearch = debounce(this.getSearchedFacilities, 250);
  }

  getSearchedFacilities() {
    const { dispatchFacilities, history } = this.props;
    const { search } = this.state;
    const searchCopy = Object.assign({}, search);

    searchCopy.services = setToString(searchCopy.services);
    searchCopy.accreditations = setToString(searchCopy.accreditations);

    delete searchCopy['filteredSearch'];

    history.push(`?${queryString.stringify(searchCopy)}`);

    dispatchFacilities(search);
  }

  handleSearchBox(term) {
    const { search, search: { services, name } } = this.state;

    let facilityName = name;

    if (term.value) {
      services.add(Number(term.value));
      facilityName = term.name || '';
    } else if (typeof term.name !== 'undefined') {
      facilityName = term.name;
    }

    this.setState(
      {
        search: { ...search, services, name: facilityName, page: 0 },
        clickedFacility: null,
        selectedFacility: null,
      },
      () => {
        this.debounceSearch();
      },
    );
  }

  handleSearchButton({ service = {}, selected = false }) {
    const { search, search: { services, accreditations } } = this.state;

    if (service.type === BUTTON_TYPE.accreditation) {
      if (selected) {
        accreditations.add(Number(service.id));
      } else {
        accreditations.delete(Number(service.id));
      }
    }
    if (service.type === BUTTON_TYPE.service) {
      if (selected) {
        services.add(Number(service.id));
      } else {
        services.delete(Number(service.id));
      }
    }
    this.setState(
      {
        search: { ...search, services, accreditations, page: 0 },
        clickedFacility: null,
        selectedFacility: null,
      },
      () => {
        this.debounceSearch();
      },
    );
  }

  handleRadiusChange(params) {
    const { search } = this.state;
    const radius = params.value;

    this.setState(
      {
        search: { ...search, radius, page: 0 },
        clickedFacility: null,
        selectedFacility: null,
      },
      () => {
        this.debounceSearch();
      },
    );
  }

  handleLocationChange(place, bounds) {
    const { systemConfig: { version = 1 } } = this.props;

    if (!place && (!place.geometry || !(place.lat() && place.lng()))) {
      return;
    }

    const { search } = this.state;

    let location = {};
    let locationName = '';
    if (place.geometry) {
      location = place.geometry.location;
      locationName = place.formatted_address;
    } else {
      location = place;
    }

    const cords = {
      latitude: location.lat(),
      longitude: location.lng(),
    };
    let searchParams = { ...search, locationName,  province: null, page: 0 };
    delete searchParams['name'];
    if (bounds) {
      searchParams = this.removeCords(searchParams);
      searchParams = {...searchParams,  ...bounds};
    } else {
      searchParams = this.removeBounds(searchParams);
      searchParams = {...searchParams, ...cords};
    }
    this.setState(
      {
        search: searchParams,
        clickedFacility: null,
        selectedFacility: null,
      },
      () => {
        this.getSearchedFacilities();
      },
    );
  }
  removeCords(searchParams) {
    delete searchParams['latitude'];
    delete searchParams['longitude'];

    return searchParams;
  }

  removeBounds(searchParams) {
    delete searchParams['southEastLatitude'];
    delete searchParams['southEastLongitude'];
    delete searchParams['northEastLatitude'];
    delete searchParams['northWestLongitude'];

    return searchParams;
  }
  handlePagination(data) {
    const { search } = this.state;
    const { systemOptions = {} } = this.props;

    this.setState(
      {
        search: { ...search, page: data.selected },
        clickedFacility: null,
        selectedFacility: null,
      },
      () => {
        this.getSearchedFacilities();
        if (systemOptions.scrollTop) {
          document.querySelector(`.${systemOptions.scrollTop}`).scrollIntoView();
        } else {
          animateScroll.scrollToTop({ duration: 250 });
        }
      },
    );
  }

  handleGoogleApiLoaded(map, maps) {
    const { dispatchGoogleMaps } = this.props;
    console.log('google api loaded');
    dispatchGoogleMaps({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
  }

  handleListItemMouseEnter(facility) {
    this.setState({ selectedFacility: facility, clickedFacility: null });
  }

  handleListItemMouseLeave() {
    this.setState({ selectedFacility: null, clickedFacility: null });
  }

  render() {
    const {
      selectedFacility,
      clickedFacility,
      search,
      search: { page },
      search: { locationName },
    } = this.state;

    const { isFetching, features, searchOptions } = this.props;

    return (
      <Fragment>
        <Loader removeLoader={!isFetching} />
        <div className={[grid.containerFluid, styles.container, 'gh-facilities-section'].join(' ')}>
          <FacilitySearch
            search={search}
            locationName={locationName}
            searchOptions={searchOptions}
            handleSearchBoxChange={this.handleSearchBox}
            handleSearchButtonChange={this.handleSearchButton}
            handleRadiusChange={this.handleRadiusChange}
            handleLocationChange={this.handleLocationChange}
          />
          <div className={[grid.row, grid.noGutters].join(' ')}>
            <div className={[grid.col, styles.colRight, 'gh-facilities-list'].join(' ')}>
              <FacilityList
                selectedFacility={selectedFacility}
                clickedFacility={clickedFacility}
                currentPage={page}
                handleListItemMouseEnter={this.handleListItemMouseEnter}
                handleListItemMouseLeave={this.handleListItemMouseLeave}
                handlePagination={this.handlePagination}
              />
            </div>

            <div
              className={[
                'gh-reset',
                grid.col,
                grid.hiddenSmDown,
                typeof features[featureSet.MAP] === 'undefined' ||
                searchOptions.noMap ||
                (search.services && search.services.has(ONLINE_SERVICES))
                  ? styles.mapHidden
                  : '',
                'gh-facilities-map-section',
              ].join(' ')}
            >
              <Sticky bottomBoundary="#bottom-boundary">
                <div id="map-container" ref={this.mapContainer} className={styles.mapInnerContainer}>
                  <FacilityMap
                    handleGoogleApiLoaded={this.handleGoogleApiLoaded}
                    selectedFacility={selectedFacility}
                    handleLocationChange={this.handleLocationChange}
                  />
                </div>
              </Sticky>
            </div>
            <div id="bottom-boundary"></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Facilities.defaultProps = {
  searchOptions: {
    defaultCenterPoint: {
      lat: null,
      lng: null,
    },
    defaultRadius: null,
  },
  systemOptions: {},
};

Facilities.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatchFacilities: PropTypes.func.isRequired,
  dispatchServices: PropTypes.func.isRequired,
  dispatchInstitution: PropTypes.func.isRequired,
  dispatchGoogleMaps: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  features: PropTypes.object.isRequired,
  systemOptions: PropTypes.object,
  searchOptions: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  dispatchFacilities: state => {
    dispatch(loadFacilities(state));
  },
  dispatchServices: () => {
    dispatch(loadServices());
  },
  dispatchInstitution: () => {
    dispatch(loadInstitution());
  },
  dispatchGoogleMaps: state => {
    dispatch(loadGoogleMap(state));
  },
  dispatchInitFacilities: state => {
    dispatch(loadInitFacilities(state));
  },
});

// reducer is called facilities
const mapStateToProps = state => ({
  isFetching: isFetchingFacilities(state),
  systemOptions: getSystemOptions(state),
  features: getFeatures(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Facilities);
