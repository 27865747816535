import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../selectors/facility';

import styles from './facility.module.css';

class FacilityLevelOfCare extends PureComponent {
  render() {
    const {
      facility: { details = {} },
    } = this.props;
    const { levels_of_care: levelsOfCare = [] } = details;
    return (
      <>
        {levelsOfCare.length > 0 && (
        <div className={styles.container}>
          <div className={styles.sectionHeader}>
            <FormattedMessage id="facility.levels_of_care" />
          </div>
          {levelsOfCare.map(levelOfCare => <div key={levelOfCare.id}>{levelOfCare.name}</div>)}
        </div>
        )}
      </>
    );
  }
}
FacilityLevelOfCare.propTypes = {
  facility: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityLevelOfCare);
