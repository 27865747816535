import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';

import WhatsThis from '../../common/Modal/WhatsThis';
import { ReactComponent as Certificate } from '../../../images/icons/v2/certificate.svg';

const ASSET_URL = `${process.env.REACT_APP_STATIC}/accreditation-logos`;
const BADGE_PATH = process.env.REACT_APP_BADGES_PATH;
import { USE_BADGE_SYSTEM } from '../../../constants/badges';

const licenceText = item => {
  return (
    <>
      <b>{item.name}</b>
      {item.status === 'IN_PROCESS' && (
        <small>
          (<FormattedMessage id="facility.accreditation.IN_PROCESS" />)
        </small>
      )}
      {item.status !== 'IN_PROCESS' && item.level && item.level.includes('LEVEL') && (
        <b className={styles.accrLevel}>
          <FormattedMessage id={`facility.license.${item.level}`} />
        </b>
      )}
    </>
  );
};

const isAccreditation = item => {
  return item.status !== 'NOT_VERIFIED' || item.status !== 'ARCHIVED';
};

class FacilityLicense extends PureComponent {
  render() {
    const {
      facility,
      facility: { accreditations = '' },
    } = this.props;
    return (
      <div className={styles.box1}>
        <div className={[grid.row].join(' ')}>
          <div className={[grid.col9].join(' ')}>
            {accreditations.length > 0 &&
              accreditations.map((item, index) => (
                <div className={[grid.row].join(' ')} key={index}>
                  <div className={[grid.col, styles.certificate].join(' ')}>
                    {isAccreditation(item) && USE_BADGE_SYSTEM.includes(item.id) && (
                      <a href={`${BADGE_PATH}/badge/verify/${item.facilityAccreditationId}`} target="_blank">
                        <img src={`${ASSET_URL}/${item.id}.png`} />
                        {licenceText(item)}
                      </a>
                    )}
                    {isAccreditation(item) && !USE_BADGE_SYSTEM.includes(item.id) && (
                      <div>
                        <Certificate />
                        {licenceText(item)}
                      </div>
                    )}
                    {item.associationId === 4 && (
                      <div className={styles.accrAssociation}>
                        <WhatsThis headerMessageId={3} contentMessageId={3} />
                      </div>
                    )}
                  </div>
                </div>
              ))}

            {/*<div className={grid.col4}>*/}
            {/*    <WhatsThis headerMessageId={1} contentMessageId={1}  />*/}
            {/*</div>*/}
            {facility.compliesLicensing && (
              <div className={[grid.row, styles.compliesLicensing].join(' ')}>
                <div className={grid.col}>
                  <FormattedMessage id={`facility.license.disclaimer.${facility.compliesLicensing}`} />
                </div>
              </div>
            )}
          </div>
          {/*<div className={[grid.col3, styles.contactButtonContainer].join(' ')}>*/}
          {/*  <a href="#contactBox" className={styles.contactButton}>*/}
          {/*    <AnglesDown  />*/}
          {/*    <FormattedMessage id="facility.contactinfo.button"/>*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}

FacilityLicense.defaultProps = {};

FacilityLicense.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityLicense);
