import React from 'react';
import PropTypes from 'prop-types';

import styles from './modalSection.module.css';
import { ReactComponent as Arrow } from '../../../../images/icons/v2/down-arrow.svg';

const ModalSection = ({ headingLabel, children, handleClick, expanded }) => (
  <div className={styles.section}>
    <div className={styles.heading} onClick={handleClick}>
      <h3 className="gh-modal-heading-section-label">{headingLabel}</h3>
      <Arrow className={expanded ? styles.upArrow : styles.downArrow} />
    </div>
    {expanded && <div className={styles.sectionContent}>{children}</div>}
  </div>
);

ModalSection.defaultProps = {
  handleClick: () => {},
  expanded: true,
};

ModalSection.propTypes = {
  headingLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func,
  expanded: PropTypes.bool,
};

export default ModalSection;
