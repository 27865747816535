export const FACILITY_FETCH_REQUESTED = 'FACILITY_FETCH_REQUESTED';
export const FACILITY_FETCH_SUCCEEDED = 'FACILITY_FETCH_SUCCEEDED';
export const FACILITY_FETCH_FAILED = 'FACILITY_FETCH_FAILED';


export const INSTITUTION_FETCH_REQUESTED = 'INSTITUTION_FETCH_REQUESTED';
export const INSTITUTION_FETCH_SUCCEEDED = 'INSTITUTION_FETCH_SUCCEEDED';
export const INSTITUTION_FETCH_FAILED = 'INSTITUTION_FETCH_FAILED';


export const loadFacility = id => ({
  type: FACILITY_FETCH_REQUESTED,
  id,
});

export const facilityFetchSucceeded = data => ({
  type: FACILITY_FETCH_SUCCEEDED,
  data,
});

export const facilityFetchFailed = message => ({
  type: FACILITY_FETCH_FAILED,
  message,
});

export const loadInstitution = id => ({
  type: INSTITUTION_FETCH_REQUESTED,
  id,
});

export const institutionFetchSucceeded = data => ({
  type: INSTITUTION_FETCH_SUCCEEDED,
  data,
});

export const institutionFetchFailed = message => ({
  type: INSTITUTION_FETCH_FAILED,
  message,
});
