import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'react-modal';

import { getFacilitySelector } from '../../../selectors/facility';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import modalStyles from '../../common/Modal/Modal.module.css';
import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';
import { ReactComponent as X } from '../../../images/icons/v2/x.svg';

import {
  CAT_12_STEP,
  CAT_PROG,
  CAT_TREATMENT,
  PRGM_CLOTHING,
  PRGM_LIFERING,
  PRGM_MAT,
  PRGM_MODERATION,
  PRGM_RATIONAL,
  PRGM_SEX_OFFENDER,
  PRGM_SMART,
  PRGM_WOMEN_SOBRIETY,
  RES_DETOX,
  SA_CHILDCARE,
  SA_CLERGY,
  SA_EDUCATION_SUPPORT,
  SA_EMPLOYMENT_SUPPORT,
  SA_LEGAL,
  SA_LIFE_SKILLS,
  SA_PEER_SUPPORT,
  TA_CBT,
  TA_CLINICAL_THERAPY,
  TA_COMPLEMENTARY_THERAPIES,
  TA_COUPLES_FAM,
  TA_DBT,
  TA_GRP_THERAPY,
  TA_INTERPERSONAL_THERAPY,
  TA_IPT,
  TA_MEDICAL_SUPERVISION,
  TA_OTHER_MENTAL,
  TA_RECREATIONAL_THERAPIES,
  TA_SUPPORT_GROUPS,
  TA_THERAPEUTIC_COMMUNITY,
  TWELVE_STEP_AA,
  TWELVE_STEP_AL,
  TWELVE_STEP_CA,
  TWELVE_STEP_CELEBRATE,
  TWELVE_STEP_MA,
  TWELVE_STEP_NA,
  CONVENIENCE_STORE,
  DENTAL_SERVICES,
  GROCERY_STORE,
  GAS_STATION,
  HEALTHCARE,
  PHARMACY,
  RESTAURANTS,
  OTHER,
} from '../../../constants/Services';

import {getFacilityTypeMessage} from "../../../utils/utils";

const SERVICES = {
  [CAT_12_STEP]: [
    TWELVE_STEP_AA,
    TWELVE_STEP_NA,
    TWELVE_STEP_MA,
    TWELVE_STEP_CA,
    TWELVE_STEP_AL,
    TWELVE_STEP_CELEBRATE,
  ],
  [SA_CHILDCARE]: [],
  [RES_DETOX]: [],
  [SA_EDUCATION_SUPPORT]: [],
  [SA_EMPLOYMENT_SUPPORT]: [],
  [SA_CLERGY]: [],
  [SA_LEGAL]: [],
  [CAT_PROG]: [
    PRGM_SEX_OFFENDER,
    PRGM_CLOTHING,
    PRGM_SMART,
    PRGM_RATIONAL,
    PRGM_WOMEN_SOBRIETY,
    PRGM_MODERATION,
    PRGM_LIFERING,
    PRGM_MAT,
  ],
  [CAT_TREATMENT]: [
    TA_IPT,
    TA_COUPLES_FAM,
    TA_GRP_THERAPY,
    TA_CBT,
    TA_DBT,
    TA_CLINICAL_THERAPY,
    TA_COMPLEMENTARY_THERAPIES,
    TA_INTERPERSONAL_THERAPY,
    TA_MEDICAL_SUPERVISION,
    TA_RECREATIONAL_THERAPIES,
    TA_SUPPORT_GROUPS,
    TA_THERAPEUTIC_COMMUNITY,
    TA_OTHER_MENTAL,
  ],
  [SA_LIFE_SKILLS]: [],
  [SA_PEER_SUPPORT]: [],
};

const WALKABLEPLACES = {
  CONVENIENCE_STORE,
  DENTAL_SERVICES,
  GROCERY_STORE,
  GAS_STATION,
  HEALTHCARE,
  PHARMACY,
  RESTAURANTS,
  OTHER,
};

const walkableServicesToArray = services => {
  const servicesArray = [];

  Object.entries(WALKABLEPLACES).forEach(([key, value]) => {
    const isSelect = services.includes(key);
    if (isSelect) {
      servicesArray.push({ value: key, isSelect });
    }
  });

  return servicesArray;
};
const servicesToArray = services => {
  const servicesArray = [];
  Object.entries(SERVICES).forEach(([key, value]) => {
    if (value.length > 0) {
      value.forEach(item => {
        const isSelect = (services[key] && services[key].includes(item)) || false;
        if (isSelect) {
          servicesArray.push({ value: item, isSelect });
        }
      });
    } else {
      const isSelect = services.hasOwnProperty(key);
      if (isSelect) {
        servicesArray.push({ value: key, isSelect });
      }
    }
  });
  return servicesArray;
};

class FacilityService extends PureComponent {
  constructor(props) {
    super(props);
    this.renderSiteServices = this.renderSiteServices.bind(this);
    this.handleShowAllServices = this.handleShowAllServices.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderNoneSpecified = this.renderNoneSpecified.bind(this);

    this.state = {
      walkablePlaces: [],
      onsiteServices: [],
      offsiteServices: [],
      showAllServices: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      facility: { offsiteServices = {}, onsiteServices = {}, additionalWalkablePlaces = '' },
    } = this.props;

    let walkablePlacesToArray = additionalWalkablePlaces ? additionalWalkablePlaces.split(',') : [];

    if (this.props.facility !== prevProps.facility) {
      const onsite = servicesToArray(onsiteServices);
      const offsite = servicesToArray(offsiteServices);
      const walkablePlaces = walkableServicesToArray(walkablePlacesToArray);

      this.setState({
        walkablePlaces: walkablePlaces,
        onsiteServices: onsite,
        offsiteServices: offsite,
      });
    }
  }

  handleShowAllServices() {
    const { showAllServices } = this.state;
    this.setState({
      showAllServices: !showAllServices,
    });
  }

  handleClose() {
    this.setState({
      showAllServices: false,
    });
  }

  renderNoneSpecified() {
    return (
      <div className={styles.nonSpecifiedText}>
        <FormattedMessage id="facility.data.none.specified" />
      </div>
    );
  }

  renderSiteServices() {
    const { walkablePlaces, onsiteServices, offsiteServices } = this.state;
    const {facility} = this.props;

    return (
      <div className={styles.box1}>
        <div className={[grid.row, grid.rowBottom].join(' ')}>
          <div className={[grid.colSm6, grid.colMd4, styles.check, styles.box1].join(' ')}>
            <h2 className={[styles.subheader].join(' ')}>
              {getFacilityTypeMessage('facility.services.header.inhouse',facility.facilityType)}
            </h2>
            {onsiteServices.length === 0
              ? this.renderNoneSpecified()
              : onsiteServices.slice(0, 10).map((item, index) => (
                  <div key={index} className={styles.check}>
                    <Check /> <FormattedMessage id={`facility.services.${item.value}`} />
                  </div>
                ))}
          </div>
          <div className={[grid.colSm6, grid.colMd4, styles.check, styles.box1].join(' ')}>
            <h2 className={[styles.subheader].join(' ')}>
              {getFacilityTypeMessage('facility.services.header.offsite',facility.facilityType)}
            </h2>
            {offsiteServices.length == 0
              ? this.renderNoneSpecified()
              : offsiteServices.slice(0, 10).map((item, index) => (
                  <div key={index} className={styles.check}>
                    <Check /> <FormattedMessage id={`facility.services.${item.value}`} />
                  </div>
                ))}
          </div>
          <div className={[grid.colSm6, grid.colMd4, styles.check, styles.box1].join(' ')}>
            <h2 className={[styles.subheader].join(' ')}>
              <FormattedMessage id="facility.services.header.walking" />
            </h2>

            {walkablePlaces.length === 0
              ? this.renderNoneSpecified()
              : walkablePlaces.slice(0, 10).map((place, index) => (
                  <div key={index} className={styles.check}>
                    <Check /> <FormattedMessage id={`facility.services.near.${place.value}`} />
                  </div>
                ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showAllServices, onsiteServices, offsiteServices } = this.state;
    const {facility} = this.props;
    facility.facilityType = 'PERSONAL_PROFILE';
    return (
      <div className={[styles.container].join(' ')}>
        <div className={grid.row}>
          <div className={grid.col}>
            <h2 className={styles.header}>
              {getFacilityTypeMessage('facility.header.services',facility.facilityType)}
            </h2>
          </div>
        </div>
        {this.renderSiteServices()}
        {(onsiteServices.length > 9 || offsiteServices.length > 9) && (
          <>
            <div className={styles.box1}>
              <button className={styles.showMore} onClick={this.handleShowAllServices}>
                <FormattedMessage id="facility.modal.show.all" values={{ value: 'Services & Programs' }} />
              </button>
            </div>
            <Modal
              onAfterOpen={() => {
                document.body.style.overflow = 'hidden';
              }}
              onAfterClose={() => {
                document.body.removeAttribute('style');
              }}
              isOpen={showAllServices}
              className={modalStyles.modal}
              ariaHideApp={false}
              overlayClassName={modalStyles.overlay}
            >
              <div className={modalStyles.modalContent}>
                <div className={modalStyles.modalHeader}>
                  <div className={[modalStyles.modalTitle, grid.noGuttersBoth].join(' ')}>
                    <h4>
                      <FormattedMessage id="facility.header.services" />
                    </h4>
                  </div>
                  <div className={[modalStyles.closeButton, grid.noGuttersBoth].join(' ')}>
                    <X onClick={this.handleClose} />
                  </div>
                </div>

                <div className={modalStyles.detailPageModalContent}>
                  <div className={[grid.colMd6, styles.check, styles.box1, grid.noGuttersBoth].join(' ')}>
                    <h2 className={[styles.subheader].join(' ')}>
                      {getFacilityTypeMessage('facility.services.header.inhouse',facility.facilityType)}
                    </h2>
                    {onsiteServices.map(
                      (item, index) =>
                        item.isSelect && (
                          <div key={index} className={styles.check}>
                            <Check /> <FormattedMessage id={`facility.services.${item.value}`} />
                          </div>
                        ),
                    )}
                  </div>

                  <div className={[grid.colMd6, styles.check, styles.box1, grid.noGuttersBoth].join(' ')}>
                    <h2 className={[styles.subheader].join(' ')}>
                      <FormattedMessage id="facility.services.header.offsite" />
                    </h2>
                    {offsiteServices.map(
                      (item, index) =>
                        item.isSelect && (
                          <div key={index} className={styles.check}>
                            <Check /> <FormattedMessage id={`facility.services.${item.value}`} />
                          </div>
                        ),
                    )}
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
    );
  }
}

FacilityService.defaultProps = {
};

FacilityService.propTypes = {
  facility: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(injectIntl(FacilityService));
