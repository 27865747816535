import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount({ map, mapApi } = this.props) {
    const options = {
      // restrict your search to a specific type of result
      // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    this.autoComplete = new mapApi.places.Autocomplete(this.searchInput, options);
    this.autoComplete.addListener('place_changed', this.onPlaceChanged);
    this.autoComplete.bindTo('bounds', map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlaceChanged = ({ addplace } = this.props) => {
    const place = this.autoComplete.getPlace();
    if (!place.geometry) {
      return;
    }
    // if (place.geometry.viewport) {
    //   map.fitBounds(place.geometry.viewport);
    // } else {
    //   map.setCenter(place.geometry.location);
    //   map.setZoom(13);
    // }

    addplace(place);
    this.searchInput.blur();
  };

  clearSearchBox() {
    this.searchInput.value = '';
  }

  onBlur() {
    const { value } = this.props;
    this.searchInput.value = value;
  }

  render() {
    const { value, className, placeholder } = this.props;
    return (
      <div>
        <input
          ref={ref => {
            this.searchInput = ref;
          }}
          type="text"
          className={className}
          onFocus={this.clearSearchBox}
          placeholder={placeholder}
          defaultValue={value}
          onBlur={this.onBlur}
        />
      </div>
    );
  }
}
AutoComplete.defaultProps = {
  value: '',
  className: '',
  placeholder: 'Enter a location'
};
AutoComplete.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string
};

export default AutoComplete;
