import { combineReducers } from 'redux';

import {
  SERVICES_FETCH_REQUESTED,
  SERVICES_FETCH_SUCCEEDED,
  SERVICES_FETCH_FAILED,

  SUGGESTED_FETCH_REQUESTED,
  SUGGESTED_FETCH_SUCCEEDED,
  SUGGESTED_FETCH_FAILED,

} from '../actions/services';

const suggested = (state = [], { data, type }) => {
  switch (type) {
    case SUGGESTED_FETCH_SUCCEEDED:
      return data;
    case SUGGESTED_FETCH_REQUESTED:
    case SUGGESTED_FETCH_FAILED:
    default:
      return state;
  }
};
const services = (state = [], { data, type }) => {
  switch (type) {
    case SERVICES_FETCH_SUCCEEDED:
      return data;
    case SERVICES_FETCH_REQUESTED:
    case SERVICES_FETCH_FAILED:
    default:
      return state;
  }
};

export default combineReducers({
  services,
  suggested,
});
