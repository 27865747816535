import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';

import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';

class FacilityPaymentOption extends PureComponent {

  render() {
    const { facility } = this.props;
    const services = facility.serviceCategoryMap.get('CAT_PAYMENT');
    return ( typeof services !== 'undefined' && services.length > 0 && (
        <>
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}><FormattedMessage id="facility.header.payment"/></h2>
            </div>
          </div>
          <div className={[grid.row, styles.box1].join(' ')}>
            <div className={grid.col}>
              <div className={grid.row}>
                {services && services.map(service => (
                  <div className={[grid.colSm6, grid.colMd4, 
                      styles.serviceLineItemCol, styles.check].join(' ')} key={service.id}>
                    <Check />
                    {service.name}
                  </div>
                ))}
              </div>
            </div>
            <div className={[grid.hiddenMdDown, grid.colMd4].join(' ')}></div>
          </div>
        </>
      )
    );
  }
}

FacilityPaymentOption.defaultProps = {
};

FacilityPaymentOption.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityPaymentOption);
