import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import uuidv4 from 'uuid/v4';

import { getFacilitySelector } from '../../../selectors/facility';
import { getFacilityTypeMessage } from '../../../utils/utils';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import FacilityContactInfo from './FacilityContactInfo/FacilityContactInfo';
import {
  POP_BLIND,
  POP_CHILDREN_COUPLE,
  POP_CHILDREN_MEN,
  POP_CHILDREN_WOMEN,
  POP_COUPLE,
  POP_COURT_INVOLVEMENT,
  POP_DEAF,
  POP_FEMALE_ADULT,
  POP_FEMALE_ONLY,
  POP_HOMELESS,
  POP_INDIGENOUS,
  POP_INTERSEX,
  POP_JUSTICE,
  POP_LGBTQ,
  POP_MALE_ADULT,
  POP_MALE_ONLY,
  POP_NON_BINARY,
  POP_PREGNANCY,
  POP_RENTRY,
  POP_TRANS_MAN,
  POP_TRANS_WOMEN,
  POP_UNDER_18_FEMALE,
  POP_UNDER_18_MALE,
  POP_VETERANS,
  POP_OCCURRING_MENTAL_HEALTH_DISORDER,
  POPULATION_ICONS,
} from '../../../constants/Population';


const POPULATION = [
  POP_FEMALE_ONLY,
  POP_FEMALE_ADULT,
  POP_PREGNANCY,
  POP_UNDER_18_FEMALE,
  POP_CHILDREN_WOMEN,

  POP_MALE_ONLY,
  POP_MALE_ADULT,
  POP_UNDER_18_MALE,
  POP_CHILDREN_MEN,

  POP_COUPLE,
  POP_CHILDREN_COUPLE,

  POP_LGBTQ,
  POP_INTERSEX,
  POP_NON_BINARY,
  POP_TRANS_MAN,
  POP_TRANS_WOMEN,

  POP_VETERANS,
  POP_DEAF,
  POP_BLIND,
  POP_HOMELESS,
  POP_INDIGENOUS,
  POP_RENTRY,
  POP_JUSTICE,
  POP_COURT_INVOLVEMENT,
  POP_OCCURRING_MENTAL_HEALTH_DISORDER,
];

class FacilityPopulation extends PureComponent {
  renderPopulation(list, pop) {
    const {
      intl: { formatMessage },
    } = this.props;

    let icon = pop;
    if (pop === POP_OCCURRING_MENTAL_HEALTH_DISORDER) {
      icon = 'POP_OCCURRING_MENTAL_HEALTH_DISORDER';
    }

    if (list.includes(pop) && POPULATION_ICONS[icon]) {
      const ICONS = POPULATION_ICONS;
      return (
        <div className={[grid.col4, styles.populationImgContainer].join(' ')} key={uuidv4()}>
          <img className={styles.populationImg} src={require(`../../../images/icons/v2/${ICONS[icon]}`)} />
          {formatMessage({ id: `facility.population.${icon}` })}
        </div>
      );
    }
  }

  render() {
    const {
      facility: { residentServed = '', priorityPopulations = '' },
      widgetType,
    } = this.props;

    return (
      <>
        {residentServed !== '' && (
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}>
                {getFacilityTypeMessage('facility.header.pop',widgetType)}
              </h2>
            </div>
          </div>
        )}
        <div className={[grid.row, styles.box1].join(' ')}>
          <div className={[grid.colSm5, grid.colMd8].join(' ')}>
            <div className={grid.row}>{POPULATION.map(pop => this.renderPopulation(residentServed, pop))}</div>
            {priorityPopulations && priorityPopulations.length > 0 && (
              <>
                <div className={grid.row}>
                  <div className={grid.col}>
                    <h2 className={styles.headerWInfoText}>
                      <FormattedMessage id="facility.header.priority.pop" />
                    </h2>
                    <p className={styles.infoText}>
                      <FormattedMessage id="facility.subheader.priority.pop" />
                    </p>
                  </div>
                </div>
                <div className={grid.row}>{POPULATION.map(pop => this.renderPopulation(priorityPopulations, pop))}</div>
              </>
            )}
          </div>
          <div className={[grid.colSm7, grid.colMd4].join(' ')}>
            <FacilityContactInfo />
          </div>
        </div>
      </>
    );
  }
}

FacilityPopulation.defaultProps = {
  widgetType: '',
};

FacilityPopulation.propTypes = {
  facility: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  widgetType: PropTypes.string,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(injectIntl(FacilityPopulation));
