import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
// import { FormattedMessage } from 'react-intl';

import App from './App';
import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// const validateSelector = (selector ) => {
//   let isValid = true;
//   if(!selector || typeof selector !== 'string' || selector === '') {
//     console
//     isValid = false;
//
//   }
//
//
//   return isValid;
// }
export const init = (data = {}) => {
  // const isValidContainer =  validateSelector(data.containerId);
  const containerId = data.containerId || 'root';
  if (!document.getElementById(containerId)) {
    alert('No DOM element found to attach to.');
    return;
  }
  // HACK for MO

  const { options = {} } = data;
  const { searchOptions = {} } = options;
  //location.hash.includes('new=1') &&

  if (location.search.includes('new=1')) {
    data.version = 2;
    searchOptions.defaultView = 'split';
    searchOptions.defaultDistance = 'state';
  }

  if (searchOptions.requiredAccreditationId === 24) {
    data.version = 2;
    searchOptions.defaultView = 'split';
    searchOptions.defaultDistance = 9999;
  }
  ReactDOM.render(<App {...data} />, document.getElementById(containerId));
};
