import { combineReducers } from 'redux';

import {
  FACILITIES_FETCH_REQUESTED,
  FACILITIES_FETCH_SUCCEEDED,
  FACILITIES_FETCH_FAILED,
} from '../actions/facilities';

const facilitiesTotalCount = (state = 0, { data, type }) => {
  switch (type) {
    case FACILITIES_FETCH_SUCCEEDED:
      return data.facilitiesTotalCount;
    case FACILITIES_FETCH_FAILED:
    case FACILITIES_FETCH_REQUESTED:
    default:
      return state;
  }
};
const facilitiesPageSize = (state = 0, { data, type }) => {
  switch (type) {
    case FACILITIES_FETCH_SUCCEEDED:
      return data.pageSize;
    case FACILITIES_FETCH_FAILED:
    case FACILITIES_FETCH_REQUESTED:
    default:
      return state;
  }
};
const facilities = (state = [], { data, type }) => {
  switch (type) {
    case FACILITIES_FETCH_SUCCEEDED:
      return data.facilities;
    case FACILITIES_FETCH_FAILED:
    case FACILITIES_FETCH_REQUESTED:
    default:
      return state;
  }
};

const isFetching = (state = true, {type}) => {
  switch (type) {
    case FACILITIES_FETCH_SUCCEEDED:
    case FACILITIES_FETCH_FAILED:
      return false;
    case FACILITIES_FETCH_REQUESTED:
      return true;
    default:
      return state;
  }
};


export default combineReducers({
  facilities,
  facilitiesTotalCount,
  facilitiesPageSize,
  isFetching,
});
