import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ToolTip from '../../common/ToolTip/ToolTip';

import { getFlagRatingIcon } from '../Flag';

import styles from './facilityFlag.module.css';
import {FormattedMessage} from "react-intl";

const FacilityFlag = ({ rating }) => {
  function renderToolTip() {
    return (
      <Fragment>
        <div className={styles.toolTipHeader}><FormattedMessage id="facility.rating.tooltip.header" /></div>
        <div className={styles.toolTipBody}><FormattedMessage id="facility.rating.tooltip.body" /></div>

      </Fragment>
    );
  }

  if (rating <= 0) {
    return '';
  }
  return (
    <Fragment>
        <ToolTip
          placement="bottom"
          trigger="hover"
          tooltip={renderToolTip()}
        >
          <>
            <div>
              <i className={styles.flag}>{getFlagRatingIcon(rating)}</i>
              {`${rating}`}
            </div>
            <div className={styles.rating}><FormattedMessage id="search.rating" /></div>
          </>
        </ToolTip>
    </Fragment>
  );
};

FacilityFlag.defaultProps = {
  rating: 0,
};

FacilityFlag.propTypes = {
  rating: PropTypes.number,
};

export default FacilityFlag;
