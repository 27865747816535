import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { injectIntl } from 'react-intl';

import selectStyles from './SelectStyles';
import { POPULATION_LIST, SCHOOL_POPULATION_LIST } from '../../../../constants/Population';
import FacilitySearchBar from '../FacilitySearchBar/FacilitySearchBar';
import { FT_SCHOOL } from '../../../../constants/Other';
import { getI18nString } from '../../../../utils/utils';

class FacilitySearchPopulation extends PureComponent {
  static getSelectedItem(value) {
    return POPULATION_LIST.find(item => item.value === parseInt(value, 10));
  }

  constructor(props) {
    super(props);
    this.state = {
      values: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { search, widgetType } = this.props;

    let listType = [];
    if (widgetType.startsWith(FT_SCHOOL)) {
      listType = SCHOOL_POPULATION_LIST.find(item => item.value === search.population);
    } else {
      listType = POPULATION_LIST.find(item => item.value === search.population);
    }

    this.setState({ values: listType });
  }

  componentDidUpdate(prevProps) {
    const { search, widgetType } = this.props;
    if (prevProps.search !== search) {
      let listType = [];
      if (widgetType.startsWith(FT_SCHOOL)) {
        listType = SCHOOL_POPULATION_LIST.find(item => item.value === search.population);
      } else {
        listType = POPULATION_LIST.find(item => item.value === search.population);
      }
      this.setState({ values: listType });
    }
  }

  handleChange(value) {
    const { handleSearchBarChange } = this.props;
    handleSearchBarChange('population', value);

    if (value === null) {
      handleSearchBarChange('population', { value: null });
    }
  }

  render() {
    const {
      search,
      intl: { formatMessage },
      widgetType,
    } = this.props;

    const { values } = this.state;
    return (
      <Select
        ref={ref => {
          this.select = ref;
        }}
        options={widgetType.startsWith(FT_SCHOOL) ? SCHOOL_POPULATION_LIST : POPULATION_LIST}
        placeholder={formatMessage({
          id: getI18nString(widgetType.startsWith(FT_SCHOOL) && FT_SCHOOL, 'search.population.select'),
        })}
        styles={selectStyles()}
        name="population"
        value={values}
        isSearchable={false}
        className="basic-single"
        onChange={this.handleChange}
        isClearable={true}
      />
    );
  }
}

FacilitySearchPopulation.defaultProps = {
  widgetType: '',
};

FacilitySearchPopulation.propTypes = {
  handleSearchBarChange: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  widgetType: PropTypes.string,
};

export default injectIntl(FacilitySearchPopulation);
