import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import uuidv4 from 'uuid/v4';

import WhatsThis from '../../common/Modal/WhatsThis';

import { getFacilitySelector } from '../../../selectors/facility';

import { ReactComponent as OpenLink } from '../../../images/icons/v2/arrow-up-right-from-square.svg';
import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';
import { ReactComponent as X } from '../../../images/icons/v2/x.svg';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';

class FacilityHousePolicy extends PureComponent {
  renderPolicyLink(item) {
    return (
      item.tags && (
        <div className={grid.row} key={uuidv4()}>
          <div className={[grid.col].join(' ')}>
            <a href={item.url} className={[styles.openIcon, styles.licenseLink].join(' ')} target="_blank">
              <OpenLink /> <FormattedMessage id="facility.rules.policy.linklabel" values={{ linklabel: item.label }} />
            </a>
          </div>
        </div>
      )
    );
  }

  render() {
    const { facility } = this.props;

    const { smokingPolicy = null, moudMedications = null, moudPolicy = null, marijuanaUsePolicy = null } = facility;
    const medsToArray = moudMedications ? moudMedications.split(',') : [];

    return (( (facility.housePolicyMap && facility.housePolicyMap.length > 0)
      || smokingPolicy !== null  || marijuanaUsePolicy != null || moudPolicy != null )  && (
          <div className={[styles.container].join(' ')}>
            <div className={grid.row}>
              <div className={grid.col}>
                <h2 className={styles.header}>
                  <FormattedMessage id="facility.header.rules" />
                </h2>
              </div>
            </div>

            {facility.housePolicyMap && facility.housePolicyMap.length > 0 && (
              <div className={styles.box1}>
                <div className={[grid.row, grid.noGutters].join(' ')}>
                  <div className={[grid.colAuto, grid.noGuttersBoth].join(' ')}>
                    <h2 className={[styles.subheader].join(' ')}>
                      <FormattedMessage id="facility.rules.policy.procedures" />
                    </h2>
                  </div>
                  <WhatsThis headerMessageId={2} contentMessageId={2} />
                </div>
                {facility.housePolicyMap.map(item => this.renderPolicyLink(item))}
              </div>
            )}

            {smokingPolicy !== null && (
              <div className={styles.box1}>
                <div className={grid.row}>
                  <div className={grid.col}>
                    <h2 className={styles.subheader}>
                      <FormattedMessage id="facility.rules.policy.tobacco" />
                    </h2>
                  </div>
                </div>
                <div className={grid.row}>
                  <div className={grid.col}>
                    {smokingPolicy === 'PT_TOBACCO_NOT_ALLOWED' && (
                      <div className={styles.check}>
                        <Check />
                        <FormattedMessage id="facility.rules.smokingPolicy.PT_TOBACCO_NOT_ALLOWED" />
                      </div>
                    )}

                    {smokingPolicy === 'PT_TOBACCO_ALLOWED' && (
                      <div className={styles.check}>
                        <Check />
                        <FormattedMessage id="facility.rules.smokingPolicy.PT_TOBACCO_ALLOWED" />
                      </div>
                    )}

                    {smokingPolicy === 'PT_TOBACCO_ALLOWED_DESIGNATED' && (
                      <div className={styles.check}>
                        <Check />
                        <FormattedMessage id="facility.rules.smokingPolicy.PT_TOBACCO_ALLOWED_DESIGNATED" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {marijuanaUsePolicy != null && (
              <div className={styles.box1}>
                <div className={grid.row}>
                  <div className={grid.col}>
                    <h2 className={styles.subheader}>
                      <FormattedMessage id="facility.rules.policy.marijuana" />
                    </h2>
                  </div>
                </div>
                <div className={grid.row}>
                  <div className={grid.col}>
                    {marijuanaUsePolicy === 'PT_MARIJUANA_NOT_ALLOWED' && (
                      <div className={styles.check}>
                        <Check />
                        <FormattedMessage id="facility.rules.smokingPolicy.PT_MARIJUANA_NOT_ALLOWED" />
                      </div>
                    )}

                    {marijuanaUsePolicy === 'PT_MARIJUANA_ALLOWED' && (
                      <div className={styles.check}>
                        <Check />
                        <FormattedMessage id="facility.rules.smokingPolicy.PT_MARIJUANA_ALLOWED" />
                      </div>
                    )}

                    {marijuanaUsePolicy === 'PT_MARIJUANA_PRESCRIPTION' && (
                      <div className={styles.check}>
                        <Check />
                        <FormattedMessage id="facility.rules.smokingPolicy.PT_MARIJUANA_PRESCRIPTION" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {moudPolicy != null && (
              <div className={styles.box1}>
                <div className={grid.row}>
                  <div className={grid.col}>
                    <h2 className={styles.subheader}>
                      <FormattedMessage id="facility.rules.policy.mat" />
                    </h2>
                  </div>
                </div>

                {moudPolicy !== 'NONE' && (

                  <>
                    <div className={styles.box1}>
                      <div className={styles.check}>
                        <Check />
                        <FormattedMessage id="facility.rules.policy.mat.moudPolicy" />
                      </div>
                    </div>
                    <div className={styles.box1}>
                      <h2 className={styles.subheader}>
                        <FormattedMessage id="facility.rules.policy.mat.moudMeds" />
                      </h2>

                      <div className={grid.col}>
                        <ol className={styles.simpleList}>
                          {medsToArray.map(item => (
                            <li className={styles.simpleListItem} key={uuidv4()}>
                              <FormattedMessage id={`facility.policy.moud.${item}`} />
                            </li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

    ));
  }
}

FacilityHousePolicy.defaultProps = {};

FacilityHousePolicy.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityHousePolicy);
