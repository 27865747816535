import React, { Component } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Facilities from './Facilities';
import Facility from './Facility';
import { storeSystemConfig } from '../actions/system';


import '../app.css';
import '../styles/bootstrap-grid.module.css';
import '../styles/pagination.module.css';
import {getSystemConfig} from "../selectors/system";

const history = createBrowserHistory();

class Base extends Component {
  constructor(props) {
    super(props);

    this.body = React.createRef();
  }

  componentDidMount() {

    const {dispatchSystemConfig, config } = this.props;

    dispatchSystemConfig(config);

    const clientCords = this.body.current.getBoundingClientRect();
    const {top} = clientCords;
    const viewportHeight = window.innerHeight;

    this.body.current.style.minHeight = `${viewportHeight - top}px`;
  }

  render() {
    const {  config: {options = {}}, systemConfig } = this.props;
    const {  searchOptions ={} } = options;
    return (
      <HashRouter history={history}>
        <div id="gh-body" className="gh-body" ref={this.body}>
          {systemConfig && systemConfig.apiKey && (
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => {
                return (
                  <Facilities {...props} searchOptions={searchOptions} />
                );}}
            />
            <Route
              exact
              path="/facility/:id"
              render={(props) => {
                return (
                  <Facility {...props} />
                );}}
            />
          </Switch>
          )}
        </div>
      </HashRouter>
    );
  }
}
Base.defaultProps = {

};
Base.propTypes = {
  dispatchSystemConfig: PropTypes.func.isRequired,
  systemConfig: PropTypes.object.isRequired,

  config: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    containerId:PropTypes.string.isRequired,
    options: PropTypes.object,
    version: PropTypes.number
  })
};


const mapDispatchToProps = {
  dispatchSystemConfig: storeSystemConfig,

};

const mapStateToProps = (state) => ({
  systemConfig: getSystemConfig(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Base);
