import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { FormattedMessage, FormattedPlural } from 'react-intl';

import { getFacilitySelector } from '../../selectors/facility';
import FacilitySocialLinks from './FacilitySocialLinks';

import styles from './facility.module.css';

const ASSET_URL = `${process.env.REACT_APP_STATIC}/accreditation-logos`;
const BLANK_IMAGE = require('../../images/icons/accreditation/blank.png');
const BADGE_PATH = process.env.REACT_APP_BADGES_PATH;

const USE_BADGE_SYSTEM = [12,14,15,16,20,21,24,25,26,27,35,45,47,56,66];


class FacilityOverview extends PureComponent {
  static renderDescription(facility) {
    const {description, summary } = facility;
    return (
      <Fragment>
        {summary &&
        summary.split('\n').map(item => {
          return (
            <Fragment key={uuidv4()}>
              {item}
              <br />
            </Fragment>
          );
        })}
        {summary && description && (<br />)}

        {description &&
          description.split('\n').map(item => {
            return (
              <Fragment key={uuidv4()}>
                {item}
                <br />
              </Fragment>
            );
          })}
      </Fragment>
    );
  }

  static renderLicenses(licenses = []) {
    if (!licenses.length) {
      return '';
    }

    return (
      <Fragment>
        <div className={styles.sectionSubHeader}>
          <FormattedPlural
            value={licenses.length}
            one={<FormattedMessage id="facility.license" />}
            other={<FormattedMessage id="facility.licenses" />}
          />
        </div>
        {licenses.map(link => {
          return (
            <div key={link.id}>
              {`${link.state}: ${link.description} `}
              <span className={styles.licenseId}>{`(${link.number})`}</span>
            </div>
          );
        })}
      </Fragment>
    );
  }

  static renderAccrediations(accreditations = []) {
    if (!accreditations.length) {
      return '';
    }

    return (
      <Fragment>
        <div className={styles.sectionSubHeader}>
          <FormattedMessage id="facility.accreditation" />
        </div>
        {accreditations.map(item => {
          return (
            <div className={styles.accreditationRow} key={item.id}>
              {item.imageUrl && USE_BADGE_SYSTEM.includes(item.id) && (
                <a href={`${BADGE_PATH}/badge/verify/${item.facilityAccreditationId}`} target="_blank">
                  <img src={item.imageUrl} className={styles.img} />
                </a>
              )}
              {item.imageUrl && !USE_BADGE_SYSTEM.includes(item.id) && (
                <img src={item.imageUrl} className={styles.img} />
              )}
              {!item.imageUrl && (<img src={BLANK_IMAGE} className={styles.img} />)}

              {item.name}
            </div>
          );
        })}
      </Fragment>
    );
  }

  render() {
    const {
      facility,
    } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.sectionHeader}>
          <FormattedMessage id="facility.overview" />
        </div>
        {this.constructor.renderDescription(facility)}
        {this.constructor.renderAccrediations(facility.accreditations)}

        {this.constructor.renderLicenses(facility.licenses)}

        { (<FacilitySocialLinks facility={facility} />)}
      </div>
    );
  }
}
FacilityOverview.propTypes = {
  facility: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityOverview);
