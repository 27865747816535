import { combineReducers } from 'redux';

import { GOOGLE_MAP_LOADED } from '../actions/map';

const googleMap = (state = {}, { map, type }) => {
  switch (type) {
    case GOOGLE_MAP_LOADED:
      return map;
    default:
      return state;
  }
};

export default combineReducers({
  googleMap,
});
