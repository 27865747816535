export const SA_LEGAL = 'SA_LEGAL';
export const RES_DETOX = 'RES_DETOX';
export const SA_LIFE_SKILLS = 'SA_LIFE_SKILLS';
export const SA_PEER_SUPPORT = 'SA_PEER_SUPPORT';
export const SA_CLERGY = 'SA_CLERGY';
export const SA_CHILDCARE = 'SA_CHILDCARE';
export const SA_EDUCATION_SUPPORT = 'SA_EDUCATION_SUPPORT';
export const SA_EMPLOYMENT_SUPPORT = 'SA_EMPLOYMENT_SUPPORT';

export const CAT_12_STEP = 'CAT_12_STEP';
export const CAT_PROG = 'CAT_PROG';
export const CAT_TREATMENT = 'CAT_TREATMENT';

//12 step
export const TWELVE_STEP_AA = '12_STEP_AA';
export const TWELVE_STEP_NA = '12_STEP_NA';
export const TWELVE_STEP_MA = '12_STEP_MA';
export const TWELVE_STEP_CA = '12_STEP_CA';
export const TWELVE_STEP_AL = '12_STEP_AL';
export const TWELVE_STEP_CELEBRATE = '12_STEP_CELEBRATE';

// Programs
export const PRGM_SEX_OFFENDER = 'PRGM_SEX_OFFENDER';
export const PRGM_CLOTHING = 'PRGM_CLOTHING';
export const PRGM_SMART = 'PRGM_SMART';
export const PRGM_RATIONAL = 'PRGM_RATIONAL';
export const PRGM_WOMEN_SOBRIETY = 'PRGM_WOMEN_SOBRIETY';
export const PRGM_MODERATION = 'PRGM_MODERATION';
export const PRGM_LIFERING = 'PRGM_LIFERING';
export const PRGM_MAT = 'PRGM_MAT';

// Treatment
export const TA_IPT = 'TA_IPT';
export const TA_COUPLES_FAM = 'TA_COUPLES_FAM';
export const TA_GRP_THERAPY = 'TA_GRP_THERAPY';
export const TA_CBT = 'TA_CBT';
export const TA_DBT = 'TA_DBT';
export const TA_CLINICAL_THERAPY = 'TA_CLINICAL_THERAPY';
export const TA_COMPLEMENTARY_THERAPIES = 'TA_COMPLEMENTARY_THERAPIES';
export const TA_INTERPERSONAL_THERAPY = 'TA_INTERPERSONAL_THERAPY';
export const TA_MEDICAL_SUPERVISION = 'TA_MEDICAL_SUPERVISION';
export const TA_RECREATIONAL_THERAPIES = 'TA_RECREATIONAL_THERAPIES';
export const TA_SUPPORT_GROUPS = 'TA_SUPPORT_GROUPS';
export const TA_THERAPEUTIC_COMMUNITY = 'TA_THERAPEUTIC_COMMUNITY';
export const TA_OTHER_MENTAL = 'TA_OTHER_MENTAL';

// Walkable
export const CONVENIENCE_STORE = 'CONVENIENCE_STORE';
export const DENTAL_SERVICES = 'DENTAL_SERVICES';
export const GROCERY_STORE = 'GROCERY_STORE';
export const GAS_STATION = 'GAS_STATION';
export const HEALTHCARE = 'HEALTHCARE';
export const PHARMACY = 'TA_THERAPEUTIC_COMMUNITY';
export const RESTAURANTS = 'RESTAURANTS';
export const OTHER = 'OTHER';
