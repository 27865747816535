import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import ToolTip from '../../common/ToolTip/ToolTip';


import styles from './facilityBadges.module.css';


const FacilityBadges = ({ facility }) => {


  function renderToolTip(badge) {
    return (
      <Fragment>
        <div className={styles.toolTipHeader}><FormattedMessage id={`facility.badges.tooltip.header.${badge}`} /></div>
        <div className={styles.toolTipBody}><FormattedMessage id={`facility.badges.tooltip.body.${badge}`} /></div>

      </Fragment>
    );
  }
  return (
    <Fragment>
          {facility.verified && (
            <ToolTip
              placement="bottom"
              trigger="hover"
              tooltip={renderToolTip('verified')}
            >
              <i className={styles.verified}></i>
            </ToolTip>
          )}
          {facility.claimed && (
            <ToolTip
              placement="bottom"
              trigger="hover"
              tooltip={renderToolTip('claimed')}
            >
              <i className={styles.claimed}></i>
            </ToolTip>
          )}
          {facility.premium && (
            <ToolTip
              placement="bottom"
              trigger="hover"
              tooltip={renderToolTip('premium')}
            >
              <i className={styles.premium}></i>
            </ToolTip>
          )}
    </Fragment>
  );
};


FacilityBadges.propTypes = {
  facility: PropTypes.object.isRequired,
};

export default FacilityBadges;
