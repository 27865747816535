import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FacilityV1 from './v1/Facility';
import FacilityV2 from './v2/Facility';

import { loadFacility } from '../actions/facility';
import { isFetchingFacility } from '../selectors/facility';

import Loader from '../components/common/Loader/Loader';
import {getSystemConfig} from '../selectors/system';

class Facility extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
    const {match: { params }, getFacility } = this.props;

    this.setState({ id: params.id }, () => {
      const { id } = this.state;
      getFacility(id);
    });
  }

  render() {
    const { isFetching, systemConfig: {version = 1 } } = this.props;

    return (
      <Fragment>
        <Loader removeLoader={!isFetching} />
          {version === 1 && ( <FacilityV1 {...this.props} />)}
          {(version === 1.5 || version === 2 || version === 2.1) && ( <FacilityV2 {...this.props} />)}
      </Fragment>
    );
  }
}
Facility.defaultProps = {
};

Facility.propTypes = {
  getFacility: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  systemConfig: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getFacility: state => {
    dispatch(loadFacility(state));
  },
});

// reducer is called facilities
const mapStateToProps = (state) => ({
  isFetching: isFetchingFacility(state),
  systemConfig: getSystemConfig(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Facility);
