import React from 'react';

import PropTypes from "prop-types";
import styles from './loader.module.css';

const HEIGHT_OFFSET = 200;

const Loader = ({removeLoader}) => (
  <>
    {!removeLoader &&  (
      <div className={styles.container}>
        <div className={styles.loader} style={{top:`${HEIGHT_OFFSET}px`}}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )}
  </>
);

Loader.propTypes = {
  removeLoader: PropTypes.bool.isRequired,
};

export default Loader;
