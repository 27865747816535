import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';
import { ReactComponent as X } from '../../../images/icons/v2/x.svg';
import { ReactComponent as DownloadIcon } from '../../../images/icons/v2/angles-down.svg';
import { ReactComponent as OpenLink } from '../../../images/icons/v2/arrow-up-right-from-square.svg';

import { getFacilitySelector } from '../../../selectors/facility';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';

class FacilityApplication extends PureComponent {
  renderEmptyData() {
    const { facility } = this.props;

    const {
      applicationRequired = null,
      applicationLink = null,
      applicationFeeRequired = null,
      screeningRequired = null,
      applicationDocument = null,
      backgroundCheckRequired = null,
      urineTestRequired = null,
    } = facility;

    return (
      applicationRequired === null &&
      applicationLink === null &&
      applicationFeeRequired === null &&
      screeningRequired === null &&
      applicationDocument === null &&
      backgroundCheckRequired === null &&
      urineTestRequired === null
    );
  }
  render() {
    const { facility } = this.props;

    const {
      applicationRequired = null,
      applicationLink = null,
      applicationFeeRequired = null,
      screeningRequired = null,
      applicationDocument = null,
      backgroundCheckRequired = null,
      urineTestRequired = null,
    } = facility;

    return (
      !this.renderEmptyData() && (
        <>
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}>
                <FormattedMessage id="facility.header.application.process" />
              </h2>
            </div>
          </div>
          <>
            <div className={styles.box1}>
              <div className={[grid.row, grid.rowBottom].join(' ')}>
                {applicationRequired && (
                  <div className={[grid.colSm6, styles.check].join(' ')}>
                    <Check /> <FormattedMessage id="facility.application.process.applicationRequired" />
                  </div>
                )}

                {screeningRequired && (
                  <div className={[grid.colSm6, styles.check].join(' ')}>
                    <Check /> <FormattedMessage id="facility.application.process.screeningRequired" />
                  </div>
                )}

                {urineTestRequired && (
                  <div className={[grid.colSm6, styles.check].join(' ')} >
                    <Check /> <FormattedMessage id="facility.application.process.urineTestRequired" />
                  </div>
                )}

                { backgroundCheckRequired && (
                  <div className={[grid.colSm6, styles.check].join(' ')}>
                    <Check /> <FormattedMessage id="facility.application.process.backgroundCheckRequired" />
                  </div>
                )}

                {applicationFeeRequired && (
                  <div className={[grid.colSm6, styles.check].join(' ',)}>
                    <Check /> <FormattedMessage id="facility.application.process.applicationFeeRequired" />
                  </div>
                )}
              </div>
            </div>

            {applicationDocument && (
              <div className={styles.box1}>
                <div className={[grid.col, grid.noGuttersBoth].join(' ')}>
                  <a href={applicationDocument} className={styles.downloadApplicationForm} target="_blank">
                    <DownloadIcon />
                    <span>Download application form</span>
                  </a>
                </div>
              </div>
            )}

            {applicationLink !== null && (
              <div className={styles.box1}>
                <div className={[grid.col, grid.noGuttersBoth].join(' ')}>
                  <a href={applicationLink} className={[styles.openIcon, styles.licenseLink].join(' ')} target="_blank">
                    <OpenLink /> <FormattedMessage id="Application Form" />
                  </a>
                </div>
              </div>
            )}
          </>
        </>
      )
    );
  }
}

FacilityApplication.defaultProps = {};

FacilityApplication.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityApplication);
