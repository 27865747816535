//options grouped into services
export const servicesItems = [
  'population',
  'servicesPrograms',
  'language',
  'receivingMAT',
  'residentCharacteristics',
  'amenities',
  'paymentType',
];

export const PAYMENT_TYPES_LIST = [
  { label: 'Cash', value: 195 },
  { label: 'Medicaid', value: 196 },
  { label: 'Medicare', value: 197 },
  { label: 'Voucher', value: 202 },
  { label: 'Military', value: 211 },
  { label: 'Money Order', value: 525 },
  { label: 'Accepts Insurance', value: 635 },
  { label: 'Personal Check', value: 727 },
  { label: 'Credit', value: 728 },
];

export const RESIDENT_CHARACTERISTICS_LIST = [
  { label: 'Pregnancy', value: 486 },
  { label: 'Deaf & hard of hearing', value: 720 },
  { label: 'Blind', value: 721 },
  { label: 'Homeless', value: 722 },
  { label: 'Indigenous peoples', value: 723 },
  { label: 'Re-entry population', value: 724 },
  { label: 'Justice Involved', value: 769 },
  { label: 'Any court involvement', value: 787 },
  { label: 'Under 18', value: 268 },
];
