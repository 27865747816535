import {facilityFeatures} from "./facilityFeatures";

export const facilityTypes = [
    'HEALTH_MENTAL',
    'HEALTH_BEHAVIORAL',
    'TREATMENT_CLINICAL',
    'TREATMENT_OUTPATIENT',
    'RCO',

    'SERVICE_FACILITY',
    'SCHOOL',

    'RECOVERY_RESIDENCE',

    'HOMELESS_SHELTER',
    'PERSONAL_PROFILE',

    'TRANSITIONAL_HOUSING',
    'PERMANENT_SUPPORTIVE_HOUSING',
    'EMERGENCY_SHELTER',
    'RAPID_RE_HOUSING',

    'PUBLIC_ASSISTANCE',
    'FOOD_NUTRITION',
    'SUPPORT_GROUPS',
    'HYGIENE',
    'INSURANCE',
    'HARM_REDUCTION'
];

const recoveryTreatment = [
    'RECOVERY_RESIDENCE',
    'HEALTH_MENTAL',
    'HEALTH_BEHAVIORAL',
    'TREATMENT_CLINICAL',
    'TREATMENT_OUTPATIENT',
    'PERMANENT_SUPPORTIVE_HOUSING',
];

const shelter = [
    'HOMELESS_SHELTER',
    'TRANSITIONAL_HOUSING',
    'EMERGENCY_SHELTER',
    'RAPID_RE_HOUSING',
    'RCO',
];

const generalResource = [
    'PUBLIC_ASSISTANCE',
    'FOOD_NUTRITION',
    'SUPPORT_GROUPS',
    'HYGIENE',
    'INSURANCE',
    'HARM_REDUCTION',
    'SERVICE_FACILITY',
    'PERSONAL_PROFILE'

];

export const isRecoveryTreatment = (type) => {
    return recoveryTreatment.find(ftype => ftype === type) !== undefined;
};

export const isShelter = (type) => {
    return shelter.find(ftype => ftype === type) !== undefined;
};

export const isGeneralResource = (type) => {
    return generalResource.find(ftype => ftype === type) !== undefined;
};

export const getFacilityFeatures = (facilityType) => {
    if (facilityType === undefined || facilityType === null || facilityType === '') {
        return undefined;
    }
    if (isRecoveryTreatment(facilityType)) {
        return facilityFeatures['recoveryTreatment'];
    }
    if (isShelter(facilityType)) {
        return facilityFeatures['shelter'];
    }
    if (isGeneralResource(facilityType)) {
        return facilityFeatures['generalResource'];
    }
};