import { call, put, select, takeLatest } from 'redux-saga/effects';


import {
  STORE_AUTH_REQUESTED,
  authFetchFailed,
  authFetchSucceeded,

} from '../actions/system';

import { fetchAuth } from '../services/AuthService';
import { getSystemConfig } from '../selectors/system';

export function* loadAuth() {
  try {
    const systemConfig = yield select(getSystemConfig);
    const auth = yield call(fetchAuth, {apiKey: systemConfig.apiKey});
    const successAction = yield call(authFetchSucceeded, auth);

    yield put(successAction);
  } catch (e) {
    const failAction = yield call(authFetchFailed, e.message);
    yield put(failAction);
  }
}

const authSaga = [
  takeLatest(STORE_AUTH_REQUESTED, loadAuth),
];

export default authSaga;
