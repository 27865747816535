import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  FACILITY_FETCH_REQUESTED,
  facilityFetchSucceeded,
  facilityFetchFailed,

  INSTITUTION_FETCH_REQUESTED,
  institutionFetchSucceeded,
  institutionFetchFailed,

} from '../actions/facility';

import { fetchFacility, fetchInstitution } from '../services/FacilityService';
import { getSystemAuth, getSystemConfig } from '../selectors/system';

export function* loadFacility(args = { id: 0 }) {
  try {
    const systemConfig = yield select(getSystemConfig);

    const serviceArgs = { id: args.id, apiKey: systemConfig.apiKey, version: systemConfig.version };
    const facility = yield call(fetchFacility, serviceArgs);

    const successAction = yield call(facilityFetchSucceeded, facility);

    yield put(successAction);
  } catch (e) {
    const failAction = yield call(facilityFetchFailed, e.message);
    yield put(failAction);
  }
}

export function* loadInstitution() {
  try {
    const systemConfig = yield select(getSystemConfig);
    const { properties: { facilityId = 0 } = {} } = yield select(getSystemAuth);

    const institution = yield call(fetchInstitution, { id: facilityId, apiKey: systemConfig.apiKey });
    const successAction = yield call(institutionFetchSucceeded, institution);

    yield put(successAction);
  } catch (e) {
    const failAction = yield call(institutionFetchFailed, e.message);
    yield put(failAction);
  }
}

const facilitySaga = [
  takeLatest(FACILITY_FETCH_REQUESTED, loadFacility),
  takeLatest(INSTITUTION_FETCH_REQUESTED, loadInstitution),
];

export default facilitySaga;
