import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import styles from './Modal.module.css';
import grid from '../../../styles/bootstrap-grid.module.css';

import { ReactComponent as QuestionMark } from '../../../images/icons/question-mark.svg';
import { ReactComponent as X } from '../../../images/icons/v2/x.svg';

class WhatsThis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.headerMessageId = props.headerMessageId;
    this.contentMessageId = props.contentMessageId;
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <>
        <div className={styles.whatsThis}>
          <QuestionMark className={styles.questionMark} />
          <a onClick={this.handleOpenModal}>
            <FormattedMessage id="facility.help.whatsthis" />
          </a>
        </div>

        <Modal isOpen={this.state.showModal} className={styles.modal} overlayClassName={styles.overlay}>
          <div className={styles.modalContent}>
            <div className={[styles.modalHeader].join(' ')}>
              <div className={[styles.modalTitle].join(' ')}>
                <h4>
                  <QuestionMark /> <FormattedMessage id={`facility.help.popup.header.${this.headerMessageId}`} />
                </h4>
              </div>
              <div className={[styles.closeButton].join(' ')}>
                <X onClick={this.handleCloseModal} />
              </div>
            </div>
              <div className={[styles.modalBody].join(' ')}>
                <FormattedMessage id={`facility.help.popup.content.${this.headerMessageId}`} />
              </div>
          </div>
        </Modal>
      </>
    );
  }
}

WhatsThis.defaultProps = {
  headerMessageId: null,
  contentMessageId: null,
};

WhatsThis.propTypes = {
  headerMessageId: PropTypes.number,
  contentMessageId: PropTypes.number,
};

export default WhatsThis;
