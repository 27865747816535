import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// import Slider from 'react-slick';
import uuidv4 from 'uuid/v4';

import { getSnippetAddressSingleLine } from '../../LocationFormat';

import styles from './facilityCard.module.css';

import { ReactComponent as Phone } from '../../../../images/icons/phone.svg';
import { ReactComponent as OpenLink } from '../../../../images/icons/v2/arrow-up-right-from-square.svg';

import { POPULATION_ICONS } from '../../../../constants/Population';
import { USE_BADGE_SYSTEM } from '../../../../constants/badges';
const ASSET_URL = `${process.env.REACT_APP_STATIC}/accreditation-logos`;

import FacilityStatus from '../FacilityStatus/FacilityStatus';
import { ReactComponent as Check } from '../../../../images/icons/v2/check.svg';
import { ReactComponent as Certificate } from '../../../../images/icons/v2/certificate.svg';
import { FT_PERSONAL_PROFILE, FT_SCHOOL } from '../../../../constants/Other';
import { getFormattedDate } from '../../../../utils/utils';

const DEFAULT_IMAGE = require('../../../../images/house.svg');
const DEFAULT_PERSONAL_PROFILE_IMAGE = require('../../../../images/user.png');
const DEFAULT_SCHOOL_IMAGE = require('../../../../images/school.png');

const VIEW_SPLIT = 'split';

const getFacilityImage = (facility, type) => {
  let facilityImage = DEFAULT_IMAGE;

  if (type === FT_PERSONAL_PROFILE) {
    facilityImage = DEFAULT_PERSONAL_PROFILE_IMAGE;
  }

  if (type === FT_SCHOOL) {
    facilityImage = DEFAULT_SCHOOL_IMAGE;
  }

  if (facility.images && facility.images.length) {
    facilityImage = facility.images[0];
  }
  return facilityImage;
};

const licenceText = item => {
  return (
    <>
      <b>
        {item.association === 'NARR' && (item.status === 'UNDEFINED' || item.status === 'VERIFIED') ? (
          <FormattedMessage id={`accreditation.${item.id}`} />
        ) : (
          item.name
        )}
      </b>
      {item.status === 'IN_PROCESS' && (
        <small>
          {' '}
          (<FormattedMessage id="facility.accreditation.IN_PROCESS" />)
        </small>
      )}
      {item.status !== 'IN_PROCESS' && item.level && item.level.includes('LEVEL') && (
        <b className={styles.accrLevel}>
          &nbsp; <FormattedMessage id={`facility.license.${item.level}`} />
        </b>
      )}
    </>
  );
};

const isAccreditation = item => {
  return item.status !== 'NOT_VERIFIED' || item.status !== 'ARCHIVED';
};
const renderAccreditations = facility => {
  const { accreditations = [] } = facility;
  return (
    <>
      {accreditations.length > 0 &&
        accreditations.map((item, index) => (
          <div className={styles.certificate} key={uuidv4()}>
            {isAccreditation(item) && USE_BADGE_SYSTEM.includes(item.id) && (
              <>
                <img src={`${ASSET_URL}/${item.id}.png`} />
                {licenceText(item)}
              </>
            )}
            {isAccreditation(item) && !USE_BADGE_SYSTEM.includes(item.id) && (
              <>
                <Certificate />
                {licenceText(item)}
              </>
            )}
          </div>
        ))}
    </>
  );
};

const PopulationIcon = ({ facility }) => {
  if (facility.genderType && POPULATION_ICONS[facility.genderType]) {
    const ICONS = POPULATION_ICONS;

    return (
      <div className={styles.gender}>
        <img
          className={styles.populationImg}
          src={require(`../../../../images/icons/v2/${ICONS[facility.genderType]}`)}
        />
        <FormattedMessage id={`facility.population.${facility.genderType}`} />
      </div>
    );
  }
  return null;
};

const FacilityCard = ({ facility, view, facilityUrl, systemOptions: { searchOptions = {} } = {}, version }) => {
  // const sliderRef = useRef(null);
  const cardRef = useRef(null);

  const handleCardClick = e => {
    if (e.target.nodeName !== 'A') {
      document.location = facilityUrl;
    }
  };

  const emptyDataSecondaryLeft = () => {
    const { avgCostMinimum = null, depositAmount = null, rentPaidWeekly = null, acceptsInsurance } = facility;

    return (
      avgCostMinimum === null &&
      depositAmount === null &&
      rentPaidWeekly === null &&
      (acceptsInsurance === null || acceptsInsurance === false)
    );
  };

  return (
    <div
      className={[
        styles.facilityCard,
        searchOptions.smallView ? styles.smallView : '',
        view === VIEW_SPLIT ? styles.facilityCardSplitView : ' ',
      ].join(' ')}
      ref={cardRef}
      onClick={handleCardClick}
    >
      <div className={styles.imageContainer}>
        {/*{facility.images && facility.images.length > 1 ? (*/}
        {/*  <div className={styles.sliderContainer}>*/}
        {/*    <Slider {...settings} ref={sliderRef}>*/}
        {/*      {facility.images.map((item, index) => (*/}
        {/*        <div className={styles.facilityImage} key={index}>*/}
        {/*          <img src={item} className={styles.facilityImage} />*/}
        {/*        </div>*/}
        {/*      ))}*/}
        {/*    </Slider>*/}
        {/*    <button className={styles.prevArrow} onClick={() => sliderRef.current.slickPrev()}>{`⟨`}</button>*/}
        {/*    <button className={styles.nextArrow} onClick={() => sliderRef.current.slickNext()}>{`⟩`}</button>*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <img src={getFacilityImage(facility)} className={styles.facilityImage}  />*/}
        {/*)}*/}
        <img src={getFacilityImage(facility, searchOptions.widgetType)} className={styles.facilityImage} />
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.contentContainer}>
          <div className={[styles.leftSide].join(' ')}>
            <h2 className={styles.facilityHeader}>
              <a href={facilityUrl}>{facility.name}</a>
            </h2>

            <div className={styles.address}>
              {searchOptions.widgetType !== FT_PERSONAL_PROFILE &&
                getSnippetAddressSingleLine(facility.city, facility.state, facility.zipCode)}
            </div>
            {(facility.beds !== 0 || facility.baths !== 0) && (
              <div className={styles.totalsContainer}>
                {facility.beds > 0 && (
                  <span className={styles.total}>
                    {facility.beds} <FormattedMessage id="facility.beds" />
                  </span>
                )}
                {facility.baths > 0 && (
                  <>
                    <span className={styles.bullet}>&#8226;</span>
                    <span className={styles.total}>{`${facility.baths} baths`}</span>
                  </>
                )}
              </div>
            )}
          </div>
          <div className={[styles.rightSide].join(' ')}>
            <FacilityStatus
              status={facility.status}
              bedUpdatedAt={facility.bedUpdatedAt}
              bedUpdateNewLine={true}
              widgetType={searchOptions.widgetType}
            />
            {facility.phone && (
              <div className={styles.phone}>
                <Phone className={styles.phoneSvg} />
                <a href={`tel:+${facility.phone}`} rel="noreferrer noopener">
                  {facility.phone}
                </a>
              </div>
            )}
            {facility.website && (
              <div className={styles.website}>
                <OpenLink />
                <a href={facility.website} target="_blank" rel="noreferrer noopener">
                  <FormattedMessage id="facility.view.website" />
                </a>
              </div>
            )}
            {facility.facilityType && ((searchOptions.widgetType === '' || searchOptions.widgetType == undefined)
              && version === 2.1) && (
                <div className={styles.facilityType}>
                  <FormattedMessage id={`facility.type.${facility.facilityType}`} />
                </div>
            )}
            {searchOptions.widgetType === FT_PERSONAL_PROFILE &&
              facility.accreditations.map(item => (
                <div className={styles.accreditationStatusContainer} key={item.id}>
                  {item.status && item.status !== 'UNDEFINED' && (
                    <div>
                      <b>
                        <FormattedMessage id="common.status" />
                      </b>
                      <span>
                        <FormattedMessage id={`accreditation.status.${item.status}`} />
                      </span>
                    </div>
                  )}
                  {item.issueDate && (
                    <div>
                      <b>
                        <FormattedMessage id="common.issueDate" />
                      </b>
                      <span>{getFormattedDate(item.issueDate)}</span>
                    </div>
                  )}

                  {item.expiryDate && (
                    <div>
                      <b>
                        <FormattedMessage id="common.expiryDate" />
                      </b>
                      <span>{getFormattedDate(item.expiryDate)}</span>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.contentContainer}>
          {!emptyDataSecondaryLeft() && (
            <div className={styles.secondaryLeft}>
              {facility.avgCostMinimum > 0 && (
                <div>
                  <FormattedMessage id="facility.accom.from" />
                  <span className={styles.contentPrice}>
                    <FormattedMessage id="facility.accom.from.suffix" values={{ from: facility.avgCostMinimum }} />
                  </span>
                </div>
              )}
              {facility.depositAmount !== null && (
                <div>
                  <span className={styles.contentPrice}>{`$ ${facility.depositAmount} `}</span>
                  <FormattedMessage id="facility.search.upfront" />
                </div>
              )}

              {facility.acceptsInsurance && (
                <div className={[styles.check].join(' ')}>
                  <Check /> <FormattedMessage id="facility.search.insurance" />
                </div>
              )}
              {facility.rentPaidWeekly && (
                <div className={[styles.check].join(' ')}>
                  <Check /> <FormattedMessage id="facility.search.payments" />
                </div>
              )}
            </div>
          )}
          <div className={styles.secondaryRight}>
            {<PopulationIcon facility={facility} />}
            {renderAccreditations(facility)}
            {/*{<Populations facility={facility} formatMessage={formatMessage} />}*/}
          </div>
        </div>

        {/*<div>*/}
        {/*  <a href={facilityUrl} id={facility.id} className={[styles.moreDetails].join(' ')}>{`More details »`}</a>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

FacilityCard.defaultProps = {};

FacilityCard.propTypes = {
  facility: PropTypes.object.isRequired,
  facilityUrl: PropTypes.string.isRequired,
  systemOptions: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(FacilityCard);
