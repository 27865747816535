import React, { Component } from 'react';
import styles from '../FilterModal//filterModal.module.css';

import ModalSection from '../../../common/v2/ModalSection/ModalSection';

import grid from '../../../../styles/bootstrap-grid.module.css';
import PropTypes from 'prop-types';

const ADDITIONAL_SERVICES = [
  {
    name: 'Childcare',
    id: 788,
  },

  {
    name: 'Detoxification (Detox)',
    id: 645,
  },
  {
    name: 'Education support',
    id: 790,
  },
  {
    name: 'Employment support ',
    id: 791,
  },
  {
    name: 'Faith-based program',
    id: 686,
  },
  {
    name: 'Legal services',
    id: 333,
  },
  {
    name: 'Life and social skills',
    id: 408,
  },
  {
    name: 'Faith-based program',
    id: 686,
  },
  {
    name: 'Peer support Services ',
    id: 670,
  },
];
class ServicesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      expanded: false,
      allServices: [],
      servicesToShow: [],
      servicesSelected: [],
    };

    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.handleShowAllServices = this.handleShowAllServices.bind(this);
    this.handleServiceSelected = this.handleServiceSelected.bind(this);
    this.renderServices = this.renderServices.bind(this);
  }

  componentDidMount() {
    const {
      initialServices,
      search: { servicesPrograms = [] },
      search: { services = new Set() },
      handleFiltersOptionsChange,
    } = this.props;

    const allServices = initialServices
      .filter(service => service.categoryId === 45 || service.categoryId === 48 || service.categoryId === 17)
      .map(service => ({
        name: service.name,
        id: service.id,
        priority: service.priority,
      }))
      .concat(ADDITIONAL_SERVICES)
      .sort(function(a, b) {
        return b.priority - a.priority;
      });
    this.setState({
      allServices: allServices,
      servicesToShow: allServices.slice(0, 10),
      servicesSelected: servicesPrograms,
    });

    if (services) {
      const myArr = Array.from(services);

      const items = myArr.filter(element => allServices.find(service => service.id === element));

      if (items.length > 0) {
        handleFiltersOptionsChange('servicesPrograms', { value: items });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      search,
      search: { servicesPrograms = null },
    } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        servicesSelected: servicesPrograms ? servicesPrograms : [],
      });
    }
  }

  handleServiceSelected(e) {
    const { handleFiltersOptionsChange } = this.props;
    const { servicesSelected } = this.state;

    let value = Number(e.target.value);
    let isChecked = e.target.checked;

    if (isChecked) {
      servicesSelected.push(value);
    } else {
      let index = servicesSelected.indexOf(value);
      if (index !== -1) {
        servicesSelected.splice(index, 1);
      }
    }
    this.setState({ servicesSelected: servicesSelected });
    handleFiltersOptionsChange('servicesPrograms', { value: servicesSelected });

    if (servicesSelected.length === 0) {
      handleFiltersOptionsChange('servicesPrograms', { value: null });
    }
  }

  handleShowFilter() {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter,
    }));
  }

  handleShowAllServices() {
    const { allServices, expanded } = this.state;
    this.setState({
      servicesToShow: !expanded ? allServices : allServices.slice(0, 10),
      expanded: !expanded,
    });
  }

  renderServices() {
    const { servicesToShow, expanded, servicesSelected, allServices } = this.state;

    return (
      <>
        <div className={styles.filterItemContainer}>
          {servicesToShow.map((item, index) => (
            <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')} key={index}>
              <div className={styles.filterItem}>
                <label className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name={item.name}
                    value={item.id}
                    onChange={this.handleServiceSelected}
                    checked={servicesSelected.includes(item.id)}
                  />
                  <span className={[styles.checkboxLabel, 'gh-filter-label'].join(' ')}>
                    <span>{item.name} </span>
                  </span>
                </label>
              </div>
            </div>
          ))}
        </div>
        {servicesToShow.length > 9 && (
          <div className={styles.box1}>
            <button className={styles.showMore} onClick={this.handleShowAllServices}>
              {expanded ? 'Show Less >' : 'Show More >'}
            </button>
          </div>
        )}
      </>
    );
  }
  render() {
    const { showFilter } = this.state;

    return (
      <div className={styles.filterContainer}>
        <ModalSection headingLabel="Services & Programs" handleClick={this.handleShowFilter} expanded={showFilter}>
          <div className={grid.container}>{this.renderServices()}</div>
        </ModalSection>
      </div>
    );
  }
}

ServicesFilter.propTypes = {
  initialServices: PropTypes.array.isRequired,
  handleFiltersOptionsChange: PropTypes.func.isRequired,
};
export default ServicesFilter;
