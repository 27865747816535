import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import selectStyles from './SelectStyles';

const RADIUS_LIST = [
  { label: 'Within 5 miles', value: 5 },
  { label: 'Within 10 miles', value: 10 },
  { label: 'Within 25 miles', value: 25 },
  { label: 'Within 50 miles', value: 50 },
  { label: 'Within 100 miles', value: 100 },
  { label: 'Within 150+ miles', value: 150 },
  { label: 'Within the state of', value: 99999 },
];

class FacilitySearchDistance extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
  }

  handleChange(value) {
    const { handleSearchBarChange } = this.props;
    handleSearchBarChange('radius', value);
  }

  getRadius() {
    let {
      search: { radius, province },
    } = this.props;
    if (province) {
      // radius = 9999;
    }
    let selectedRadius = RADIUS_LIST[2];
    if (radius) {
      selectedRadius = RADIUS_LIST.find(item => item.value === parseInt(radius, 10));
    }
    return selectedRadius;
  }

  onMenuOpen() {
    const { showSearchRadius, openSearchRadius } = this.props;

    if (!showSearchRadius) {
      openSearchRadius();
    }
  }

  onMenuClose() {
    const { showSearchRadius, openSearchRadius } = this.props;

    if (showSearchRadius) {
      openSearchRadius();
    }
  }

  render() {
    const { showSearchRadius } = this.props;

    return (
      <Select
        ref={ref => {
          this.select = ref;
        }}
        options={RADIUS_LIST}
        styles={selectStyles()}
        name="radius"
        value={this.getRadius()}
        isSearchable={false}
        className="basic-single"
        onChange={this.handleChange}
        menuIsOpen={showSearchRadius}
        onMenuOpen={this.onMenuOpen}
        onMenuClose={this.onMenuClose}
      />
    );
  }
}

FacilitySearchDistance.propTypes = {
  handleSearchBarChange: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default FacilitySearchDistance;
