// FACILITY TYPES
export const FT_SCHOOL = 'SCHOOL';
export const FT_PERSONAL_PROFILE = 'PERSONAL_PROFILE';

//Legal Exception
export const ARSON = 'ARSON';
export const ASSAULT = 'ASSAULT';
export const BATTERY = 'BATTERY';
export const FELONY_STATUS = 'FELONY_STATUS';
export const SEX_OFFENDER = 'SEX_OFFENDER';
export const VIOLENT_OFFENDER = 'VIOLENT_OFFENDER';
export const HOME_INCARCERATION = 'HOME_INCARCERATION';
export const PAROLE = 'PAROLE';
export const PROBATION = 'PROBATION';

//Recommended Stay
export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';

//Policy & Procedure Documents
export const DISCHARGE_PROCEDURES = 'DISCHARGE_PROCEDURES';
export const EDUCATION = 'EDUCATION';
export const FINANCIAL = 'FINANCIAL';
export const REFUND_BELONGINGS = 'REFUND_BELONGINGS';
export const RELAPSE_OR_RETURN = 'RELAPSE_OR_RETURN';
export const RESIDENT_BEHAVIOUR = 'RESIDENT_BEHAVIOUR';
export const WORK_REQUIREMENTS = 'WORK_REQUIREMENTS';

//Moud Medications
export const BUPRENORPHINE = 'BUPRENORPHINE';
export const SUBUTEX = 'SUBUTEX';
export const SUBOXONE = 'SUBOXONE';
export const NALTREXONE = 'NALTREXONE';
export const VIVITROL = 'VIVITROL';
export const METHADONE = 'METHADONE';

//Moud Policy
export const SELF = 'SELF';
export const PRESCRIBED = 'PRESCRIBED';
export const MANAGED = 'MANAGED';
export const NONE = 'NONE';

// Payment
export const PAY_CASH = 'PAY_CASH';
export const PAY_MEDICAID = 'PAY_MEDICAID';
export const PAY_MEDICARE = 'PAY_MEDICARE';
export const PAY_VOUCHER = 'PAY_VOUCHER';
export const PAY_MILITARY = 'PAY_MILITARY';
export const PAY_MONEY = 'PAY_MONEY';
export const PAY_INSURANCE = 'PAY_INSURANCE';
export const PAY_CHECK = 'PAY_CHECK';
export const PAY_CREDIT = 'PAY_CREDIT';

// smoking & pot
export const PT_MARIJUANA_ALLOWED = 'PT_MARIJUANA_ALLOWED';
export const PT_MARIJUANA_NOT_ALLOWED = 'PT_MARIJUANA_NOT_ALLOWED';
export const PT_MARIJUANA_PRESCRIPTION = 'PT_MARIJUANA_PRESCRIPTION';
export const PT_TOBACCO_ALLOWED = 'PT_TOBACCO_ALLOWED';
export const PT_TOBACCO_ALLOWED_DESIGNATED = 'PT_TOBACCO_ALLOWED_DESIGNATED';
export const PT_TOBACCO_NOT_ALLOWED = 'PT_TOBACCO_NOT_ALLOWED';

// amenities

/*
export const AMEN_AIR_CONDITIONING='';
export const AMEN_ANIMAL_PET='';
export const AMEN_ANIMAL_SERVICE='';
export const AMEN_BABY_BATH='';
export const AMEN_BABY_CHAIR='';
export const AMEN_BABY_CHANGE='';
export const AMEN_BABY_CRIB='';
export const AMEN_BABY_GATE='';
export const AMEN_BABY_MONITOR='';
export const AMEN_BACKYARD='';
export const AMEN_BATH_PRIVATE='';
export const AMEN_BATH_SHARED='';
export const AMEN_BOARD_GAMES='';
export const AMEN_BOOKS='';
export const AMEN_CARBON_ALARM='';
export const AMEN_CHILD_BOOKS='';
export const AMEN_COMPUTERS='';
export const AMEN_DINING='';
export const AMEN_ELEVATOR='';
export const AMEN_EQUIP_EXERCISE='';
export const AMEN_EQUIP_REC='';
export const AMEN_FIRE_PIT='';
export const AMEN_FURNISH_ROOM='';
export const AMEN_GRILL='';
export const AMEN_HEATING='';
export const AMEN_IN_ROOM_TV='';
export const AMEN_KITCHEN_COMMUNAL='';
export const AMEN_KITCHEN_PERSONAL='';
export const AMEN_LAUNDRY='';
export const AMEN_LAUNDRY_NEARBY='';
export const AMEN_LINENS='';
export const AMEN_MEALS='';
export const AMEN_NARCAN='';
export const AMEN_OUTDOOR_DINING='';
export const AMEN_OUTDOOR_FURNITURE='';
export const AMEN_OVERNIGHT_GUEST='';
export const AMEN_PARKING_FREE='';
export const AMEN_PARKING_OFF_PAID='';
export const AMEN_PARKING_ON_PAID='';
export const AMEN_PARKING_STREET='';
export const AMEN_PATIO='';
export const AMEN_POOL='';
export const AMEN_STORAGE='';
export const AMEN_WATERFRONT='';
export const AMEN_WIFI='';
export const AMEN_WORKSPACE='';
 */
