import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';

import configureStore from './configureStore';

import Base from './containers/Base';


import localeData from './locales/en';

const App = (props) => (
  <Provider store={configureStore()}>
    <IntlProvider messages={localeData} locale="en">
      <Base config={props} />
    </IntlProvider>
  </Provider>
);

export default App;
