import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** @jsx jsx */
import { jsx } from '@emotion/core';

import styles from './serviceButton.module.css';


class ServiceButton extends Component {

  static getStyleOverrides(overrides) {
    const styleOverrides = {};

    if(overrides.border) {
      styleOverrides.border = overrides.border;
    }

    return styleOverrides;
  }

  static getStyleSelectedOverrides(overrides) {
    const styleOverrides = {
      ':hover': {},
      '::after': {},
    };

    styleOverrides.border = overrides.border ? overrides.border : 'auto';
    styleOverrides.color = overrides.color ? overrides.color : 'inherit';
    styleOverrides.background = overrides.background ? overrides.background : 'auto';
    styleOverrides[':hover'].border = overrides.border ? overrides.border : 'auto';
    styleOverrides[':hover'].color = overrides.color ? overrides.color : 'auto';
    styleOverrides[':hover'].background = overrides.background ? overrides.background : 'auto';
    styleOverrides['::after'].color = overrides.color ? overrides.color : 'auto';

    return styles;
  }


  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { handleClick, selected, service } = this.props;

    handleClick({service,selected:!selected});
  }

  render() {
    const {
      selected,
      service,
      showCloseIcon,
      styleOverrides: { serviceButton = {} },
      styleOverrides: { serviceButtonSelected = {} },
    } = this.props;
    let overridesCss = this.constructor.getStyleOverrides(serviceButton);
    if(selected) {
      overridesCss= {...this.constructor.getStyleSelectedOverrides(serviceButtonSelected)};
    }
    return (
      <button
        type="button"
        onClick={this.handleClick}
        className={[styles.button,
          `${selected ? styles.selected : ''}`,
          `${selected ? 'gh-service-button-selected ' : 'gh-service-button'}`,
          `${showCloseIcon ? styles.close : ''}`
        ].join(' ')}
        css={overridesCss}
        value={service.id}

      >
        {service.name}
      </button>
    );
  }
}

ServiceButton.defaultProps = {
  selected: false,
  showCloseIcon: false,
  styleOverrides: {}
};

ServiceButton.propTypes = {
  selected:  PropTypes.bool,
  service: PropTypes.object.isRequired,
  showCloseIcon: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  styleOverrides: PropTypes.object,
};


export default ServiceButton;
