exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".facilityHours_hoursContainer__3k-Db {\n  font-size: 12px;\n}\n\n.facilityHours_hoursContainer__3k-Db div {\n  line-height: 1.2;\n}\n\n.facilityHours_name__ElOGX {\n  display: inline-block;\n  margin-left: 20px;\n  vertical-align: top;\n  width: 30px;\n}\n\n.facilityHours_timeContainer__36SPJ {\n  display: inline-block;\n}\n\n.facilityHours_header__-5RZ4 {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["facilityHours.module.css"],"names":[],"mappings":"AAAA;EACE,eAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB","file":"facilityHours.module.css","sourcesContent":[".hoursContainer {\n  font-size: $small;\n}\n\n.hoursContainer div {\n  line-height: 1.2;\n}\n\n.name {\n  display: inline-block;\n  margin-left: 20px;\n  vertical-align: top;\n  width: 30px;\n}\n\n.timeContainer {\n  display: inline-block;\n}\n\n.header {\n  font-weight: bold;\n}\n"]}]);

// Exports
exports.locals = {
	"hoursContainer": "facilityHours_hoursContainer__3k-Db",
	"name": "facilityHours_name__ElOGX",
	"timeContainer": "facilityHours_timeContainer__36SPJ",
	"header": "facilityHours_header__-5RZ4"
};