import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import {FormattedMessage, injectIntl} from 'react-intl';


import styles from './facilityHours.module.css';

const FacilityHours = ({hours}) => {
  return (
    <div className={styles.hoursContainer}>
      <div className={styles.header}><FormattedMessage id="facility.hours"/></div>
      {hours.map(hour => {
        return (
          <div key={uuidv4()}>
            <span className={styles.name}> {hour.name}:</span>
            <div className={styles.timeContainer}>
              {hour.timePeriods.map(timePeriod => {
                return (
                  <div key={uuidv4()}>
                    {`${timePeriod.hourStart}:${timePeriod.minStart === 0 ? '00' : timePeriod.minStart} 
                    ${timePeriod.amPmStart}`}
                    &nbsp;&mdash;&nbsp;
                    {`${timePeriod.hourEnd}:${timePeriod.minEnd === 0 ? '00' : timePeriod.minEnd} 
                    ${timePeriod.amPmEnd}`}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

FacilityHours.defaultProps = {
  hours: [],
};
FacilityHours.propTypes = {
  hours: PropTypes.array,
};

export default injectIntl(FacilityHours);
