import React from 'react';
import { ReactComponent as FlagGood } from '../../images/icons/flag-good.svg';
import { ReactComponent as FlagBad } from '../../images/icons/flag-bad.svg';
import { ReactComponent as FlagMeh } from '../../images/icons/flag-meh.svg';

export const getFlagRatingIcon = rating => {
  if (!rating) {
    return '';
  }

  let flag = <FlagBad />;

  if (rating >= 70) {
    flag = <FlagGood />;
  } else if (rating < 70 && rating >= 40) {
    flag = <FlagMeh />;
  }

  return flag;
};
