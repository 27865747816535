import mediaQuerySelector from '../../utils/mediaQuerySelector';

const desktop = {
  container: styles => ({
    ...styles,
    minHeight:'25px',
    fontSize: '10px',
  }),
  control: styles => ({
    ...styles,
    minHeight: '25px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: 0,
  }),

};

const mobile = {
  container: styles => ({
    ...styles,
    display: 'inline-block',
    width: '88%',
    paddingLeft: '5px',
  }),
  control: styles => ({
    ...styles,
    border: 0,
    paddingRight: '5px',
    borderRadius: '5px 0 0 5px',
    paddingLeft: 0,
    minHeight: '30px',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: 0,
    border: 0,
    height: '36px',
  }),
  menuList: styles => ({
    ...styles,
    border: 0,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
    marginTop: '8px!important',
  }),

  placeholder: styles => ({
    ...styles,
    top: '18px',
    fontWeight: 'normal',
  }),
};

const selectBox = () => {
  return mediaQuerySelector() === 'xs' ? mobile : desktop;
};

export default selectBox;
