import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getFacilityTypeMessage, getFormattedDate } from '../../../../utils/utils';

import styles from './facilityStatus.module.css';

const STATUS_MAPPING = {
  1: 'Available',
  2: 'Unavailable',
  3: 'Call',
};

const FacilityStatus = ({ status, bedUpdatedAt, bedUpdateNewLine, widgetType = '' }) => (
  <>
    {status > 0 && (
      <div className={[styles.callout, 'facility-status'].join(' ')}>
        <div
          className={[
            styles.availabilityBubble,
            styles[`status${STATUS_MAPPING[status]}`],
            `gh-facility-status-${STATUS_MAPPING[status].toLowerCase()}`,
          ].join(' ')}
        ></div>
        <span className={[styles.subheader]}>
          {getFacilityTypeMessage(`facility.status.${STATUS_MAPPING[status]}`, widgetType)}
        </span>
        {bedUpdatedAt && (
          <span className={[styles.contentFaded, bedUpdateNewLine ? styles.block : ''].join(' ')}>
            <FormattedMessage id={`facility.status.availabilityDate`} />
            {getFormattedDate(bedUpdatedAt)})
          </span>
        )}
      </div>
    )}
  </>
);

FacilityStatus.defaultProps = {
  bedUpdateNewLine: false,
  bedUpdatedAt: null,
};

FacilityStatus.propTypes = {
  status: PropTypes.number.isRequired,
  bedUpdatedAt: PropTypes.string,
  bedUpdateNewLine: PropTypes.bool,
};

export default FacilityStatus;
