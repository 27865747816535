import React from 'react';

import FacilityLevelOfCare from './FacilityLevelOfCare';
import FacilityInsurance from './FacilityInsurance';
import FacilityOverview from './FacilityOverview';
import FacilityGallery from './FacilityGallery';

import grid from '../../styles/bootstrap-grid.module.css';
import FacilityAdditionalContactInfo from "./FacilityAdditionalContactInfo";

const FacilityContent = () => (
  <div className={[grid.col, 'gh-facility-content'].join(' ')}>
     <FacilityGallery/>
    <FacilityLevelOfCare />
    <FacilityOverview />
    <FacilityInsurance />
    <FacilityAdditionalContactInfo />
  </div>
);

export default FacilityContent;
