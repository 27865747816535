import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';
import { getFacilityTypeMessage } from '../../../utils/utils';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';

class FacilityRent extends PureComponent {
  renderEmptyData() {
    const {
      facility: { depositRequired, financialAssistance, depositDue, applicationFeeRequired, rentPaidWeekly },
    } = this.props;

    return (
      depositRequired === null &&
      financialAssistance === null &&
      depositDue === null &&
      applicationFeeRequired === null &&
      rentPaidWeekly === null
    );
  }

  renderRentList() {
    const {
      facility: {
        depositRequired,
        financialAssistance,
        depositDue,
        applicationFeeRequired,
        rentPaidWeekly,
        depositAmount,
        additionalFeeAmount,
        applicationFeeAmount,
        additionalFeesRequired,
        feesAssociatedWithServices,
        feesAssociatedWithServicesDetails,
      },
      facility,
      widgetType,
    } = this.props;

    return (
      <>
        <div className={[grid.row, grid.rowBottom].join(' ')}>
          {depositRequired && (
            <div className={[grid.colSm6, styles.check].join(' ')}>
              <Check /> <FormattedMessage id="facility.accom.deposit" />
            </div>
          )}
          {financialAssistance && (
            <div className={[grid.colSm6, styles.check].join(' ')}>
              <Check /> <FormattedMessage id="facility.accom.financial" />
            </div>
          )}
          {depositDue && (
            <div className={[grid.colSm6, styles.check].join(' ')}>
              <Check /> <FormattedMessage id="facility.accom.deposit.flex" />
            </div>
          )}
          {applicationFeeRequired && (
            <div className={[grid.colSm6, styles.check].join(' ')}>
              <Check /> <FormattedMessage id="facility.accom.application.fee" />
            </div>
          )}
          {rentPaidWeekly && (
            <div className={[grid.colSm6, styles.check].join(' ')}>
              <Check />
              <FormattedMessage id="facility.accom.weekly" />
            </div>
          )}
        </div>

        {feesAssociatedWithServices && (
          <div className={[grid.row, grid.rowBottom].join(' ')}>
            <div className={[grid.col].join(' ')}>
              <b>
                {getFacilityTypeMessage('facility.fee.servicesDetails',widgetType)}
              </b>
              {feesAssociatedWithServicesDetails}
            </div>
          </div>
        )}

        {(depositRequired || additionalFeesRequired || applicationFeeRequired) && (
          <>
            <div className={grid.row}>
              <div className={[grid.col].join(' ')}>
                <div className={styles.totalCostContainer}>
                  <h3 className={styles.subheader}>
                    <FormattedMessage id="facility.accom.cost" />
                  </h3>
                  {depositRequired && depositAmount && (
                    <div>
                      <b>
                        <FormattedMessage id="facility.accom.cost.depositAmount" values={{ cost: depositAmount }} />
                      </b>
                      <FormattedNumber value={depositAmount} style="currency" currency="USD" />
                    </div>
                  )}
                  {additionalFeesRequired && additionalFeeAmount && (
                    <div>
                      <b>
                        <FormattedMessage id="facility.accom.cost.additionalFeeAmount" />
                      </b>
                      <FormattedNumber value={additionalFeeAmount} style="currency" currency="USD" />
                    </div>
                  )}
                  {applicationFeeRequired && applicationFeeAmount && (
                    <div>
                      <b>
                        <FormattedMessage id="facility.accom.cost.applicationFeeAmount" />
                      </b>
                      <FormattedNumber value={applicationFeeAmount} style="currency" currency="USD" />
                    </div>
                  )}
                </div>
              </div>
              <div className={[grid.colMd4].join(' ')}></div>
            </div>
            <div className={grid.row}>
              <div className={[grid.col].join(' ')}>
                <b>
                  <FormattedMessage id="facility.accom.cost.totalAmount" />
                </b>
                <FormattedNumber
                  value={depositAmount + additionalFeeAmount + applicationFeeAmount}
                  style="currency"
                  currency="USD"
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  render() {
    return (
      !this.renderEmptyData() && (
        <>
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}>
                <FormattedMessage id="facility.header.rent" />
              </h2>
            </div>
          </div>

          <div className={styles.box1}>
            <div>{this.renderRentList()}</div>
          </div>
        </>
      )
    );
  }
}

FacilityRent.defaultProps = {
  widgetType: '',
};

FacilityRent.propTypes = {
  facility: PropTypes.object.isRequired,
  widgetType: PropTypes.string,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityRent);
