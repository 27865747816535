export const POP_VETERANS = 'POP_VETERANS';
export const POP_FEMALE_ONLY = 'POP_FEMALE_ONLY';
export const POP_MALE_ONLY = 'POP_MALE_ONLY';
export const POP_COED = 'POP_COED';
export const POP_PREGNANCY = 'POP_PREGNANCY';
export const POP_TRANS_MAN = 'POP_TRANS_MAN';
export const POP_TRANS_WOMEN = 'POP_TRANS_WOMEN';
export const POP_CHILDREN_MEN = 'POP_CHILDREN_MEN';
export const POP_CHILDREN_WOMEN = 'POP_CHILDREN_WOMEN';
export const POP_COUPLE = 'POP_COUPLE';
export const POP_CHILDREN_COUPLE = 'POP_CHILDREN_COUPLE';
export const POP_LGBTQ = 'POP_LGBTQ';
export const POP_INTERSEX = 'POP_INTERSEX';
export const POP_NON_BINARY = 'POP_NON_BINARY';
export const POP_DEAF = 'POP_DEAF';
export const POP_BLIND = 'POP_BLIND';
export const POP_HOMELESS = 'POP_HOMELESS';
export const POP_INDIGENOUS = 'POP_INDIGENOUS';
export const POP_RENTRY = 'POP_RENTRY';
export const POP_JUSTICE = 'POP_JUSTICE';
export const POP_UNDER_18_FEMALE = 'POP_UNDER_18_FEMALE';
export const POP_UNDER_18_MALE = 'POP_UNDER_18_MALE';
export const POP_COURT_INVOLVEMENT = 'POP_COURT_INVOLVEMENT';
export const POP_MALE_ADULT = 'POP_MALE_ADULT';
export const POP_FEMALE_ADULT = 'POP_FEMALE_ADULT';
export const POP_OCCURRING_MENTAL_HEALTH_DISORDER = 'CO-OCCURRING_MENTAL_HEALTH_DISORDER';

export const POPULATION_ICONS = {
  POP_FEMALE_ADULT: 'women.svg',
  POP_PREGNANCY: 'pregnant.svg',
  POP_UNDER_18_FEMALE: 'under18.svg',
  POP_CHILDREN_WOMEN: 'woman-child.svg',

  POP_MALE_ADULT: 'man.svg',
  POP_UNDER_18_MALE: 'under18.svg',
  POP_CHILDREN_MEN: 'man-child.svg',

  POP_COUPLE: 'people.svg',
  POP_CHILDREN_COUPLE: 'family-bold.svg',

  POP_LGBTQ: 'lgbtq.svg',
  POP_INTERSEX: 'lgbtq.svg',
  POP_NON_BINARY: 'lgbtq.svg',
  POP_TRANS_MAN: 'trans-inclusive.svg',
  POP_TRANS_WOMEN: 'trans-inclusive.svg',

  // DERIVED ITEMS
  POP_FEMALE_ONLY: 'women.svg',
  POP_MALE_ONLY: 'man.svg',
  POP_COED: 'people.svg',

  POP_VETERANS: 'veterans.svg', // Veterans
  POP_DEAF: 'deaf.svg',
  POP_BLIND: 'blind.svg',
  POP_HOMELESS: 'bed.svg',
  POP_INDIGENOUS: 'location.svg',
  POP_RENTRY: 'gavel.svg',
  POP_JUSTICE: 'gavel.svg',
  POP_COURT_INVOLVEMENT: 'gavel.svg',
  POP_OCCURRING_MENTAL_HEALTH_DISORDER: 'co-occurring-disorder.png',

  NEUTRAL: 'gender-neutral.svg',
  WOMEN_ONLY: 'women.svg',
  MEN_ONLY: 'man.svg',
};

export const POPULATION_LIST = [
  { label: 'Women Only', value: 272 },
  { label: 'Men Only ', value: 273 },
  { label: 'Gender Neutral', value: 297 },
  { label: 'Transgender Man', value: 692 },
  { label: 'Transgender Woman', value: 693 },
  { label: 'Men w/ children', value: 713 },
  { label: 'Women w/ children', value: 714 },
  { label: 'Couples w/o children', value: 715 },
  { label: 'Couples w/ children ', value: 716 },
  { label: 'LGBTQ+', value: 717 },
  { label: 'Intersex', value: 718 },
  { label: 'Non-binary', value: 719 },
  { label: 'Adult Men', value: 803 },
  { label: 'Adult Women', value: 804 },
];

export const SCHOOL_POPULATION_LIST = [
  { label: 'Online school or Provider', value: 687 },
  { label: 'Live school or Provider', value: 662 },
];
