import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../selectors/facility';

import styles from './facility.module.css';
import grid from '../../styles/bootstrap-grid.module.css';

class FacilityInsurance extends PureComponent {
  static renderInsurance(insurances = [], network) {
    if (!insurances.length) {
      return '';
    }
    return (
      <Fragment>
        <div className={styles.insuranceSubHeader}>
          <FormattedMessage id={network} />
        </div>
        {insurances.map(insurance => (
          <div key={uuidv4()}>{insurance.name}</div>
        ))}
      </Fragment>
    );
  }

  render() {
    const { facility: {insuranceInNetwork, insuranceOutOfNetwork} } = this.props;

    return (
      <Fragment>
        {(insuranceInNetwork.length > 0 || insuranceOutOfNetwork.length > 0) && (
          <div className={styles.container}>
            <div className={styles.sectionHeader}>
              <FormattedMessage id="facility.insurance" />
            </div>
            <div className={[grid.row, grid.noGutters].join(' ')}>
              {insuranceInNetwork.length > 0 && (
                <div className={[grid.colSm6, grid.noGuttersLeft].join(' ')}>
                  {this.constructor.renderInsurance(insuranceInNetwork, 'facility.in_network')}
                </div>
              )}
              {insuranceOutOfNetwork.length > 0 && (
                <div className={[grid.col, grid.colSm6, grid.noGuttersLeft].join(' ')}>
                  {this.constructor.renderInsurance(insuranceOutOfNetwork, 'facility.out_of_network')}
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
FacilityInsurance.propTypes = {
  facility: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityInsurance);
