
export const facilityFeatures = {
  shelter: [
    'gender'
  ],
  recoveryTreatment: [
    'gender',
    'MAT',
    'financeSection',
    'otherDetails'
  ],
  generalResource: [

  ]
};