import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Menu, { MenuItem, SubMenu } from 'rc-menu';
import PropTypes from 'prop-types';

import { ReactComponent as Arrow } from '../../../../images/icons/v2/down-arrow.svg';
import { ReactComponent as X } from '../../../../images/icons/v2/x.svg';

import '../../../../styles/general.css';
import styles from './facilitySearchBar.module.css';
import { PAYMENT_TYPES_LIST } from '../../../../constants/filteredSearchOptions';

class BarPaymentType extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      paymentSelected: {},
      expanded: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleExpanded = this.handleExpanded.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      search,
      search: { paymentType = {} },
    } = this.props;

    if (prevProps.search !== search) {
      let item = PAYMENT_TYPES_LIST.find(element => element.value === paymentType);
      this.setState({
        paymentSelected: item ? item : {},
      });
    }
  }

  handleSelect(info) {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;

    let item = PAYMENT_TYPES_LIST.find(element => element.label === info.key);

    this.setState({
      paymentSelected: item ? item : {},
    });

    if (item) {
      handleFiltersOptionsChange('paymentType', item);
      fetchFilteredSearch();
    }
  }

  handleClear() {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;
    this.setState({
      paymentSelected: {},
    });
    handleFiltersOptionsChange('paymentType', { value: null });
    fetchFilteredSearch();
  }

  handleExpanded() {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  render() {
    const { paymentSelected, expanded } = this.state;

    return (
      <>
        <Menu
          mode="horizontal"
          overflowedIndicator=""
          triggerSubMenuAction="click"
          onClick={this.handleSelect}
          onOpenChange={this.handleExpanded}
        >
          <SubMenu
            title={
              <div className={styles.paymentTypeContainer}>
                <span className="submenu-title-wrapper">
                  {paymentSelected.label ? (
                    <span>{paymentSelected.label}</span>
                  ) : (
                    <FormattedMessage id="search.bar.payment.type" />
                  )}
                </span>
                {paymentSelected.label && <X onClick={this.handleClear} className={styles.removeIcon} />}
                <Arrow className={expanded ? styles.upArrow : styles.downArrow} />
              </div>
            }
            key="paymentTypeKey"
          >
            {PAYMENT_TYPES_LIST.map(payment => (
              <MenuItem key={payment.label} className={styles.filterOption}>
                <span>{payment.label}</span>
              </MenuItem>
            ))}
          </SubMenu>
        </Menu>
      </>
    );
  }
}

BarPaymentType.propTypes = {
  handleFiltersOptionsChange: PropTypes.func.isRequired,
  fetchFilteredSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default injectIntl(BarPaymentType);
