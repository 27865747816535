import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import { replaceNewLine } from '../../../utils/utils';

class FacilityDescription extends PureComponent {

  render() {
    const { facility } = this.props;
    return (
      <>
        {((facility.description && facility.description.length > 0) ||
          (facility.abstinenceDefinition && facility.abstinenceDefinition.length > 0)) && (
          <div className={[styles.box1].join(' ')}>
            {facility.description && facility.description.length > 0 && (
              <div className={[grid.row].join(' ')}>
                <div
                  className={[grid.col, styles.content].join(' ')}
                  dangerouslySetInnerHTML={{ __html: replaceNewLine(facility.description) }}
                />
              </div>
            )}
            {facility.abstinenceDefinition && facility.abstinenceDefinition.length > 0 && (
              <div className={[grid.row, styles.abstinence].join(' ')}>
                <div className={[grid.col, styles.content].join(' ')}>
                  <b>
                    <FormattedMessage id="facility.label.abstinencedefinition" />
                  </b>{' '}
                  {facility.abstinenceDefinition}
                </div>
              </div>
            )}
            {facility.structureType && facility.structureType.length > 0 && facility.structureType !== 'OTHER' && (
              <div className={[grid.row, styles.abstinence].join(' ')}>
                <div className={[grid.col, styles.content].join(' ')}>
                  <b>
                    <FormattedMessage id="facility.label.structureType" />
                  </b>
                  <div>
                    <FormattedMessage id={`facility.structureType.${facility.structureType}`} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {facility.structureType && facility.structureType.length > 0 && facility.structureType !== 'OTHER' && (
          <div className={[grid.row, styles.abstinence].join(' ')}>
            <div className={[grid.col, styles.content].join(' ')}>
              <b>
                <FormattedMessage id="facility.label.structureType" />
              </b>
              <div>
                <FormattedMessage id={`facility.structureType.${facility.structureType}`} />
              </div>
            </div>
          </div>
        )}

        {facility.allowWaitList && (
          <div className={[grid.row, styles.abstinence].join(' ')}>
            <div className={[grid.col, styles.content].join(' ')}>
              <b>
                <FormattedMessage id="facility.allowWaitlist" />
              </b>
              <div>
                <FormattedMessage id="facility.allowWaitlist.description" />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

FacilityDescription.defaultProps = {};

FacilityDescription.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityDescription);
