import dayjs from 'dayjs';
import {createIntl, createIntlCache} from 'react-intl';

import localeData from '../locales/en';

const cache = createIntlCache();
const intl = createIntl({ locale: 'en', messages: localeData }, cache);


export const getAvailabilityType = beds => {
  if (!Number.isInteger(beds)) {
    return '';
  }

  return beds > 0 ? 'available' : 'unavailable';
};

export const pinType = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  CALL: 'CALL',
};

export const getI18nString = (type = '', suffix) => {
  if (type.length) {
    return `${type}.${suffix}`;
  }
  return suffix;
};
export const getFacilityTypeMessage = (defaultMessage, widgetType) => {
  const defaultMsg = intl.formatMessage({id:defaultMessage});
  return intl.formatMessage({id:`${defaultMessage}.${widgetType}`, defaultMessage:defaultMsg});
};

export const getFacilityStatusType = status => {
  let statusType = '';
  switch (status) {
    case 1:
      statusType = pinType.AVAILABLE;
      break;
    case 2:
      statusType = pinType.UNAVAILABLE;
      break;
    case 3:
      statusType = pinType.CALL;
      break;
    default:
      break;
  }

  return statusType;
};

export const truncate = (string = '', length = 0, addEllipsis = true) => {
  if (!length || string.length < length) {
    return string;
  }

  return `${string.substring(0, length)}${addEllipsis ? '...' : ''}`;
};

export const setToString = set => {
  const setArray = [];

  set.forEach(item => setArray.push(item));

  return setArray.join(',');
};

export const replaceNewLine = text => {
  return text.replace(new RegExp(/(\\r\\n|\\r|\\n)|(\r\n|\r|\n)/g), '<br/>');
};

export function getCurrentPosition() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: 1000 });
  });
}

export const getFormattedDate = (date = new Date()) => {
  return dayjs(date).format('MM/DD/YYYY');
};
