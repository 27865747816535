import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../selectors/facility';

import styles from './facility.module.css';

class FacilityService extends PureComponent {

  static renderServiceList(serviceCategories = []) {
    return (
      <Fragment>
        {serviceCategories.map(serviceCategory => {
          return (
            <div key={uuidv4()}>
              <div className={styles.service}>{serviceCategory.name}</div>
              {serviceCategory.services.map(service => (
                <div className={styles.serviceService} key={uuidv4()}>
                  {service.name}
                </div>
              ))}
            </div>
          );
        })}
      </Fragment>
    );
  }

  render() {
    const { facility : {serviceCategories }} = this.props;

    return (
      <>
        {serviceCategories && (
          <div className={[styles.serviceContainer, 'gh-facility-service-section'].join(' ')}>
            <div className={styles.sectionHeader}>
              <FormattedMessage id="facility.service" />
            </div>
            {this.constructor.renderServiceList(serviceCategories)}
          </div>
        )}
      </>
    );
  }
}
FacilityService.propTypes = {
  facility: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityService);
