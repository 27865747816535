import { request } from './BaseRequest';

import { setToString } from '../utils/utils';

export const fetchFacilities = ({ args = {}, version, widgetType }) => {
  const queryArgs = Object.assign({}, args);
  const apiVersion = version > 1 ? 'v2' : 'v1';

  if (queryArgs.services && queryArgs.services instanceof Set && queryArgs.services.size > 0) {
    queryArgs.services = setToString(queryArgs.services);
  } else if (queryArgs.services && !queryArgs.services.length) {
    delete queryArgs.services;
  }

  if (queryArgs.accreditations && queryArgs.accreditations instanceof Set && queryArgs.accreditations.size > 0) {
    queryArgs.accreditations = setToString(queryArgs.accreditations);
  } else if (queryArgs.accreditations && !queryArgs.accreditations.length) {
    delete queryArgs.accreditations;
  }
  queryArgs.facilityType = widgetType;

  if (args.facilityType) {
    queryArgs.facilityType = args.facilityType;
  }

  if (args.financialAssistance) {
    queryArgs.financialAssistance = args.financialAssistance;
  }

  return request({
    url: `/${apiVersion}/facilities`,
    args: queryArgs,
  }).then(async (response = {}) => {
    if (response.status === 200) {
      const data = await response.json();
      return {
        facilitiesTotalCount: data.totalElements,
        pageSize: data.size,
        facilities: data.content,
      };
    }
    const data = await response.json();
    throw new Error(data.details);
  });
};
