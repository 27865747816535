import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { FormattedMessage } from 'react-intl';


import styles from './facility.module.css';
import {getFacilitySelector} from "../../selectors/facility";
import {connect} from "react-redux";

import { ReactComponent as Phone } from '../../images/icons/phone.svg';
import { ReactComponent as Web } from '../../images/icons/web.svg';
import {truncate} from "../../utils/utils";

class FacilityAdditionalContactInfo extends PureComponent {

  render() {
    const { facility } = this.props;

    if(!facility.premium ||
      !(facility.tollFreePhone || facility.additionalWebsite || facility.admissionsEmail || facility.intakePhone )) {
      return null;
    }
    return (
      <div className={styles.container}>
        <div className={styles.sectionHeader}>
          <FormattedMessage id="facility.additional_contact" />
        </div>
        {facility.intakePhone && (
          <div >
          <span  className={styles.additionalContactSubHeader}>
            <FormattedMessage id="facility.additional.intake_number" />:
          </span>
            &nbsp; <Phone className={styles.phoneSvg} />
            <a href={`tel:+${facility.intakePhone}`} className={styles.phone}>{facility.intakePhone}</a>
          </div>
        )}
        {facility.tollFreePhone && (
          <div>
            <span  className={styles.additionalContactSubHeader}>
              <FormattedMessage id="facility.additional.toll_free_number" />:
            </span>
            &nbsp; <Phone className={styles.phoneSvg} />
            <a href={`tel:+${facility.tollFreePhone}`} className={styles.phone}>{facility.tollFreePhone}</a>
          </div>
        )}
        {facility.admissionsEmail && (
          <div>
            <span  className={styles.additionalContactSubHeader}>
              <FormattedMessage id="facility.additional.email" />:
            </span>
            &nbsp; <Web className={styles.websiteSvg} />
            <a href={`mailto:${facility.admissionsEmail}`} className={styles.website} >{facility.admissionsEmail}</a>
          </div>
        )}
        {facility.additionalWebsite && (
          <div>
            <span  className={styles.additionalContactSubHeader}>
              <FormattedMessage id="facility.additional.website" />:
            </span>
            &nbsp; <Web className={styles.websiteSvg} />
            <a href={facility.additionalWebsite} target="_blank" className={styles.website} >
              {truncate(facility.additionalWebsite, 60)}
            </a>
          </div>
        )}


      </div>
    );
  }
}

FacilityAdditionalContactInfo.propTypes = {
  facility: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(FacilityAdditionalContactInfo);
