import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

import ModalSection from '../../../common/v2/ModalSection/ModalSection';
import FacilitySearchPopulation from '../FacilitySearch/FacilitySearchPopulation';
import selectStyles from '../FacilitySearch/SelectStyles';

import grid from '../../../../styles/bootstrap-grid.module.css';
import styles from '../FilterModal//filterModal.module.css';
import { RESIDENT_CHARACTERISTICS_LIST } from '../../../../constants/filteredSearchOptions';
import {getFacilityFeatures} from "../../../../constants/facilityType";

const LEGAL_EXCEPTIONS_LIST = [
  { label: 'Arson', value: 'ARSON' },
  { label: 'Assault', value: 'ASSAULT' },
  { label: 'Battery', value: 'BATTERY' },
  { label: 'Felony status', value: 'FELONY_STATUS' },
  { label: 'Sex offender', value: 'SEX_OFFENDER' },
  { label: 'Violent offender', value: 'VIOLENT_OFFENDER' },
  { label: 'Home incarceration', value: 'HOME_INCARCERATION' },
  { label: 'Parole', value: 'PAROLE' },
  { label: 'Probation', value: 'PROBATION' },
];

const MEDICATIONS_LIST = [
  { label: 'Buprenorphine', value: 'BUPRENORPHINE' },
  { label: 'Subutex', value: 'SUBUTEX' },
  { label: 'Suboxone', value: 'SUBOXONE' },
  { label: 'Naltrexone', value: 'NALTREXONE' },
  { label: 'Vivitrol', value: 'VIVITROL' },
  { label: 'Methadone', value: 'METHADONE' },
];

class DemographicFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      ReceivingMATSelected: false,
      facilityFeatures: ''
    };

    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.handleReceivingMAT = this.handleReceivingMAT.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;
    const facilityFeatures = getFacilityFeatures(search.facilityType);
    this.setState({
      ReceivingMATSelected: search.hasOwnProperty('receivingMAT'),
      facilityFeatures: facilityFeatures
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleFiltersOptionsChange, search } = this.props;
    const { ReceivingMATSelected } = this.state;

    if (prevState.ReceivingMATSelected !== ReceivingMATSelected) {
      if (ReceivingMATSelected) {
        handleFiltersOptionsChange('receivingMAT', { value: 144 });
      } else {
        handleFiltersOptionsChange('receivingMAT', { value: null });
        handleFiltersOptionsChange('moud', { value: null });
      }
    }

    if (prevProps.search !== search) {
      this.setState({
        ReceivingMATSelected: search.hasOwnProperty('receivingMAT'),
      });
    }
  }

  handleShowFilter() {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter,
    }));
  }

  handleReceivingMAT(e) {
    let isChecked = e.target.checked;

    this.setState({
      ReceivingMATSelected: isChecked,
    });
  }

  render() {
    const { showFilter, ReceivingMATSelected, facilityFeatures } = this.state;
    const { handleFiltersOptionsChange, initialServices, search } = this.props;

    const languages = initialServices
      .filter(service => service.categoryId === 24)
      .map(service => ({
        label: service.name,
        value: service.id,
      }));

    return (
      <div className={styles.filterContainer}>
        <ModalSection headingLabel="Demographics" handleClick={this.handleShowFilter} expanded={showFilter}>
          <div className={grid.container}>
            <div className={styles.filterItemContainer}>
              {(facilityFeatures === undefined || facilityFeatures.includes('gender')) &&
                <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                  <div className={styles.filterItem}>
                    <div className={styles.filterItemLabel}>
                      <span className="gh-filter-label">
                        <FormattedMessage id="search.modal.gender"/>
                      </span>
                    </div>
                    <div className={styles.filterItemInput}>
                      <FacilitySearchPopulation search={search} handleSearchBarChange={handleFiltersOptionsChange}/>
                    </div>
                  </div>
                </div>
              }
              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <div className={styles.filterItemLabel}>
                    <span className="gh-filter-label">
                      <FormattedMessage id="search.modal.languages"/>
                    </span>
                  </div>
                  <div className={styles.filterItemInput}>
                    <LanguagesSpoken
                      handleFiltersOptionsChange={handleFiltersOptionsChange}
                      languages={languages}
                      search={search}
                    />
                  </div>
                </div>
              </div>

              {/*<div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>*/}
              {/*  <div className={styles.filterItem}>*/}
              {/*    <div className={styles.filterItemLabel}>*/}
              {/*      <span className="gh-filter-label">*/}
              {/*        <FormattedMessage id="search.modal.correctionalBackground" />*/}
              {/*      </span>*/}
              {/*    </div>*/}
              {/*    <div className={styles.filterItemInput}>*/}
              {/*      <CorrectionalBackground
                          handleFiltersOptionsChange={handleFiltersOptionsChange}
                          search={search} />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <div className={styles.filterItemLabel}>
                    <span className="gh-filter-label">
                      <FormattedMessage id="search.modal.residentCharacteristics"/>
                    </span>
                  </div>
                  <div className={styles.filterItemInput}>
                    <ResidentCharacteristic handleFiltersOptionsChange={handleFiltersOptionsChange} search={search}/>
                  </div>
                </div>
              </div>

              {(facilityFeatures === undefined || facilityFeatures.includes('MAT')) &&
                <div className={[grid.colSm5, grid.noGuttersBoth, styles.itemMAT].join(' ')}>
                  <div className={styles.filterItem}>
                    <label className={styles.checkboxContainer}>
                      <input
                        type="checkbox"
                        name="receivingMAT"
                        onChange={this.handleReceivingMAT}
                        checked={ReceivingMATSelected}
                      />
                      <span className={[styles.checkboxLabel, 'gh-filter-label'].join(' ')}>
                        <FormattedMessage id="search.modal.receivingMAT"/>
                      </span>
                    </label>
                  </div>
                  {ReceivingMATSelected && (
                    <Medications handleFiltersOptionsChange={handleFiltersOptionsChange} search={search}/>
                  )}
                </div>
              }

            </div>
          </div>
        </ModalSection>
      </div>
    );
  }
}

DemographicFilter.propTypes = {
  initialServices: PropTypes.array.isRequired,
  handleFiltersOptionsChange: PropTypes.func.isRequired,
};

export default DemographicFilter;

class ListOption extends Component {
  constructor(props) {
    super(props);
  }

  getOptionStyles = defaultStyles => ({
    ...defaultStyles,
  });

  render() {
    const { data, getStyles, innerRef, innerProps, isSelected } = this.props;
    const defaultStyles = getStyles('option', this.props);
    const stylesOption = this.getOptionStyles(defaultStyles);

    stylesOption.display = 'flex';
    stylesOption.alignItems = 'center';

    return (
      <div {...innerProps} ref={innerRef} style={stylesOption}>
        <input type="checkbox" checked={isSelected} readOnly className={styles.checkboxLineItem} />
        <span className={styles.checkboxLabel}>{data.label}</span>
      </div>
    );
  }
}

class CorrectionalBackground extends Component {
  static getSelectedItem(value, list) {
    return list.find(item => item.value === parseInt(value, 10));
  }

  constructor(props) {
    super(props);
    this.state = {
      values: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {
      search: { legalExceptions = [] },
    } = this.props;

    this.setState({
      values: LEGAL_EXCEPTIONS_LIST.filter(item => legalExceptions.includes(item.value)),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        values: search.legalExceptions
          ? LEGAL_EXCEPTIONS_LIST.filter(item => search.legalExceptions.includes(item.value))
          : [],
      });
    }
  }

  handleChange(value) {
    const { handleFiltersOptionsChange } = this.props;
    handleFiltersOptionsChange('legalExceptions', { value });
    this.setState({
      values: value,
    });
  }

  render() {
    const customComponents = {
      Option: ListOption,
    };
    const { values } = this.state;
    return (
      <Select
        ref={ref => {
          this.select = ref;
        }}
        options={LEGAL_EXCEPTIONS_LIST}
        name="correctionalbackground"
        styles={selectStyles()}
        isSearchable={false}
        className="basic-single"
        onChange={this.handleChange}
        closeMenuOnSelect={false}
        isMulti={true}
        hideSelectedOptions={false}
        components={customComponents}
        value={values}
      />
    );
  }
}

class ResidentCharacteristic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {
      search: { residentCharacteristics = [] },
    } = this.props;

    this.setState({
      values: RESIDENT_CHARACTERISTICS_LIST.filter(item => residentCharacteristics.includes(item.value)),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        values: search.residentCharacteristics
          ? RESIDENT_CHARACTERISTICS_LIST.filter(item => search.residentCharacteristics.includes(item.value))
          : [],
      });
    }
  }

  handleChange(value) {
    const { handleFiltersOptionsChange } = this.props;
    handleFiltersOptionsChange('residentCharacteristics', { value });

    this.setState({
      values: value,
    });
  }

  render() {
    const customComponents = {
      Option: ListOption,
    };
    const { values } = this.state;

    return (
      <Select
        ref={ref => {
          this.select = ref;
        }}
        options={RESIDENT_CHARACTERISTICS_LIST}
        name="residentCharacteristics"
        styles={selectStyles()}
        isSearchable={false}
        className="basic-single"
        onChange={this.handleChange}
        closeMenuOnSelect={false}
        isMulti={true}
        hideSelectedOptions={false}
        components={customComponents}
        value={values}
      />
    );
  }
}

class LanguagesSpoken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    const {
      search,
      search: { services = new Set() },
      languages,
      handleFiltersOptionsChange,
    } = this.props;

    if (services.size > 0) {
      let item = {};
      for (const service of services) {
        languages.find(element => {
          if (element.value === service) {
            item = element;
          }
        });
      }

      if (item) {
        handleFiltersOptionsChange('language', item);
      }
    }
  }
  componentDidUpdate(prevProps) {
    const { search, languages } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        values: languages.find(item => item.value === search.language),
      });
    }
  }

  handleChange(value) {
    const { handleFiltersOptionsChange } = this.props;
    handleFiltersOptionsChange('language', value);

    if (value === null) {
      handleFiltersOptionsChange('language', { value: null });
    }
  }

  render() {
    const { languages } = this.props;
    const { values } = this.state;

    return (
      <Select
        ref={ref => {
          this.select = ref;
        }}
        options={languages}
        name="language"
        styles={selectStyles()}
        isSearchable={false}
        className="basic-single"
        onChange={this.handleChange}
        value={values}
        isClearable={true}
      />
    );
  }
}

class Medications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {
      search: { moud = '' },
    } = this.props;

    this.setState({
      values: MEDICATIONS_LIST.find(item => item.value === moud),
    });
  }

  componentDidUpdate(prevProps) {
    const {
      search: { moud = '' },
      search,
    } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        values: MEDICATIONS_LIST.find(item => item.value === moud),
      });
    }
  }

  handleChange(value) {
    const { handleFiltersOptionsChange } = this.props;
    handleFiltersOptionsChange('moud', value);

    if (value === null) {
      handleFiltersOptionsChange('moud', { value: null });
    }
  }

  render() {
    const { values } = this.state;

    return (
      <Select
        ref={ref => {
          this.select = ref;
        }}
        options={MEDICATIONS_LIST}
        name="moud"
        styles={selectStyles()}
        isSearchable={false}
        className="basic-single"
        onChange={this.handleChange}
        value={values}
        isClearable={true}
        placeholder="Any medication"
      />
    );
  }
}
