import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';




const ImageResizedForHeight = ({src, ratio}) => {
  const [height, setHeight] = useState(0);

  const onImageLoad = (e) => {
    const {width} = e.target;
    const height = width * ratio;
    setHeight(height);
  };

  return (
    <>
      {!height && (<img src={src} onLoad={onImageLoad} />)}
      {height && (<img src={src} style={{height:`${height}px`}} />)}
    </>

  );
};

ImageResizedForHeight.defaultProps = {
  ratio: 3/4
};

ImageResizedForHeight.propTypes = {
  src: PropTypes.string.isRequired,
  ratio: PropTypes.number
};

export default ImageResizedForHeight;
