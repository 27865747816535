import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../selectors/facility';
import {getSystemOptions} from '../../selectors/system';

import { getSnippetAddress, getFullAddress } from '../common/LocationFormat';
import { truncate } from '../../utils/utils';

import ToolTip from '../common/ToolTip/ToolTip';
// import FacilityBed from '../common/FacilityBed/FacilityBed';
// import FacilityAvailability from '../common/FacilityAvailability/FacilityAvailability';
import FacilityStatus from "../common/FacilityStatus/FacilityStatus";
import FacilityHours from "../common/FacilityHours/FacilityHours";

import FacilityFlag from '../common/FacilityFlag/FacilityFlag';

import { ReactComponent as QuestionMark } from '../../images/icons/question-mark.svg';
import { ReactComponent as Claimed } from '../../images/icons/claimed.svg';
import { ReactComponent as Phone } from '../../images/icons/phone.svg';
import { ReactComponent as Web } from '../../images/icons/web.svg';

import grid from '../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import uuidv4 from "uuid/v4";

class FacilityHeader extends PureComponent {

  static renderClaimedToolTip(isClaimed,id) {
    return (
      <Fragment>
        {isClaimed ? (
            <FormattedMessage id="facility.claimed.desc" />
        ) : (
          <>
            <FormattedMessage id="facility.unclaimed.desc" />
            <a href={`${process.env.REACT_APP_PROVIDER_PATH}/facilityClaim/createFull?facilityId=${id}`}
               target="_blank">
              <strong><FormattedMessage id="facility.unclaimed.desc.click" /></strong>

            </a>
            </>
        )}
      </Fragment>
    );
  }

  renderAddress() {
    const { facility } = this.props;

    if (facility.privateAddress) {
      return getSnippetAddress(facility.city, facility.state, facility.zipCode, facility.county);
    }
    return getFullAddress(facility.address1, facility.address2,
      facility.city, facility.state, facility.zipCode, facility.county);
  }



  render() {
    const { facility, systemOptions } = this.props;
    return (
      <Fragment>
        <div className={[grid.col, styles.header, 'gh-facility-header'].join(' ')}>
          <div>
            <span className={styles.facilityHeader}>{facility.name}</span>
             <ToolTip
              placement="bottom"
              trigger="hover"
              tooltip={this.constructor.renderClaimedToolTip(facility.claimed, facility.id)}
              delayHide={200}
             >
              <span className={facility.claimed ? styles.claimed : styles.unclaimed}>
                {facility.claimed ? (
                  <Fragment>
                    <Claimed />
                    <FormattedMessage id="facility.claimed" />
                  </Fragment>
                ) : (
                  <Fragment>
                    <a target="_blank" href=
                         {`${process.env.REACT_APP_PROVIDER_PATH}/facilityClaim/createFull?facilityId=${facility.id}`}>
                      <QuestionMark />
                      <FormattedMessage id="facility.unclaimed" />
                    </a>
                  </Fragment>
                )}
              </span>
             </ToolTip>
          </div>
          <div className={styles.address}>{this.renderAddress()}</div>

          <div className={styles.badgeRow}>
            <div className={styles.badgeCol}>
              {facility.phone && (
                <div className={['gh-facility-phone'].join(' ')}>
                  <Phone className={styles.phoneSvg} />
                  <a href={`tel:+${facility.phone}`} className={styles.phone}>
                    {facility.phone}
                  </a>
                </div>
              )}
              {facility.website && (
                <div className={['gh-facility-web'].join(' ')}>
                  <Web className={styles.websiteSvg} />
                  <a href={facility.website} className={styles.website} target="_blank" rel="noreferrer noopener">
                    {truncate(facility.website.replace(/^https?\:\/\//i, ""), 35)}
                  </a>
                </div>
              )}
            </div>
            <div className={styles.badges}>
               <div className={styles.rating} style={{ display: 'inline-block' }}>
                <FacilityFlag rating={facility.rating} />
               </div>
            </div>
          </div>
          <div className={styles.badgeRow}>

            {facility.hours && facility.hours.length > 0 && (
              <div className={styles.badgeCol} >
                <FacilityHours hours={facility.hours} />
              </div>
            )}
            <div className={[styles.badgeCol, styles.alignBottom, styles.availability].join(' ')}>
              {/* {systemOptions.hasBedAvailability && ( */}
              {/*  <FacilityAvailability beds={facility.freeBeds} availability={facility.bedsAvailability} /> */}
              {/* )} */}
            </div>
            <div className={[styles.bed, styles.alignBottom].join(' ')}>
              {typeof facility.status !== 'undefined' && (
                <FacilityStatus status={facility.status} />
              )}

              {/* <FacilityBed beds={facility.beds} /> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

FacilityHeader.defaultProps = {
  systemOptions: {}
};

FacilityHeader.propTypes = {
  facility: PropTypes.object.isRequired,
  systemOptions:  PropTypes.object,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
  systemOptions: getSystemOptions(state),

});

export default connect(mapStateToProps)(FacilityHeader);
