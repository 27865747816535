import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalSection from '../../../common/v2/ModalSection/ModalSection';

import styles from '../FilterModal/filterModal.module.css';
import grid from '../../../../styles/bootstrap-grid.module.css';

class AmenitiesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      allAmenities: [],
      amenitiesToShow: [],
      amenitiesSelected: [],
      expanded: false,
    };

    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.renderAmenities = this.renderAmenities.bind(this);
    this.handleAmenitieSelected = this.handleAmenitieSelected.bind(this);
    this.handleShowAllAmenities = this.handleShowAllAmenities.bind(this);
  }

  componentDidMount() {
    const {
      initialServices,
      search: { amenities = [] },
      search: { services = new Set() },
      handleFiltersOptionsChange,
    } = this.props;

    const allAmenities = initialServices
      .filter(service => service.categoryId === 55)
      .map(service => ({ label: service.name, value: service.id }));
    this.setState({
      allAmenities: allAmenities,
      amenitiesToShow: allAmenities.slice(0, 10),
      amenitiesSelected: amenities,
    });

    if (services) {
      const myArr = Array.from(services);

      const items = myArr.filter(element => allAmenities.find(amenity => amenity.value === element));

      if (items.length > 0) {
        handleFiltersOptionsChange('amenities', { value: items });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      search,
      search: { amenities = [] },
    } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        amenitiesSelected: amenities ? amenities : [],
      });
    }
  }

  handleShowFilter() {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter,
    }));
  }

  handleAmenitieSelected(e) {
    const { handleFiltersOptionsChange } = this.props;
    const { amenitiesSelected } = this.state;

    let value = Number(e.target.value);
    let isChecked = e.target.checked;

    if (isChecked) {
      amenitiesSelected.push(value);
    } else {
      let index = amenitiesSelected.indexOf(value);
      if (index !== -1) {
        amenitiesSelected.splice(index, 1);
      }
    }
    this.setState({ amenitiesSelected: amenitiesSelected });
    handleFiltersOptionsChange('amenities', { value: amenitiesSelected });

    if (amenitiesSelected.length === 0) {
      handleFiltersOptionsChange('amenities', { value: null });
    }
  }

  handleShowAllAmenities() {
    const { allAmenities, expanded } = this.state;
    this.setState({
      amenitiesToShow: !expanded ? allAmenities : allAmenities.slice(0, 10),
      expanded: !expanded,
    });
  }

  renderAmenities() {
    const { amenitiesToShow, expanded, amenitiesSelected } = this.state;

    return (
      <>
        <div className={styles.filterItemContainer}>
          {amenitiesToShow.map((item, index) => (
            <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')} key={index}>
              <div className={styles.filterItem}>
                <label className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name={item.label}
                    value={item.value}
                    onChange={this.handleAmenitieSelected}
                    checked={amenitiesSelected.includes(item.value)}
                  />
                  <span className={[styles.checkboxLabel, 'gh-filter-label'].join(' ')}>
                    <span>{item.label}</span>
                  </span>
                </label>
              </div>
            </div>
          ))}
        </div>
        {amenitiesToShow.length > 9 && (
          <div className={styles.box1}>
            <button className={styles.showMore} onClick={this.handleShowAllAmenities}>
              {expanded ? 'Show Less >' : 'Show More >'}
            </button>
          </div>
        )}
      </>
    );
  }
  render() {
    const { showFilter } = this.state;

    return (
      <div className={styles.filterContainer}>
        <ModalSection headingLabel="Amenities" handleClick={this.handleShowFilter} expanded={showFilter}>
          <div className={grid.containerFluid}>{this.renderAmenities()}</div>
        </ModalSection>
      </div>
    );
  }
}

AmenitiesFilter.propTypes = {
  initialServices: PropTypes.array.isRequired,
  handleFiltersOptionsChange: PropTypes.func.isRequired,
};

export default AmenitiesFilter;
