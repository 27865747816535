import React from 'react';
import PropTypes from 'prop-types';

import GoogleMapReact from 'google-map-react';

import styles from './map.module.css';

const GoogleMap = ({ children, ...props }) => (
  <div className={styles.mapWrapper}>
    <GoogleMapReact
      {...props}
    >
      {children}
    </GoogleMapReact>
  </div>
);

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
