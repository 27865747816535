import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from '../components/common/Loader/Loader';
import { isFetchingFacilities } from '../selectors/facilities';
import { getSystemConfig } from '../selectors/system';

import FacilitiesV1 from './v1/Facilities';
import FacilitiesV2 from './v2/Facilities';

class Facilities extends Component {
  render() {
    const {
      isFetching,
      searchOptions,
      systemConfig: { version = 1 },
    } = this.props;
    return (
      <Fragment>
        <Loader removeLoader={!isFetching} />
        {version === 1 && <FacilitiesV1 {...this.props} searchOptions={searchOptions} />}
        {(version === 1.5 || version === 2 || version === 2.1) && 
        <FacilitiesV2 {...this.props} searchOptions={searchOptions} />}
      </Fragment>
    );
  }
}

Facilities.defaultProps = {};

Facilities.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  searchOptions: PropTypes.object,
  systemConfig: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  isFetching: isFetchingFacilities(state),
  systemConfig: getSystemConfig(state),
});

export default connect(mapStateToProps)(Facilities);
