/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import { getFacilities } from '../../../../selectors/facilities';
import { getServices } from '../../../../selectors/services';
import { getGoogleMap } from '../../../../selectors/map';

import { ReactComponent as Search } from '../../../../images/icons/v2/search.svg';

import styles from './facilitySearch.module.css';
import grid from '../../../../styles/bootstrap-grid.module.css';

import FacilitySearchDistance from './FacilitySearchDistance';
import FacilitySearchPopulation from './FacilitySearchPopulation';
import FacilitySearchLocation from './FacilitySearchLocation';
import { getSystemOptions } from '../../../../selectors/system';
import { getI18nString } from '../../../../utils/utils';
import FacilitySearchName from './FacilitySearchName';
import { FT_PERSONAL_PROFILE, FT_SCHOOL } from '../../../../constants/Other';
import FacilitySearchType from "./FacilitySearchType";

class FacilitySearch extends Component {
  render() {
    const {
      handleLocationChange,
      handleSearchBarChange,
      handleFiltersOptionsChange,
      fetchFilteredSearch,
      handleFacilityTypeChange,
      search,
      googleMap,
      locationName,
      systemOptions,
      searchOptions: { widgetType = '' } = {},
      openSearchRadius,
      showSearchRadius,
      systemConfig: { version },
    } = this.props;

    return (
      <div className={[grid.row, styles.searchContainer, grid.container].join(' ')}>
        {/*<div className={[grid.col, styles.searchParam, styles.searchParamIAm].join(' ')}>*/}
        {/*  <div className={styles.searchLabel}>*/}
        {/*    <FormattedMessage id="search.label.i.am" />*/}
        {/*  </div>*/}
        {/*  <FacilitySearchRole search={search} handleSearchBarChange={handleSearchBarChange} />*/}
        {/*</div>*/}
          {version === 2.1 && (widgetType === undefined || widgetType === '') &&
            <div
                className={[
                    grid.col,
                    styles.searchParam
                ].join(' ')}
            >
                <div className={styles.searchLabel}>
                    <FormattedMessage id="search.label.type"/>
                </div>
                <FacilitySearchType
                    search={search}
                    handleFacilityTypeChange={handleFacilityTypeChange}
                />
            </div>
          }
          <div
              className={[
                  grid.col,
                  styles.searchParam,
                  widgetType !== FT_PERSONAL_PROFILE && styles.searchParamDistance,
              ].join(' ')}
          >
              <div className={styles.searchLabel}>
                  <FormattedMessage id="search.label.name" />
              </div>
              <FacilitySearchName
                  handleFiltersOptionsChange={handleFiltersOptionsChange}
                  fetchFilteredSearch={fetchFilteredSearch}
                  className={styles.location}
                  search={search}
                  widgetType={widgetType}/>
          </div>
        {widgetType !== FT_PERSONAL_PROFILE && (
          <>
            <div
                className={[
                    grid.col,
                    styles.searchParam,
                    styles.searchParamDistance
                ].join(' ')}>
              <div className={styles.searchLabel}>
                <FormattedMessage
                  id={`${getI18nString(widgetType.startsWith(FT_SCHOOL) && FT_SCHOOL, 'search.label.distance')}`}
                />
              </div>
              <FacilitySearchDistance
                search={search}
                handleSearchBarChange={handleSearchBarChange}
                showSearchRadius={showSearchRadius}
                openSearchRadius={openSearchRadius}
              />
            </div>
            <div className={[grid.col, styles.searchParam].join(' ')}>
              <div className={styles.searchLabel}>
                <FormattedMessage id="search.label.location" />
              </div>
              <FacilitySearchLocation
                search={search}
                systemOptions={systemOptions}
                googleMap={googleMap}
                handleLocationChange={handleLocationChange}
                locationName={locationName}
                className={styles.location}
              />
            </div>
            <div
                className={[
                    grid.col,
                    styles.searchParam,
                    styles.searchParamGender
                ].join(' ')}>
                <div className={styles.searchLabel}>
                    <FormattedMessage id="search.label.for" />
                </div>
                <FacilitySearchPopulation
                    search={search}
                    handleSearchBarChange={handleSearchBarChange}
                    widgetType={widgetType}
                />
            </div>
          </>
        )}
        <div
            className={[
                grid.col1,
                styles.searchParam,
                styles.searchParamSearchBtn
            ].join(' ')}>
          <a href="#" onClick={handleSearchBarChange}>
            <Search />
          </a>
        </div>
      </div>
    );
  }
}

FacilitySearch.defaultProps = {
  locationName: '',
  systemOptions: {},
};

FacilitySearch.propTypes = {
  searchServices: PropTypes.array.isRequired,
  handleSearchBarChange: PropTypes.func.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  googleMap: PropTypes.object.isRequired,
  locationName: PropTypes.string,
  intl: PropTypes.object.isRequired,
  systemOptions: PropTypes.object,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facilities: getFacilities(state),
  searchServices: getServices(state),
  systemOptions: getSystemOptions(state),
  googleMap: getGoogleMap(state),
});

export default connect(mapStateToProps)(injectIntl(FacilitySearch));
