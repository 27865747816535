import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UrlAssembler from 'url-assembler';

import styles from './facilities.module.css';
import FacilityCard from '../../common/v2/FacilityCard/FacilityCard';

class FacilityItem extends Component {
  static getStyleOverrides(overrides) {
    const styleOverrides = {};

    if (overrides.minWidth) {
      styleOverrides.minWidth = overrides.minWidth;
    }

    return styleOverrides;
  }

  static getFacilityUrl(pattern, id) {
    const url = UrlAssembler()
      .template(pattern)
      .param({ id })
      .toString();
    return decodeURIComponent(url);
  }

  constructor(props) {
    super(props);

    this.state = {
      overrides: {
        facilityUrl: '#/facility/:id',
      },
    };
  }

  componentDidMount() {
    const {
      systemOptions: { pageOverrides: { facilityUrl } = {} },
    } = this.props;
    if (facilityUrl) {
      this.setState({ overrides: { facilityUrl } });
    }
  }

  render() {
    const { facility, styleOverrides, systemOptions, view, version } = this.props;

    const {
      overrides: { facilityUrl },
    } = this.state;

    return (
      <div
        className={[styles.facilityItem, 'gh-facilities-item'].join(' ')}
        css={this.constructor.getStyleOverrides(styleOverrides)}
        ref={this.myRef}
      >
        <FacilityCard
          facility={facility}
          systemOptions={systemOptions}
          view={view}
          facilityUrl={this.constructor.getFacilityUrl(facilityUrl, facility.id)}
          version={version}
        />
      </div>
    );
  }
}

FacilityItem.defaultProps = {
  styleOverrides: {},
  systemOptions: {},
};

FacilityItem.propTypes = {
  facility: PropTypes.object.isRequired,
  styleOverrides: PropTypes.object,
  systemOptions: PropTypes.object,
  view: PropTypes.string.isRequired,
};

export default FacilityItem;
