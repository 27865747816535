import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from "react-images";
import uuidv4 from 'uuid/v4';

import { getFacilityImagesSelector } from '../../selectors/facility';
import styles from './facility.module.css';

class FacilityGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentModal: null
    };
  }

   toggleModal = index => this.setState({selectedIndex: index});

  render() {
    const { images } = this.props;
    const { selectedIndex } = this.state;

    return (
      <>
          {images.length > 0 && (
            <div className={styles.imageGalleryRow}>
              {images.length > 0 && (
                <>
                  <div className={styles.imageGalleryCol}>
                    <img src={images[0].source} onClick={() =>this.toggleModal(0)}
                         className={[styles.imageGalleryThumb, styles.imageGalleryThumbPrimary].join(' ')} />
                  </div>
                  <div className={styles.imageGalleryCol}>
                    {images.map((item,index) => {
                      if(index > 0 && index < 4) {
                        return (
                          <div className={styles.imageThumbContainer}  key={uuidv4()}>
                            <img src={item.source} onClick={() =>this.toggleModal(index)}
                                 className={[styles.imageGalleryThumb].join(' ')} />
                          </div>
                        );
                      }
                    })}
                    {images.length >= 4 && (
                      <div className={[styles.imageThumbContainer,styles.imageMoreContainer].join(' ')}
                           onClick={() =>this.toggleModal(4)}>
                        <img src={images[4].source} className={[styles.imageGalleryThumb].join(' ')} />
                        <div className={styles.imageMoreText}>
                          {images.length} Photos
                          <br/>
                          View All

                        </div>
                      </div>
                    )}
                  </div>
                  <ModalGateway>
                    {Number.isInteger(selectedIndex) ? (
                      <Modal onClose={this.toggleModal}>
                        <Carousel currentIndex={selectedIndex}  views={images} />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </>
              )}
            </div>
          )}

        </>

    );
  }
}
FacilityGallery.propTypes = {
  images: PropTypes.array.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  images: getFacilityImagesSelector(state),
});

export default connect(mapStateToProps)(FacilityGallery);
