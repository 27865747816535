import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { createIntl, createIntlCache } from 'react-intl';
import localeData from '../locales/en';
import { featureSet } from '../featureSet';
import { getSystemConfig } from './system';

const cache = createIntlCache();
const intl = createIntl({ locale: 'en', messages: localeData }, cache);

export const getFacility = state => state.facility.facility;
export const getInstitution = state => state.facility.institution;

export const isFetchingFacility = state => state.facility.isFetching;

const facilityV2 = facility => {
  const facilityClone = Object.assign({}, facility);
  facilityClone.premium = true;

  facilityClone.serviceCategoryMap = new Map(facilityClone.serviceCategories.map(key => [key.code, key.services]));
  let flattenedArray = [];
  facilityClone.servicesMap = new Map();

  //iterate over category
  facilityClone.serviceCategoryMap.forEach((value, key) => {
    const newFlattenArray = value.flatMap(item => [{ code: item.code, service: item.name }]);
    flattenedArray = [...flattenedArray, ...newFlattenArray];
  });

  let amenities = facilityClone.serviceCategoryMap.get('CAT_AMENITIES');
  amenities = amenities ? amenities.sort((a, b) => b.priority - a.priority) : [];
  facilityClone.serviceCategoryMap.set('CAT_AMENITIES', amenities);

  if (flattenedArray.length) {
    facilityClone.servicesMap = new Map(flattenedArray.map(key => [key.code, key.service]));
  }

  if (facilityClone.housePolicyDocuments.length) {
    const and = intl.formatMessage({ id: 'common.and' });
    facilityClone.housePolicyMap = facilityClone.housePolicyDocuments.flatMap(item => {
      if (item.tags === null || item.tags === '') {
        return [];
      }

      const tags = item.tags
        .split(',')
        .map(tag => intl.formatMessage({ id: `facility.policy.selector.${tag}` }) + ', ');
      const params = {
        tags: item.tags,
        url: item.url,
        label: tags
          .reduce((policies, currentTag) => {
            return policies + currentTag;
          }, '')
          .slice(0, -2)
          .replace(new RegExp(',([^,]*)$'), ` ${and} $1`),
        // removes (, and blank space) at the end of the string, and replace last (,) with (and) word
      };

      return params;
    });
  }

  return facilityClone;
};
const facilityV1 = facility => {
  const facilityClone = Object.assign({}, facility);

  facilityClone.serviceCategoryMap = new Map(facilityClone.serviceCategories.map(key => [key.name, key.services]));

  facilityClone.insurances.forEach(item => {
    switch (item.status) {
      case 1: // In Network
        facilityClone.insuranceInNetwork.push(item);
        break;
      case 2: // Out of Network
        facilityClone.insuranceOutOfNetwork.push(item);
        break;
      default:
    }
  });

  return facilityClone;
};
export const getFacilitySelector = createSelector(
  [getFacility, getSystemConfig],
  (facility, systemConfig) => {
    let facilityClone = Object.assign({}, facility);
    facilityClone.insuranceInNetwork = [];
    facilityClone.insuranceOutOfNetwork = [];
    facilityClone.socialLinks = [
      {
        facebookUrl: facility.facebookUrl,
        twitterUrl: facility.twitterUrl,
        linkedinUrl: facility.linkedinUrl,
        instagramUrl: facility.instagramUrl,
      },
    ];
    facilityClone.serviceCategoryMap = new Map();
    facilityClone.servicesMap = new Map();
    facilityClone.housePolicyMap = new Map();

    if (!isEmpty(facility)) {
      if (facilityClone.website && !facilityClone.website.includes('http')) {
        facilityClone.website = `https://${facilityClone.website}`;
      }

      if (systemConfig.version > 1) {
        facilityClone = facilityV2(facilityClone);
      } else {
        facilityClone = facilityV1(facilityClone);
      }
    }
    return facilityClone;
  },
);

export const getFeatures = createSelector(
  [getInstitution],
  institution => {
    const features = {};

    if (!institution.features) {
      return features;
    }

    institution.features.forEach(item => {
      if (item.enabled) {
        features[item.feature] = item;
      }
    });
    return features;
  },
);

export const getMapAutocompleteKey = createSelector(
  [getFeatures],
  features => {
    let key = '';
    const map = features[featureSet.AUTO_COMPLETE];

    if (map && map.config) {
      const mapItem = map.config.find(item => item.name === 'WEB_API_KEY');
      key = mapItem ? mapItem.value : '';
    }
    return key;
  },
);
export const getFacilityImagesSelector = createSelector(
  [getFacility],
  facility => {
    let images = [];
    if (!isEmpty(facility)) {
      images = facility.images.map(item => ({ source: item }));
    }
    return images;
  },
);
