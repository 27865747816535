const breakPoints = {
  xs: {
    min: 0,
    max: 575,
  },
  sm: {
    min: 576,
    max: 768,
  },
  md: {
    min: 768,
    max: 992,
  },
  lg: {
    min: 992,
    max: 1200,
  },
  xl: {
    min: 1200,
    max: 99999,
  },
};

export default () => {
  const width = window.innerWidth;

  for (const key in breakPoints) {
    if (breakPoints[key].min <= width && breakPoints[key].max > width) {
      return key;
    }
  }

  return 'xl';
};
