import { request } from './BaseRequest';

export const fetchAuth = ({apiKey}) =>
  request({
    apiPath:  process.env.REACT_APP_AUTH_PATH,
    url: '/api/client-details',
    apiKey,
  }).then(async (response ={}) => {

    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    const data = await response.json();
    throw new Error(data.details);
  });


