exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../images/icons/badges/claimed.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../images/icons/badges/verified.svg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../../images/icons/badges/premium.svg"));

// Module
exports.push([module.id, ".facilityBadges_badgeRow__2Hbzt {\n  margin-top: 8px;\n}\n\n.facilityBadges_badgeBase__1NlII {\n  background-size: 24px 24px;\n  cursor: pointer;\n  display: inline-block;\n  height: 24px;\n  margin-right: 8px;\n  position: relative;\n  width: 24px;\n}\n\n.facilityBadges_claimed__3DrTv {\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n\n.facilityBadges_verified__2uC0h {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n\n.facilityBadges_premium__onAwt {\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n\n.facilityBadges_toolTipHeader__35fZE {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["facilityBadges.module.css"],"names":[],"mappings":"AAAA;EACE,eAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,qBAAqB;EACrB,YAAY;EACZ,iBAA2B;EAC3B,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,+CAAiE;AAEnE;;AAEA;EACE,+CAAkE;AAEpE;;AAEA;EACE,+CAAiE;AAEnE;;AAEA;EACE,iBAAiB;AACnB","file":"facilityBadges.module.css","sourcesContent":[".badgeRow {\n  margin-top: $smallSpacing;\n}\n\n.badgeBase {\n  background-size: 24px 24px;\n  cursor: pointer;\n  display: inline-block;\n  height: 24px;\n  margin-right: $smallSpacing;\n  position: relative;\n  width: 24px;\n}\n\n.claimed {\n  background-image: url('../../../images/icons/badges/claimed.svg');\n  composes: badgeBase;\n}\n\n.verified {\n  background-image: url('../../../images/icons/badges/verified.svg');\n  composes: badgeBase;\n}\n\n.premium {\n  background-image: url('../../../images/icons/badges/premium.svg');\n  composes: badgeBase;\n}\n\n.toolTipHeader {\n  font-weight: bold;\n}\n"]}]);

// Exports
exports.locals = {
	"badgeRow": "facilityBadges_badgeRow__2Hbzt",
	"badgeBase": "facilityBadges_badgeBase__1NlII",
	"claimed": "facilityBadges_claimed__3DrTv facilityBadges_badgeBase__1NlII",
	"verified": "facilityBadges_verified__2uC0h facilityBadges_badgeBase__1NlII",
	"premium": "facilityBadges_premium__onAwt facilityBadges_badgeBase__1NlII",
	"toolTipHeader": "facilityBadges_toolTipHeader__35fZE"
};