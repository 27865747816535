import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Menu, { MenuItem, SubMenu } from 'rc-menu';

import { ReactComponent as Arrow } from '../../../../images/icons/v2/down-arrow.svg';
import { ReactComponent as X } from '../../../../images/icons/v2/x.svg';

import '../../../../styles/general.css';
import styles from './facilitySearchBar.module.css';
import { RESIDENT_CHARACTERISTICS_LIST } from '../../../../constants/filteredSearchOptions';

class BarResidentModifiers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      characteristicsSelected: [],
      expanded: false,
      selectedKeys: [],
    };

    this.handleCharacteristicsSelected = this.handleCharacteristicsSelected.bind(this);
    this.handleExpanded = this.handleExpanded.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      search: { residentCharacteristics = [] },
      search,
    } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        characteristicsSelected: residentCharacteristics,
      });

      if (residentCharacteristics.length === 0) {
        this.setState({
          selectedKeys: [],
        });
      }
    }
  }

  handleExpanded() {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  handleClear() {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;

    this.setState({
      characteristicsSelected: [],
      selectedKeys: [],
    });
    handleFiltersOptionsChange('residentCharacteristics', { value: null });
    fetchFilteredSearch();
  }

  handleCharacteristicsSelected(e) {
    const { handleFiltersOptionsChange, fetchFilteredSearch } = this.props;
    const { characteristicsSelected, selectedKeys } = this.state;

    let items = characteristicsSelected;
    let keys = selectedKeys;

    if (characteristicsSelected.includes(Number(e.key))) {
      items = characteristicsSelected.filter(item => item !== Number(e.key));
      keys = selectedKeys.filter(item => item !== e.key);
    } else {
      items.push(Number(e.key));
      keys.push(e.key);
    }

    this.setState({
      characteristicsSelected: items,
      selectedKeys: keys,
    });

    handleFiltersOptionsChange('residentCharacteristics', { value: items });
    fetchFilteredSearch();
    this.forceUpdate();
  }
  render() {
    const { characteristicsSelected, expanded, selectedKeys } = this.state;

    return (
      <>
        <Menu
          mode="horizontal"
          overflowedIndicator=""
          triggerSubMenuAction="click"
          multiple={true}
          onOpenChange={this.handleExpanded}
          selectedKeys={selectedKeys}
        >
          <SubMenu
            title={
              <div>
                <span className="submenu-title-wrapper">
                  <FormattedMessage id="search.bar.modifiers" />
                </span>
                {characteristicsSelected.length > 0 && (
                  <>
                    <span className={styles.totalCharacteristicsSelected}>{characteristicsSelected.length}</span>
                    <X className={styles.removeIcon} onClick={this.handleClear} />
                  </>
                )}
                <Arrow className={expanded ? styles.upArrow : styles.downArrow} />
              </div>
            }
            key="residentModifierKey"
          >
            {RESIDENT_CHARACTERISTICS_LIST.map((characteristic, index) => (
              <MenuItem
                key={characteristic.value}
                onClick={this.handleCharacteristicsSelected}
                className={[styles.checkboxContainer, styles.filterOption].join(' ')}
              >
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  id={characteristic.label}
                  value={characteristic.value}
                  checked={characteristicsSelected.includes(characteristic.value)}
                  readOnly
                />
                <span>{characteristic.label}</span>
              </MenuItem>
            ))}
          </SubMenu>
        </Menu>
      </>
    );
  }
}

BarResidentModifiers.propTypes = {
  handleFiltersOptionsChange: PropTypes.func.isRequired,
  fetchFilteredSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
};

export default injectIntl(BarResidentModifiers);
