
export const STORE_SYSTEM_CONFIG = 'SYSTEM_CONFIG';

export const STORE_AUTH_REQUESTED = 'STORE_AUTH_REQUESTED';
export const STORE_AUTH_SUCCEEDED = 'STORE_AUTH_SUCCEEDED';
export const STORE_AUTH_FAILED = 'STORE_AUTH_FAILED';

export const loadAuth = args => ({
  type: STORE_AUTH_REQUESTED,
  args,
});

export const authFetchSucceeded = data => ({
  type: STORE_AUTH_SUCCEEDED,
  data,
});

export const authFetchFailed = message => ({
  type: STORE_AUTH_FAILED,
  message,
});

export const storeSystemConfig = config => ({
  type: STORE_SYSTEM_CONFIG,
  config,
});
