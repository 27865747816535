import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFacilitySelector } from '../../../../selectors/facility';

import {getSnippetAddress, getFullAddress} from '../../../common/LocationFormat';
import { truncate } from '../../../../utils/utils';


import styles from './facility-contact-info.module.css';
import FacilitySocialLinks from "../FacilitySocialLinks";

class FacilityContactInfo extends PureComponent {

  renderAddress() {
    const { facility } = this.props;
    if (facility.privateAddress) {
      return getSnippetAddress(facility.city, facility.state, facility.zipCode, facility.county);
    }
    return getFullAddress(facility.address1, facility.address2,
      facility.city, facility.state, facility.zipCode, facility.county);
  }

  render() {
    const { facility, systemOptions } = this.props;

    return (
      <div id="contactBox" className={[styles.card].join(' ')}>

        <h2 className={styles.header}> <FormattedMessage id="facility.header.contact"/></h2>
        <div className={styles.address}>{this.renderAddress()}</div>
        {facility.phone && (
          <div className={styles.phone}>
            <a href={`tel:+${facility.phone}`} className={styles.link} >{facility.phone}</a>
          </div>
        )}
        {facility.email && (
          <div className={styles.email}>{facility.email}</div>
        )}
        {facility.premium && facility.admissionsEmail && (
          <div className={styles.email}>{facility.admissionsEmail}</div>
        )}
        {facility.website && (
          <div className={styles.website}>
            <a href={`${facility.website}`} className={styles.link} target="_blank" rel="noreferrer noopener">
              {truncate(facility.website,50)}
            </a>
          </div>
        )}
        <FacilitySocialLinks facility={facility} />
        {facility.mobile === true &&
            <div className={styles.contentMobile}>
              Note: This is a mobile resource - address may change
            </div>
        }
      </div>
    );
  }
}

FacilityContactInfo.propTypes = {
  facility: PropTypes.object.isRequired,
};

// reducer is called facilities
const mapStateToProps = state => ({
  facility: getFacilitySelector(state),

});

export default connect(mapStateToProps)(FacilityContactInfo);
