export const SERVICES_FETCH_REQUESTED = 'SERVICES_FETCH_REQUESTED';
export const SERVICES_FETCH_SUCCEEDED = 'SERVICES_FETCH_SUCCEEDED';
export const SERVICES_FETCH_FAILED = 'SERVICES_FETCH_FAILED';

export const SUGGESTED_FETCH_REQUESTED = 'SUGGESTED_FETCH_REQUESTED';
export const SUGGESTED_FETCH_SUCCEEDED = 'SUGGESTED_FETCH_SUCCEEDED';
export const SUGGESTED_FETCH_FAILED = 'SUGGESTED_FETCH_FAILED';

export const SUGGESTED_AND_SERVICE_FETCH_REQUESTED = 'SUGGESTED_AND_SERVICE_FETCH_REQUESTED';

export const loadServices = () => ({
  type: SERVICES_FETCH_REQUESTED,
});

export const servicesFetchSucceeded = data => ({
  type: SERVICES_FETCH_SUCCEEDED,
  data,
});

export const servicesFetchFailed = message => ({
  type: SERVICES_FETCH_FAILED,
  message,
});

export const loadSuggested = () => ({
  type: SUGGESTED_FETCH_REQUESTED,
});

export const suggestedFetchSucceeded = data => ({
  type: SUGGESTED_FETCH_SUCCEEDED,
  data,
});

export const suggestedFetchFailed = message => ({
  type: SUGGESTED_FETCH_FAILED,
  message,
});

export const loadSuggestedAndServices = () => ({
  type: SUGGESTED_AND_SERVICE_FETCH_REQUESTED,
});