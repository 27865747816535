exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "\n.modalSection_section__3q-I1 {\n  border-bottom: 1px solid #e2e2e2;\n}\n\n.modalSection_heading__2wjyg {\n  align-items: center;\n  color: #484848;\n  cursor: pointer;\n  display: flex;\n  font-size: 18px;\n  font-weight: 600;\n  justify-content: space-between;\n  padding: 1.5rem 0;\n}\n\n.modalSection_heading__2wjyg h3 {\n  margin: 0;\n}\n\n.modalSection_sectionContent__21g2w {\n  display: flex;\n  flex-flow: row wrap;\n  padding-bottom: 10px;\n  width: 100%;\n}\n\n.modalSection_downArrow__1jk9b {\n  fill: #555555;\n  height: 10px;\n  width: 10px;\n}\n\n.modalSection_upArrow__2xck5 {\n  fill: #555555;\n  height: 10px;\n  -webkit-transform: rotate(-180deg);\n          transform: rotate(-180deg);\n  width: 10px;\n}\n", "",{"version":3,"sources":["modalSection.module.css"],"names":[],"mappings":";AACA;EACE,gCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,cAAmB;EACnB,eAAe;EACf,aAAa;EACb,eAAkB;EAClB,gBAAgB;EAChB,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,aAAoB;EACpB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAoB;EACpB,YAAY;EACZ,kCAA0B;UAA1B,0BAA0B;EAC1B,WAAW;AACb","file":"modalSection.module.css","sourcesContent":["\n.section {\n  border-bottom: 1px solid $borderV2;\n}\n\n.heading {\n  align-items: center;\n  color: $primaryText;\n  cursor: pointer;\n  display: flex;\n  font-size: $xlarge;\n  font-weight: 600;\n  justify-content: space-between;\n  padding: 1.5rem 0;\n}\n\n.heading h3 {\n  margin: 0;\n}\n\n.sectionContent {\n  display: flex;\n  flex-flow: row wrap;\n  padding-bottom: $mediumSpacing;\n  width: 100%;\n}\n\n.downArrow {\n  fill: $primaryTextV2;\n  height: 10px;\n  width: 10px;\n}\n\n.upArrow {\n  fill: $primaryTextV2;\n  height: 10px;\n  transform: rotate(-180deg);\n  width: 10px;\n}\n"]}]);

// Exports
exports.locals = {
	"section": "modalSection_section__3q-I1",
	"heading": "modalSection_heading__2wjyg",
	"sectionContent": "modalSection_sectionContent__21g2w",
	"downArrow": "modalSection_downArrow__1jk9b",
	"upArrow": "modalSection_upArrow__2xck5"
};