import mediaQuerySelector from '../../../../utils/mediaQuerySelector';

const desktop = {
  container: styles => ({
    ...styles,
    minHeight: '25px',
    fontSize: '12px',
  }),
  control: styles => ({
    ...styles,
    minHeight: '36px',
    background: 'transparent',
    border: '1px solid #e2e2e2',
    '&:hover': {
      background: '#fafbfd',
    },
  }),

  dropdownIndicator: styles => ({
    ...styles,
    padding: 0,
  }),
  menuList: styles => ({
    ...styles,
    color: '#555555',
  }),
};

const mobile = {
  container: styles => ({
    ...styles,
    display: 'inline-block',
    width: '100%',
    paddingLeft: '5px',
  }),
  control: styles => ({
    ...styles,
    paddingRight: '5px',
    borderRadius: '5px',
    paddingLeft: 0,
    minHeight: '30px',
    boxShadow: 'none',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: 0,
    border: 0,
    height: '36px',
  }),
  menuList: styles => ({
    ...styles,
    border: 0,
    color: '#555555',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
    marginTop: '8px!important',
  }),

  placeholder: styles => ({
    ...styles,
    top: '18px',
    fontWeight: 'normal',
  }),
};

const selectBox = () => {
  return mediaQuerySelector() === 'xs' ? mobile : { ...desktop };
};

export default selectBox;
