import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

import ModalSection from '../../../common/v2/ModalSection/ModalSection';
import selectStyles from '../FacilitySearch/SelectStyles';

import styles from '../FilterModal//filterModal.module.css';
import grid from '../../../../styles/bootstrap-grid.module.css';

const NARR_LEVELS = [
  { label: 'LEVEL 1', value: 'LEVEL1' },
  { label: 'LEVEL 2', value: 'LEVEL2' },
  { label: 'LEVEL 3', value: 'LEVEL3' },
  { label: 'LEVEL 4', value: 'LEVEL4' },
];

class HousingFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      NARRSelected: false,
      oxfordHousesSelected: false,
      smokingAllowedSelected: false,
      urineTestRequiredSelected: false,
      houseMeetingsSelected: false,
    };

    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.handleTobaccoUse = this.handleTobaccoUse.bind(this);
    this.handleDrugTesting = this.handleDrugTesting.bind(this);
    this.handleHouseMeetings = this.handleHouseMeetings.bind(this);
    this.handleNARRCertification = this.handleNARRCertification.bind(this);
    this.handleOxfordHousesCertification = this.handleOxfordHousesCertification.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;
    this.setState({
      smokingAllowedSelected: search.hasOwnProperty('smokingAllowed'),
      urineTestRequiredSelected: search.hasOwnProperty('urineTestRequired'),
      houseMeetingsSelected: search.hasOwnProperty('houseMeetings'),
      NARRSelected: search.hasOwnProperty('associations') && search.associations.includes('1'),
      oxfordHousesSelected: search.hasOwnProperty('associations') && search.associations.includes('4'),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleFiltersOptionsChange, search } = this.props;
    const { oxfordHousesSelected, NARRSelected } = this.state;

    if (prevState.oxfordHousesSelected !== oxfordHousesSelected || prevState.NARRSelected !== NARRSelected) {
      if (oxfordHousesSelected && NARRSelected) {
        handleFiltersOptionsChange('associations', { value: '1,4' });
      }
      if (NARRSelected && !oxfordHousesSelected) {
        handleFiltersOptionsChange('associations', { value: '1' });
      }
      if (oxfordHousesSelected && !NARRSelected) {
        handleFiltersOptionsChange('associations', { value: '4' });
      }
      if (!oxfordHousesSelected && !NARRSelected) {
        handleFiltersOptionsChange('associations', { value: null });
      }
    }

    if (prevProps.search !== search) {
      this.setState({
        smokingAllowedSelected: search.hasOwnProperty('smokingAllowed'),
        urineTestRequiredSelected: search.hasOwnProperty('urineTestRequired'),
        houseMeetingsSelected: search.hasOwnProperty('houseMeetings'),
        NARRSelected: search.hasOwnProperty('associations') && search.associations.includes('1'),
        oxfordHousesSelected: search.hasOwnProperty('associations') && search.associations.includes('4'),
      });
    }
  }

  handleShowFilter() {
    this.setState(prevState => ({
      showFilter: !prevState.showFilter,
    }));
  }

  handleTobaccoUse(e) {
    const { handleFiltersOptionsChange } = this.props;
    let isChecked = e.target.checked;
    if (isChecked) {
      handleFiltersOptionsChange('smokingAllowed', { value: isChecked });
    } else {
      handleFiltersOptionsChange('smokingAllowed', { value: null });
    }

    this.setState({
      smokingAllowedSelected: isChecked,
    });
  }

  handleDrugTesting(e) {
    const { handleFiltersOptionsChange } = this.props;
    let isChecked = e.target.checked;
    if (isChecked) {
      handleFiltersOptionsChange('urineTestRequired', { value: isChecked });
    } else {
      handleFiltersOptionsChange('urineTestRequired', { value: null });
    }

    this.setState({
      urineTestRequiredSelected: isChecked,
    });
  }

  handleHouseMeetings(e) {
    const { handleFiltersOptionsChange } = this.props;
    let isChecked = e.target.checked;
    if (isChecked) {
      handleFiltersOptionsChange('houseMeetings', { value: isChecked });
    } else {
      handleFiltersOptionsChange('houseMeetings', { value: null });
    }

    this.setState({
      houseMeetingsSelected: isChecked,
    });
  }

  handleNARRCertification() {
    const { NARRSelected } = this.state;

    this.setState({
      NARRSelected: !NARRSelected,
    });
  }

  handleOxfordHousesCertification() {
    const { oxfordHousesSelected } = this.state;

    this.setState({
      oxfordHousesSelected: !oxfordHousesSelected,
    });
  }

  render() {
    const {
      showFilter,
      NARRSelected,
      oxfordHousesSelected,
      houseMeetingsSelected,
      smokingAllowedSelected,
      urineTestRequiredSelected,
    } = this.state;
    const { handleFiltersOptionsChange, search } = this.props;

    return (
      <div className={styles.filterContainer}>
        <ModalSection headingLabel="Other Details" handleClick={this.handleShowFilter} expanded={showFilter}>
          <div className={grid.container}>
            <div className={styles.filterItemContainer}>
              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="smokingAllowed"
                      onChange={this.handleTobaccoUse}
                      checked={smokingAllowedSelected}
                    />
                    <span className={[styles.checkboxLabel, 'gh-filter-label'].join(' ')}>
                      <FormattedMessage id="search.modal.smokingAllowed" />
                    </span>
                  </label>
                </div>
              </div>

              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <div className={styles.filterItemLabel}>
                    <span className="gh-filter-label">
                      <FormattedMessage id="search.modal.NARRLevel" />
                    </span>
                  </div>
                  <div className={styles.filterItemInput}>
                    <NARRLevels handleFiltersOptionsChange={handleFiltersOptionsChange} search={search} />
                  </div>
                </div>
              </div>

              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="urineTestRequired"
                      onChange={this.handleDrugTesting}
                      checked={urineTestRequiredSelected}
                    />
                    <span className={[styles.checkboxLabel, 'gh-filter-label'].join(' ')}>
                      <FormattedMessage id="search.modal.urineTestRequired" />
                    </span>
                  </label>
                </div>
              </div>

              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <div>
                    <span className="gh-filter-label">
                      <FormattedMessage id="search.modal.associations" />
                    </span>
                    <label className={[styles.radioButtonContainer, 'gh-filter-label'].join(' ')}>
                      <input
                        type="radio"
                        name="associations1"
                        onClick={e => this.handleNARRCertification()}
                        checked={NARRSelected}
                        readOnly
                      />
                      <span className={[styles.checkboxLabel, 'gh-filter-label'].join('')}>
                        <FormattedMessage id="search.modal.associations.NARR" />
                      </span>
                    </label>
                    <label className={[styles.radioButtonContainer, 'gh-filter-label'].join(' ')}>
                      <input
                        type="radio"
                        name="associations4"
                        onClick={this.handleOxfordHousesCertification}
                        checked={oxfordHousesSelected}
                        readOnly
                      />
                      <span className={[styles.checkboxLabel, 'gh-filter-label'].join(' ')}>
                        <FormattedMessage id="search.modal.associations.oxfordHouses" />
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className={[grid.colSm5, grid.noGuttersBoth].join(' ')}>
                <div className={styles.filterItem}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="houseMeetings"
                      onChange={this.handleHouseMeetings}
                      checked={houseMeetingsSelected}
                    />
                    <span className={[styles.checkboxLabel, 'gh-filter-label'].join(' ')}>
                      <FormattedMessage id="search.modal.houseMeetings" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ModalSection>
      </div>
    );
  }
}

HousingFilter.propTypes = {
  handleFiltersOptionsChange: PropTypes.func.isRequired,
};
export default HousingFilter;

class NARRLevels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;
    this.setState({
      values: NARR_LEVELS.find(item => item.value === search.accreditationLevel),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        values: search.accreditationLevel ? NARR_LEVELS.find(item => item.value === search.accreditationLevel) : [],
      });
    }
  }

  handleChange(value) {
    const { handleFiltersOptionsChange } = this.props;
    handleFiltersOptionsChange('accreditationLevel', value);
    if (value === null) {
      handleFiltersOptionsChange('accreditationLevel', { value: null });
    }
  }

  render() {
    const { values } = this.state;

    return (
      <Select
        ref={ref => {
          this.select = ref;
        }}
        options={NARR_LEVELS}
        name="accreditationLevel"
        styles={selectStyles()}
        isSearchable={false}
        className="basic-single"
        onChange={this.handleChange}
        value={values}
        isClearable={true}
      />
    );
  }
}
