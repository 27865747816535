exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../facility.module.css"), "");

// Module
exports.push([module.id, ".facility-contact-info_card__3wccx {\n  background: #fff;\n  border-radius: 20px;\n  box-shadow: 0 10px 48px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n}\n\n.facility-contact-info_header__2W6EZ {\n}\n\n.facility-contact-info_address__1Fq2X,\n.facility-contact-info_phone__1uFXO {\n  margin-bottom: 10px;\n}\n\n.facility-contact-info_link__dwhGo,\n.facility-contact-info_link__dwhGo:hover {\n  color: #555555;\n}\n\n.facility-contact-info_website__25TIa {\n  text-decoration: underline;\n}\n\n.facility-contact-info_website__25TIa,\n.facility-contact-info_email__2gzrw {\n  margin-bottom: 10px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.facility-contact-info_contentMobile__3LmrJ {\n  color: #767676;\n  font-size: 12px;\n  font-weight: 600;\n}\n\n@media (max-width: 560px) {\n  .facility-contact-info_card__3wccx {\n    box-shadow: none;\n  }\n}\n", "",{"version":3,"sources":["facility-contact-info.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,0CAA0C;EAC1C,aAAa;AACf;;AAEA;AAEA;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;EAEE,cAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAuB;EACvB,eAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","file":"facility-contact-info.module.css","sourcesContent":[".card {\n  background: #fff;\n  border-radius: 20px;\n  box-shadow: 0 10px 48px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n}\n\n.header {\n  composes: header from '../facility.module.css';\n}\n\n.address,\n.phone {\n  margin-bottom: 10px;\n}\n\n.link,\n.link:hover {\n  color: $primaryTextV2;\n}\n\n.website {\n  text-decoration: underline;\n}\n\n.website,\n.email {\n  margin-bottom: 10px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.contentMobile {\n  color: $secondaryTextV2;\n  font-size: $small;\n  font-weight: 600;\n}\n\n@media (max-width: 560px) {\n  .card {\n    box-shadow: none;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"card": "facility-contact-info_card__3wccx",
	"header": "facility-contact-info_header__2W6EZ " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../facility.module.css").locals["header"] + "",
	"address": "facility-contact-info_address__1Fq2X",
	"phone": "facility-contact-info_phone__1uFXO",
	"link": "facility-contact-info_link__dwhGo",
	"website": "facility-contact-info_website__25TIa",
	"email": "facility-contact-info_email__2gzrw",
	"contentMobile": "facility-contact-info_contentMobile__3LmrJ"
};