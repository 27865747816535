import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getFacilitySelector } from '../../../selectors/facility';
import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';

class FacilityAmenities extends PureComponent {
  constructor(props) {
    super(props);
    this.renderAmenities = this.renderAmenities.bind(this);
    this.handleShowAllAmenities = this.handleShowAllAmenities.bind(this);

    this.state = {
      allAmenities: [],
      amenitiesToShow: [],
      expanded: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {facility, facility: { serviceCategoryMap }} = this.props;
    let amenities = serviceCategoryMap ? serviceCategoryMap.get('CAT_AMENITIES') : [];

    if (prevProps.facility !== facility) {
      this.setState({ allAmenities: amenities, amenitiesToShow: amenities.slice(0, 10) });
    }
  }

  handleShowAllAmenities() {
    const { allAmenities, expanded } = this.state;

    this.setState({
      amenitiesToShow: !expanded ? allAmenities : allAmenities.slice(0, 10),
      expanded: !expanded,
    });
  }
  renderAmenities() {
    const { amenitiesToShow, expanded } = this.state;
    const { intl: {formatMessage}} = this.props;

    return (
      <>
        <div className={styles.box1}>
          <div className={grid.row}>
            {amenitiesToShow.map((item, index) => (
              <div className={[grid.colSm6, styles.check].join(' ')} key={index}>
                <Check />
                <span>{`${item.name}`}</span>
              </div>
            ))}
          </div>
        </div>
        {amenitiesToShow.length > 9 && (
          <div className={styles.box1}>
            <button className={styles.showMore} onClick={this.handleShowAllAmenities}>
              {expanded ? formatMessage({id:'facility.amen.show.less'}) : formatMessage({id:'facility.amen.show.all'})}
            </button>
          </div>
        )}
      </>
    );
  }
  render() {
    const { amenitiesToShow } = this.state;

    return (
      amenitiesToShow && amenitiesToShow.length > 0 && (
        <>
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}>
                <FormattedMessage id="facility.header.amenities" />
              </h2>
            </div>
          </div>
          {this.renderAmenities()}
        </>
      )
    );
  }
}

FacilityAmenities.defaultProps = {};

FacilityAmenities.propTypes = {
  facility: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(injectIntl(FacilityAmenities));
