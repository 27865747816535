import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import {FormattedMessage, injectIntl} from 'react-intl';

import ToolTip from '../../common/ToolTip/ToolTip';


import styles from './facilityAccreditations.module.css';
const ASSET_URL = `${process.env.REACT_APP_STATIC}/accreditation-logos`;

const FacilityAccreditations = ({accreditations, intl: {formatMessage}}) => {
  const handleError = (event) => event.target.style.display = 'none';

  return (
    <Fragment>
      {accreditations.split(',').map(item => {
        return (
          <ToolTip
            key={item}
            placement="bottom"
            trigger="hover"
            tooltip={(<FormattedMessage id={`accreditation.${item}`} />)}
          >
            <>
              <img src={`${ASSET_URL}/${item}.png`} onError={handleError}
                   className={styles.img}/>
            </>
          </ToolTip>

        );
      })}
    </Fragment>
  );
};

FacilityAccreditations.propTypes = {
  accreditations: PropTypes.string.isRequired,
};

export default injectIntl(FacilityAccreditations);
