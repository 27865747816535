import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import uuidv4 from 'uuid/v4';
import { getFacilitySelector } from '../../../selectors/facility';
import { ReactComponent as Check } from '../../../images/icons/v2/check.svg';
import { ReactComponent as X } from '../../../images/icons/v2/x.svg';

import grid from '../../../styles/bootstrap-grid.module.css';
import styles from './facility.module.css';
import {
  IN_HOUSE,
  OWN_VEHICLE,
  PUBLIC,
  RIDESHARE,
  VEHICLE_ALLOWED,
  VEHICLE_ALLOWED_RESTRICT,
  VEHICLE_STAY,
} from '../../../constants/Transportation';

const TRANSPORT_OPTIONS = [
  OWN_VEHICLE,
  RIDESHARE,
  PUBLIC,
  IN_HOUSE,
  VEHICLE_ALLOWED,
  VEHICLE_STAY,
  VEHICLE_ALLOWED_RESTRICT,
];

class FacilityTransportation extends PureComponent {
  hasTransportation(option) {
    const {
      facility: { transportationOptions = '' },
    } = this.props;
    if (!transportationOptions) {
      return false;
    }
    return transportationOptions.split(',').includes(option);
  }

  renderCol(trans) {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <div className={[grid.colSm6, grid.colMd4, styles.check].join(' ')} key={uuidv4()}>
         <Check /> <FormattedMessage id={`facility.trans.${trans}`} />
      </div>
    );
  }

  renderTransportationList() {
    let row = [];
    for (let i = 0; i < TRANSPORT_OPTIONS.length; i++) {
      if (this.hasTransportation(TRANSPORT_OPTIONS[i])) {
        row.push(this.renderCol(TRANSPORT_OPTIONS[i]));
      }
    }
    return row;
  }

  render() {
    const {
      facility: { transportationOptions = '' },
    } = this.props;

    return (
      transportationOptions &&
      transportationOptions !== '' && (
        <div className={[styles.container].join(' ')}>
          <div className={grid.row}>
            <div className={grid.col}>
              <h2 className={styles.header}>
                <FormattedMessage id="facility.header.transportation" />
              </h2>
            </div>
          </div>

          <div className={[styles.box1].join(' ')}>
            <div className={grid.row} key={uuidv4()}>
              {this.renderTransportationList()}
            </div>
          </div>
        </div>
      )
    );
  }
}

FacilityTransportation.defaultProps = {};

FacilityTransportation.propTypes = {
  facility: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  facility: getFacilitySelector(state),
});

export default connect(mapStateToProps)(injectIntl(FacilityTransportation));
