import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Menu, { SubMenu, MenuItem } from 'rc-menu';

import grid from '../../../../styles/bootstrap-grid.module.css';
import styles from './facilitySearchBar.module.css';

import BarResidentModifiers from './BarResidentModifiers';
import BarRent from './BarRent';
import BarPaymentType from './BarPaymentType';

import { ReactComponent as Map } from '../../../../images/icons/v2/map.svg';
import { ReactComponent as List } from '../../../../images/icons/v2/list.svg';
import { ReactComponent as Arrow } from '../../../../images/icons/v2/down-arrow.svg';

import FilterModal from '../../../facilities/v2/FilterModal/FilterModal';
import BarSort from './BarSort';

const VIEW_LIST = 'list';
const VIEW_MAP = 'map';
const SPLIT_VIEW = 'split';

class FacilitySearchBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
      showAvailableHouses: false,
      expanded: false,
      showSplitView: true,
    };

    this.handleShowFilters = this.handleShowFilters.bind(this);
    this.handleShowAvailableHouses = this.handleShowAvailableHouses.bind(this);
    this.handleExpanded = this.handleExpanded.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    if (window.screen.width < 1024) {
      this.setState({
        showSplitView: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { search, showSearchFilters } = this.props;

    if (prevProps.search !== search) {
      this.setState({
        showAvailableHouses: search.hasOwnProperty('facilityAvailable'),
      });
    }

    if (prevProps.showSearchFilters !== showSearchFilters) {
      this.setState({
        showFilters: showSearchFilters,
      });
    }
  }
  handleShowFilters() {
    const { showFilters } = this.state;
    const { openSearchFilters } = this.props;
    this.setState({ showFilters: !showFilters });
    openSearchFilters();
  }

  handleShowAvailableHouses(e) {
    const { fetchFilteredSearch, handleSearchBarChange } = this.props;
    let isChecked = e.target.checked;
    if (isChecked) {
      handleSearchBarChange('facilityAvailable', { value: isChecked });
    } else {
      handleSearchBarChange('facilityAvailable', { value: null });
    }

    this.setState({
      showAvailableHouses: isChecked,
    });
    fetchFilteredSearch();
  }

  handleExpanded() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  onSelect(info) {
    const { handleMapChange } = this.props;

    handleMapChange(info.key);
  }
  render() {
    const {
      view,
      handleSearchFiltersChange,
      handleFiltersOptionsChange,
      fetchFilteredSearch,
      filterOptions,
      search,
      hideFilters,
    } = this.props;
    const { showFilters, expanded, showSplitView, showAvailableHouses } = this.state;

    return (
      <div className={[grid.row, grid.noGutters].join(' ')}>
        <div className={[grid.col, styles.bar].join(' ')}>
          <div className={[grid.row, styles.innerBar, grid.noGutters, grid.container].join(' ')}>
            <div className={styles.filtersContainer}>
              {!hideFilters && (
                <>
                  <div className={[grid.col2, styles.barResidentModifiers, grid.noGuttersBoth].join(' ')}>
                    <BarResidentModifiers
                      handleFiltersOptionsChange={handleFiltersOptionsChange}
                      fetchFilteredSearch={fetchFilteredSearch}
                      search={search}
                    />
                  </div>
                  <div className={[grid.col2, styles.barPaymentType].join(' ')}>
                    <BarPaymentType
                      handleFiltersOptionsChange={handleFiltersOptionsChange}
                      fetchFilteredSearch={fetchFilteredSearch}
                      search={search}
                    />
                  </div>
                  {/*<div className={[grid.col2, styles.barRent].join(' ')}>*/}
                  {/*  <BarRent*/}
                  {/*    handleFiltersOptionsChange={handleFiltersOptionsChange}*/}
                  {/*    fetchFilteredSearch={fetchFilteredSearch}*/}
                  {/*    search={search}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div className={[grid.col2, styles.barAvail].join(' ')}>
                    <label className={styles.checkboxContainer}>
                      <input
                        type="checkbox"
                        name="facilityAvailable"
                        onChange={this.handleShowAvailableHouses}
                        checked={showAvailableHouses}
                      />
                      <span className={styles.checkboxLabel}>
                        <FormattedMessage id="search.bar.availability" />
                      </span>
                    </label>
                  </div>
                  <div className={[grid.col2, styles.barFilter].join(' ')}>
                    <button className={styles.filterButton} onClick={this.handleShowFilters}>
                      <FormattedMessage id="search.bar.more" />
                    </button>
                  </div>

                  <FilterModal
                    show={showFilters}
                    handleCloseModal={this.handleShowFilters}
                    handleSearchFiltersChange={handleSearchFiltersChange}
                    handleFiltersOptionsChange={handleFiltersOptionsChange}
                    fetchFilteredSearch={fetchFilteredSearch}
                    filterOptions={filterOptions}
                    search={search}
                  />
                  <div className={[grid.col2, styles.barRent].join(' ')}>
                    <BarSort
                      handleFiltersOptionsChange={handleFiltersOptionsChange}
                      fetchFilteredSearch={fetchFilteredSearch}
                      search={search}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={[grid.col2, styles.barMapList].join(' ')}>
              <Menu
                mode="horizontal"
                overflowedIndicator=""
                triggerSubMenuAction="click"
                onOpenChange={this.handleExpanded}
                onSelect={this.onSelect}
              >
                <SubMenu
                  title={
                    <span className="submenu-title-wrapper">
                      {view === VIEW_LIST && (
                        <>
                          <List className={styles.mapListIcon} />
                          <FormattedMessage id="search.bar.list" />
                        </>
                      )}
                      {view === VIEW_MAP && (
                        <>
                          <Map className={styles.mapListIcon} />
                          <FormattedMessage id="search.bar.map" />
                        </>
                      )}

                      {view === SPLIT_VIEW && (
                        <>
                          <List className={styles.mapListIcon} />
                          <FormattedMessage id="search.bar.split" />
                        </>
                      )}

                      <Arrow className={expanded ? styles.upArrow : styles.downArrow} />
                    </span>
                  }
                  key="residentModifierKey"
                >
                  <MenuItem key={VIEW_LIST} className={styles.menuItem}>
                    <List className={styles.mapListIcon} />
                    <FormattedMessage id="search.bar.list" />
                  </MenuItem>
                  <MenuItem key={VIEW_MAP} className={styles.menuItem}>
                    <Map className={styles.mapListIcon} />
                    <FormattedMessage id="search.bar.map" />
                  </MenuItem>
                  {showSplitView && (
                    <MenuItem key={SPLIT_VIEW} className={styles.menuItem}>
                      <List className={styles.mapListIcon} />
                      <FormattedMessage id="search.bar.split" />
                    </MenuItem>
                  )}
                </SubMenu>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FacilitySearchBar.defaultProps = {
  hideFilters: false,
};

FacilitySearchBar.propTypes = {
  handleMapChange: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  handleFiltersOptionsChange: PropTypes.func.isRequired,
  fetchFilteredSearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  hideFilters: PropTypes.bool,
};

export default FacilitySearchBar;
