import React from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import PropTypes from 'prop-types';

import styles from './tooltip.module.css';

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => (
  <TooltipTrigger
    {...props}
    tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: `${styles.tooltipContainer}`,
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: `${styles.tooltipArrow}`,
              'data-placement': placement,
            })}
          />
        )}
        {tooltip}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

Tooltip.defaultProps = {
  hideArrow: false,
  children: {},
};

Tooltip.propTypes = {
  hideArrow: PropTypes.bool,
  tooltip: PropTypes.object.isRequired,
  children: PropTypes.object,
};

export default Tooltip;
